import React, { useState } from 'react'
import { textInputClass, btnStyles, inputsCommon, VOICES, apiFetch } from './utils'
import classNames from 'classnames'
import Uploader from './Uploader'
import ThingList from './ThingList'
import { set } from 'ramda'
import ffmpeg from './utils/ffmpeg'
import Accordion from './common/Accordion'
import useStore from './context/hooks'

export default function AudioChooser(props) {
  const { onComplete } = props
  const [audioLoading, setAudioLoading] = useState(false)
  const [narrationText, setNarrationText] = useState('')
  const [voice, setVoice] = useState(props.voice || VOICES[0][1])
  const [type, setType] = useState()
  const [ silenceLength, setSilenceLength ] = useState(10)
  const { state, dispatch } = useStore()
  const setAudio = (url, transcript) => {
    onComplete && onComplete({
      url,
      transcript
    })
  }
  const tabs = []
  if (state.user && state.user.things.find(t => t.type === 'audio')) {
    tabs.push({
      label: '💼 your audio',
      content: (
        <ThingList 
          type='audio'
          onClick={thing => {
            setAudio(thing.to, thing.from)
          }}
        />
      )
    })
  }
  tabs.push({
    label: '☁️ upload mp3 or mp4',
    content: (
      <Uploader 
        fileName='audio'
        onUpload={data => {
          setAudio(data.url)
        }} 
      >
        Click or drop an mp3 or mp4 file here
      </Uploader>
    )
  })
  tabs.push({
    label: '👄 generate narration',
    content: (
      <p className='text-left'>
        <textarea 
          className={classNames('peer h-full min-h-[120px] w-full h-60', inputsCommon)}
          value={narrationText} 
          onChange={evt => setNarrationText(evt.target.value)} 
        />
        {narrationText && (
          <div className='flex justify-between'>
            <div>
              <p className='text-left'>Voice:</p>
              <select className={classNames(inputsCommon)} value={voice} onChange={evt => setVoice(evt.target.value)}>
                {VOICES.map(v => {
                  const label = Array.isArray(v) ? v[0] : v
                  const value = Array.isArray(v) ? v[1] : v
                  return (
                    <option key={label} value={value}>{label}</option>
                  )
                })}
              </select>
            </div>
            <button className={classNames(btnStyles)} onClick={async () => {
              try {
                setAudioLoading(true)
                const res = await apiFetch(`/narration`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    text: narrationText,
                    voice
                  })
                })
                const data = await res.json()
                setAudio(data.url, narrationText)
                dispatch({ type: 'refreshUser' })

              } catch (e) {
                console.error(e)
              }
              setAudioLoading(false)
            }}>Generate</button>
          </div>
        )}
      </p>
    )
  })
  let audioShow = <Accordion tabs={tabs} />
  if (audioLoading) {
    audioShow = (
      <p className='text-center'>Loading...</p>
    )
  }
  return (
    <div className="audio-chooser">
      <label>Audio</label>
      {audioShow}
    </div>
  )
}
