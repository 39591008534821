import { abbreviateNumber, addCommas } from "../utils";
import classNames from "classnames";
import FireIcon from "./FireIcon";

export default function Fire ({ fire, long, className }) {
  let show = fire
  if (long) show = addCommas(fire)
  else show = abbreviateNumber(fire)

  return (
    <span className={classNames('fire-wrap inline-block', className)}>
      {show}
      <FireIcon />
    </span>
  )
}