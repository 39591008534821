import React, { useState, useEffect, useCallback } from 'react'
import { apiPost, textInputClass, btnStyles, inputsCommon, VOICES, apiFetch } from './utils'
import classNames from 'classnames'
import Uploader from './Uploader'
import ThingList from './ThingList'
import { set } from 'ramda'
import { WaveSurferPlayer } from "./WaveSurfer";
import ffmpeg from './utils/ffmpeg'
import Accordion from './common/Accordion'
import useStore from './context/hooks'
import { ImPlus } from "react-icons/im"
import "./AudioChooser.scss"
import { IoArrowBackSharp } from "react-icons/io5";
import BerfRange from './BerfRange'
import AudioPlayer from './common/AudioPlayer'
import { TfiReload } from "react-icons/tfi";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { IoPlay, IoPause } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";



export default function AudioChooser(props) {
  const { onComplete, narration } = props
  const [audioLoading, setAudioLoading] = useState(false)
  const [audioData, setAudioData] = useState(null)
  const [linkedToNarration, setLinkedToNarration] = useState(true)
  const [audioUrl, setAudioUrl] = useState('')
  const [narrationText, setNarrationText] = useState(narration || '')
  const [voice, setVoice] = useState(props.voice || VOICES[0][1])
  const [type, setType] = useState('')
  const [audioDuration, setAudioDuration] = useState(0)
  const [audioPlaying, setAudioPlaying] = useState(false)
  const [ silenceLength, setSilenceLength ] = useState(10)
  const { state, dispatch } = useStore()
  useEffect(() => {
    if (!linkedToNarration) return
    setNarrationText(narration)
  }, [narration, linkedToNarration])
  const setAudio = useCallback((url, transcript) => {
    onComplete && onComplete({
      url,
      transcript,
      voice,
      audioDuration,
      silenceLength: type === 'silence' ? silenceLength : null
    })
  }, [voice, audioDuration, silenceLength, type])
  useEffect(() => {
    if (type === 'narration' && voice !== '') {
      generate()
    }
  }, [type, voice])
  const reset = () => {
    setType('')
    setAudioData(null)
    setAudioUrl('')
    onComplete && onComplete({})
  }
  const generate = async () => {
    try {
      setAudioLoading(true)
      const res = await apiFetch(`/narration/stream`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: narrationText,
          voice
        })
      })
      const data = await res.blob()
      // create an audio object with the blob
      setAudioData(data)

    } catch (e) {
      console.error(e)
    }
    setAudioLoading(false)
  }
  const tabs = []
  if (state.user && state.user.things.find(t => t.type === 'audio')) {
    tabs.push({
      label: '💼 your audio',
      content: (
        <ThingList 
          type='audio'
          onClick={thing => {
            console.log('thing', thing)
          }}
        />
      )
    })
  }
  tabs.push({
    label: '☁️ upload mp3 or mp4',
    content: (
      <Uploader 
        fileName='audio'
        onUpload={data => {
          setAudio(data.url)
        }} 
      >
        Click or drop an mp3 or mp4 file here
      </Uploader>
    )
  })
  // let audioShow = <Accordion tabs={tabs} />
  let btns = []
  if (audioUrl) {
    btns.push(
      <a onClick={reset} key='back' className=''><FaRegTrashAlt /></a>
    )
    btns.push(
      <a onClick={() => setAudioPlaying(!audioPlaying)} key='playing' className=''>{audioPlaying ? <IoPause/> : <FaPlay />}</a>
    )
  } else if (type !== '') {
    btns.push(
      <a onClick={reset} key='back' className=''><IoArrowBackSharp /></a>
    )
  }
  let label = 'Sound'
  let audioShow = (
    <div className='choices'>
      <a onClick={() => {
        setType('silence')
        onComplete && onComplete({
          silenceLength
        })
      }}>Silence</a>
      <a onClick={() => setType('narration')}>Narration</a>
      {/* <a onClick={() => setType('uploads')}><ImPlus /></a> */}
    </div>
  )
  if (type === 'silence') {
    label = 'Silence'
    audioShow = (
      <div className='main'>
        <BerfRange
          min={0}
          step={0.1}
          max={15}
          values={[silenceLength]}
          onChange={({ values }) => {
            setSilenceLength(values[0])
            onComplete && onComplete({
              silenceLength: values[0]
            })
          }}
        />
        <p className='text-right text-sm'>{silenceLength}s</p>
      </div>
    )
  } else if (type === 'narration') {
    label = 'Narration'
    let narrationInput = null
    if (audioData) {
      narrationInput = (
        <>
          <AudioPlayer url={URL.createObjectURL(audioData)} />
          <button onClick={() => { 
            setAudioData(null)
            setAudioDuration(0)
          }} className='mx-2 delete cancel btn generate'><FaRegTrashAlt /></button>
        </>
      )
      if (!audioUrl && !audioLoading) {
        btns.push(
          <a className='mt-2 check' key='check' onClick={async () => {
            setAudioLoading(true)
  
            const formData = new FormData();
            const file = new File([audioData], 'narration.mp3', { type: 'audio/mpeg' })
            formData.append('from', narrationText)
            formData.append('audio', file);
            try {
              const response = await apiFetch(`/uploads`, {
                method: 'POST',
                body: formData,
              });
  
              if (!response.url) {
                window.alert('Error uploading audio')
              }
  
              const data = await response.json();
              setAudioUrl(data.url)
              setAudioLoading(false)
            } catch (error) {
              console.error('Error uploading image:', error.message);
              window.alert('Error uploading audio')
              setAudioLoading(false)
            }
          }}><FaCheck /></a>
        )
      }
    }
    audioShow = (
      <div className='text-left w-full'>
        <textarea 
          className={classNames('peer  w-full mb-2', inputsCommon, audioData ? 'h-20' : 'h-20')}
          value={narrationText} 
          onChange={evt => {
            setNarrationText(evt.target.value)
            setLinkedToNarration(false)
            setAudioData(null)
          }} 
          placeholder='What should the narrator say?'
        />
        {narrationText && (
          <div className='flex justify-between items-end'>
            <div>
              <label className='block mb-1'>Voice:</label>
              <select className={classNames(inputsCommon)} value={voice} onChange={evt => setVoice(evt.target.value)}>
                <option value=''>Select a voice</option>
                {VOICES.map(v => {
                  const label = Array.isArray(v) ? v[0] : v
                  const value = Array.isArray(v) ? v[1] : v
                  return (
                    <option key={label} value={value}>{label}</option>
                  )
                })}
              </select>
            </div>
            <div className='flex'>
              {audioLoading ? (
                <p>one sec...</p>
              ) : (
                <>
                  {narrationInput}
                  <button className='btn generate' onClick={generate}>{audioData ? <TfiReload /> : 'generate'}</button>
                </>
                )
              }
            </div>
          </div>
        )}
      </div>
    )
  } else if (type === 'uploads') {
    label = 'Uploads'
    audioShow = (
      <ThingList 
        type='audio'
        onClick={thing => {
          setNarrationText(thing.from)
          setAudioUrl(thing.to)
        }}
      />
    )
  }
  if (audioUrl) {
    label = 'Audio'
    audioShow = (
      <WaveSurferPlayer
        height={100}
        waveColor="rgb(163,230,53)"
        progressColor="rgb(200, 0, 200)"
        url={audioUrl}
        playing={audioPlaying}
        onEvent={evt => {
          if (evt === 'finish') setAudioPlaying(false)
        }}
        onDuration={duration => {
          const durr = Math.ceil(duration * 10) / 10
          if (durr === audioDuration) return
            setAudioDuration(durr)
          onComplete && onComplete({
            voice,
            url: audioUrl,
            transcript: narrationText,
            audioDuration: durr,
            silenceLength: null 
          })
        }}
      />
    )
  }
  return (
    <div className={classNames("audio-chooser field-group", `type-${type}`)}>
      <label>{label}</label>
      <div className={classNames('wrap', { btns: btns.length > 0 })}>
        {btns.length > 0 && (
          <div className='btns'>
            {btns}
          </div>
        )}
        {audioShow}
      </div>
    </div>
  )
}
