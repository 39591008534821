import useStore, { useSetThing } from "../context/hooks"
import { selectClass } from "../utils"

export default function ModeSelect () {
  const { state } = useStore()
  const setThing = useSetThing()
  const { mode, user } = state
  return (
    <div className=" fixed bottom-0 right-0 m-5 z-50">
      {mode === 'advanced' && !user && <span className="text-sm bg-black"><a onClick={() => setThing('modal', 'auth')}>be advanced</a> </span>}
      <select className={selectClass} value={mode} onChange={e => setThing('mode', e.target.value)}>
        <option value=''>normie mode</option>
        <option value='advanced'>{user ? 'advanced' : 'mid'} mode</option>
      </select>
    </div>
  )

}