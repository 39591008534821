import React, { useState, useEffect } from 'react';
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6";

const AudioPlayer = ({ url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(new Audio(url));

  useEffect(() => {
    const audio = audioRef.current;
    // Play or pause audio based on isPlaying state
    isPlaying ? audio.play() : audio.pause();

    // Cleanup on component unmount
    return () => {
      audio.pause();
    };
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;

    // Update component state based on audio playback ending
    const handleAudioEnd = () => setIsPlaying(false);
    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      audio.removeEventListener('ended', handleAudioEnd);
    };
  }, []);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <button onClick={togglePlayPause} className='text-3xl'>
      {isPlaying ? <FaCirclePause/> : <FaCirclePlay/>}
    </button>
  );
};

export default AudioPlayer;
