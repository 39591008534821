import React, { createContext, useReducer } from 'react';
import { isLocal } from '../utils';

// Initial state
const initialState = {
  refreshUser: true,
  userChecked: false,
  preferred: [],
  storiesCache: {},
  videosCache: {},
  nodesCache: {}
};

export const savableSettings = [
  'mode'
]
savableSettings.forEach(setting => {
  if (localStorage.getItem(setting)) {
    initialState[setting] = localStorage.getItem(setting)
  }
})


// Create the context
export const Context = createContext();

// Create a component to wrap your app with the context
export const Provider = ({ children }) => {
  // Reducer function to handle state updates
  const reducer = (state, action) => {
    if (isLocal()) {
      // console.log(action.type, action.payload?.key, action.payload)
    }
    const ret = { ...state }
    switch (action.type) {
      case 'user':
        return { ...state, user: action.payload, refreshUser: false, userChecked: true };
      case 'refreshUser':
        return { ...state, refreshUser: true };
      case 'setThing':
        ret[action.payload.key] = action.payload.value
        return ret
      case 'cache':
        const key = action.payload.key + 'Cache'
        ret[key] = ret[key] || {}
        action.payload.value.forEach(v => {
          ret[key][v._id] = v
        })
        return ret
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  );
};