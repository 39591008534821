import './App.scss';
import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom"
import { linkStyles, doLogin, getUrl, logout, playAi, isBerf, site, abbreviateNumber, isMobileDevice } from './utils';
import { Provider } from './context/Context';
import AuthForm from './AuthForm';
import classNames from 'classnames';
import useStore, { useCheckStatus, useSetThing } from './context/hooks';
import Buy from './Buy';
import Thing from './Thing';
import Menu from './Menu';
import DescribeThing from './DescribeThing';
import { AiOutlineSearch } from "react-icons/ai";
import { QuickContinue } from './QuickContinue';
import AddStory from './AddStory';
import { GiSplash } from "react-icons/gi";
import BerfIcon from './common/BerfIcon';
import Modal from './common/Modal';
import FireIcon from './common/FireIcon';
import Fire from './common/Fire';
import { MdOutlineRefresh } from "react-icons/md";



function App() {
  const location = useLocation()
  const { state, dispatch } = useStore()
  const checkStatus = useCheckStatus()
  const setThing = useSetThing()
  const { user, menuOpen, mode } = state

  let modal = state.modal
  if (modal && typeof modal === 'object') modal = modal.type

  // const col = 'text-center mr-auto ml-auto max-w-md pl-5 pr-5 pb-14 w-full' 
  // const classes = location.pathname === '/' ? '' : col

  const [isWide, setIsWide] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      setIsWide(innerWidth > innerHeight);
    };

    // Initial call to set the initial state
    handleResize();

    // Add a listener for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!state.refreshUser) return
    console.log('refreshing user')
    doLogin()
    .then(ok => {
      dispatch({ type: 'user', payload: ok })
      checkStatus()
    })
    .catch(err => console.log(err))
  }, [state.refreshUser, checkStatus])
  let pageClass = (location.pathname || '').split('/')[1].replace(/[^a-z]/gi, '') 
  if (!pageClass) pageClass = 'home'

  const style = classNames(linkStyles, 'block px-4 py-2 hover:bg-slate-600 hover:text-white')
  const closeMenu = () => {
    dispatch({ type: 'setThing', payload: { key: 'menuOpen', value: false } })
  }
  let modalShow
  if (modal === 'fire') {
    modalShow = <Buy />
  } else if (modal === 'thing') {
    modalShow = <Thing {...state.modal.props} />
  } else if (modal === 'describe-thing') {
    modalShow = <DescribeThing {...state.modal.props} />
  } else if (modal === 'quick-continue') {
    modalShow = <QuickContinue {...state.modal.props} />
  } else if (modal === 'add-story') {
    modalShow = <AddStory {...state.modal.props} />
  }
  return (
    <div className={classNames("App", 
      pageClass, 
      isWide ? 'wide' : 'tall', 
      modal, 
      mode, 
      site(),
      { 
        'menu-open': menuOpen, 
        aiaiaiaiai: !isBerf(), 
        loggedout: !user,
        mobile: isMobileDevice(),
        desktop: !isMobileDevice(),
        'no-fire': !user?.fire,
        berf: isBerf() 
      }
    )}>
      <header className="App-header">
        <h1 className="text-3xl font-bold logo"><Link onClick={() => !user && playAi()} to="/">aiaiaiaiai</Link></h1>
        <AuthForm />
        {site('tv') ?
          (
            <a href="#" className={classNames('text-center buy-berfs')} role="menuitem"
            onClick={() => {
              setThing('modal', 'fire')
            }}
          >
            {user && user.fire ? (
              <Fire fire={user.fire} className='text-xs italic' />
              )
              : (
                <>💸 buy berfs</>
              )
            }
          </a>
         )
         : <Link className='search-icon' to='videos'><AiOutlineSearch /></Link>}
      </header>
      {/* <nav className='flex justify-center main p-5'>
        <Link className={classNames({ active: location.pathname !== '/videos' })} to="/">Create Videos</Link>
        <Link className={classNames({ active: location.pathname === '/videos' })} to="/videos">Watch Videos</Link>
      </nav> */}
      <div className={classNames('page-content')}>
        <Outlet />
      </div>
      {modalShow && <Modal className={modal}>{modalShow}</Modal>}
      <a href="/" className="refresh fixed bottom-0 right-0 p-3 text-xl"><MdOutlineRefresh /></a>
    </div>
  );
}

export default App;
