import React from 'react';
import { FPS, getVideoUrl } from './utils';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSetThing } from './context/hooks';
import { AiFillTags } from 'react-icons/ai';
import { GoGitCommit, GoRepoForked } from 'react-icons/go';
import { VscDebugContinue } from "react-icons/vsc";
import { CiLink } from "react-icons/ci";
import { IoMdDownload } from "react-icons/io";
import DescribeThing from './DescribeThing';
import './VideoButtons.scss'

const VideoButtons = ({ video, player }) => {
  const [tagOpen, setTagOpen] = React.useState(false)
  const setThing = useSetThing() 
  if (!video) return null
  const v = video || {}
  const isGenerated = /finals/.test(video.video)
  return (
    <div className={classNames('video-buttons', { generated: isGenerated, uploaded: !isGenerated })}>
      <div className='btns flex justify-start'>
        <Link className='svg' to={`/c/${v._id}`}><CiLink /></Link>
        <a href={video.video} download className={classNames('svg')}>
          <IoMdDownload />
        </a>
        <Link className='svg fork' to={getVideoUrl(video)}><GoRepoForked /></Link>
        <a onClick={() => setTagOpen(!tagOpen)} className={classNames('relative svg icon text-white', { 'text-red-500': tagOpen })}>
          <AiFillTags />
        </a>
        <a 
          title='create clip'
          className='ml-auto image-link show-advanced inline clip' 
          onClick={() => {
            setThing('modal', {
              type: 'thing',
              props: {
                type: 'VideoClip',
                video
              }
            })
          }}>
            ✄
          </a>
        {player?.current && (
          <a 
            title='snapshot'
            className='image-link show-advanced inline commit' 
            onClick={() => {
              const t = typeof player.current.currentTime === 'function' ? player.current.currentTime() : player.current.currentTime
              const frame = Math.floor(t * FPS)
              // window.open(imageUrl, '_blank')
              setThing('modal', {
                type: 'thing',
                props: {
                  frame,
                  type: 'VideoImage',
                  video
                }
              })
            }}><GoGitCommit /></a>
        )}
        <Link 
          title='continue'
          className='image-link show-advanced inline-block svg continue' 
          to={`/audio?c=${v._id}`}
        ><VscDebugContinue /></Link>
      </div>
      {tagOpen && (
        <div className='absolute top-0 left-0 w-full bg-black p-5 bg-opacity-80 z-10 h-full flex justify-center items-center '>
          <a className='absolute z-20 right-0 top-0 text-lg p-2 px-4' onClick={() => setTagOpen(false)}>x</a>
          <div className=''>
            <DescribeThing type='video' id={v._id} />
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoButtons;