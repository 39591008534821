import { max, on, path, range } from "ramda"
import { Dendrogram } from "../common/Dendrogram";
import { SimplePathNav } from "../common/SimplePathNav";
import './PathViewer.scss'
import useStore from "../context/hooks";
import React, { useState, useEffect, useRef } from 'react';
import * as d3 from "d3";

export default function PathViewer ({ onClick, trip, selected, hierarchy }) {
  const { state } = useStore()
  const { storiesCache } = state

  // keep track of width of element
  const elementRef = useRef(null);
  const [width, setWidth] = useState(300);

  useEffect(() => {
    if (!elementRef.current) return
    const updateWidth = () => {
        setWidth(elementRef.current.offsetWidth);
    };

    window.addEventListener('resize', updateWidth);

    // Set the initial width
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, [hierarchy]);
  if (!hierarchy) return

  const current = hierarchy.find(v => v.id === selected)
  let pathHierarchy = hierarchy
  let depth = 0
  if (current) {
    // console.log('hello current', current)
    if (current.depth > 1) {
      pathHierarchy = hierarchy.find(v => v.depth === current.depth - 1).copy() 
      depth = current.depth
    }
  }
  return (
    <div className='path-viewer mt-2' ref={elementRef}>
      <SimplePathNav
        width={width} 
        height={150} 
        trip={trip}
        hierarchy={hierarchy}
        selected={selected}
        onClick={evt => onClick && onClick(evt)}
      />
      {/* <hr/>
      <Dendrogram
        width={width} 
        height={70} 
        hierarchy={pathHierarchy}
        depth={depth}
        selected={selected}
        onClick={node => {
          if (onClick) onClick(node.data.id)
        }}
      /> */}
    </div>
  )
}