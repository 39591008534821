import classNames from "classnames";
import BraintreeDropIn from "./BraintreeDropin";
import { apiCheckout, getFire, textInputClass } from "./utils";
import { useState } from "react";
import useStore from "./context/hooks";
import Fire from "./common/Fire";

export default function Buy ({ onSuccess }) {
  const [dollarAmount, setDollarAmount] = useState(10);
  const [loading, setLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const { state, dispatch } = useStore()
  const { user } = state

  let show = (
    <>
      <p className="mb-5">
        $
        <input 
          type="number"
          value={dollarAmount}
          onChange={e => setDollarAmount(parseFloat(e.target.value))}
          className={classNames(textInputClass.replace('w-full', ''), "border p-2 w-1/2 inline-block w-20")}
        />
        {dollarAmount > 0 && <span> =  <Fire fire={getFire(dollarAmount)} long /></span>}
      </p>
      <BraintreeDropIn 
        show
        onPaymentCompleted={async braintree => {
          setLoading(true)
          const ret = await apiCheckout({ braintree, price: dollarAmount, buyFire: getFire(dollarAmount) })
          if (ret.success) {
            dispatch({ type: 'refreshUser' })
            onSuccess && onSuccess()
          } else {
            alert('something went wrong')
          }
          setFinished(true)
          setLoading(false)
        }}
      />
    </>
  )
  if (loading) {
    show = <div className='text-center'>Loading...</div>
  } else if (finished) {
    show = <div className='text-center'>success! now get outta here and make dem vidz</div>
  }

  return (
    <div className='buy'>
      <h2>Buy Berfs</h2>
      {/* <p>
      Berfs represent the life of the project. Actions that contribute to the project generate Berfs. This includes things such as drawing attention to the group’s productions, injecting money into the project, and by creating content that is used by others.  However, berfs can also be lost, which happens when they are used to cover costs like infrastructure and labor, or as penalties for creating unacceptable content. This dynamic ensures that berfs not only incentivize positive contributions but also help maintain the project’s health by discouraging detrimental actions.
      </p>
      <p>
        A fraction of all Berf transactions go into the "Berf Bin", a collective pool intended for communal use, decided by the community itself.  In the initial stages, it supports new users by providing them with a starting capital of 5,000 berfs. As your stature grows in the community, you will be able to have say in how the Berf Bin is used.
      </p> */}
      <p className="py-3">You currently have <Fire long fire={user.fire} /></p>
      {show}
    </div>
  )
}