import { useState, useRef, useEffect } from "react"
import useStore, { useFetchStories, useSetThing } from "../context/hooks"
import TikTok from "../TikTok"

export default function NodeTikTok ({ onChange, hierarchy, selected, onStartChange }) {
  const tikTokRef = useRef(null)
  const { fetchStory, storiesCache, nodesCache } = useFetchStories()
  useEffect(() => {
    fetchStory(selected)
  }, [selected])
  if (!hierarchy) {
    return null
  }
  

  return (
    <div className='max-w-2xl node-tiktok px-2 mb-2'> 
      <TikTok
        selected={selected}
        hierarchy={hierarchy}
        onChange={onChange}
        onStartChange={onStartChange}
        ref={tikTokRef}
        renderItem={_id => {
          const story = storiesCache[_id]
          const node = nodesCache[story.nodeId || story.nodes]
          return (
            <div
              key={`v-${_id}`}
              className="node"
            >
              {node?.text}
            </div>
          )
        }}
      />
    </div>
  )
}