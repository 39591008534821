import "./Thing.scss"
import React, { useState, useEffect, useRef } from "react";
import { apiPost, imgUrlForFrame, inputsCommon } from "./utils";
import classNames from "classnames";
import { useStore, useSetThing } from "./context/hooks";
import ClipMaker from "./ClipMaker";
import { values } from "ramda";
import ThingList from "./ThingList";
import DescribeThing from "./DescribeThing";

export default function Thing (doc) {
  const { frame, to, type, video, data, clipMaker, onComplete } = doc
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [extraData, setData] = useState(null)
  const [clipAudio, setClipAudio] = useState(false)
  const { dispatch, state } = useStore()
  const { user } = state
  const setThing = useSetThing()

  const getId = () => {
    const isVideoClip = type === 'VideoClip'
    return isVideoClip ? doc?.video?._id + '-' + extraData?.start + '-' + extraData?.end : doc?.video?._id + '-' + frame
  }

  const submit = async () => {
    const isVideoClip = type === 'VideoClip'
    const id = getId() 
    const thing = {
      type,
      id
    }
    if (isVideoClip && clipAudio) {
      thing.data = { audio: true }
    }
    setDescription('')
    const res = await apiPost('/user/thing', thing)
    onComplete && onComplete()
  }

  let show = (
    <p className='pt-5 pb-1'>
      <DescribeThing 
        id={getId()} 
        type={type} 
        onComplete={submit}
      />
    </p>
  )
  let display = null 
  if (type === 'VideoClip') {
    display = <>
      <ClipMaker 
        video={video} 
        muted
        onChange={({ values }) => {
          setData({ start: values[0], end: values[1] })
        }} 
      />
      {/* {extraData && (
        <label>
          Is audio important? &nbsp;
          <input type='checkbox' checked={clipAudio} onChange={evt => setClipAudio(evt.target.checked)} />
        </label>
      )} */}
    </>
    if (!extraData) {
      show = null
    }
  } else if (type === 'VideoImage') {
    display = <img src={imgUrlForFrame(video, frame)} />
  }
  if (loading) {
    show = (
      <div className='flex justify-center items-center'>
        Saving...
      </div>
    )
  }
  return (
    <div className={classNames('the-thing', type)}>
      {display}
      {show}
    </div>
  )

}