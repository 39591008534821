import { useState, useRef, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { apiJson, apiPost, imgUrlForFrame, isValidObjectId } from "./utils"
import { set } from "ramda"
import VideoPlayer from "./VideoPlayer2"
import useStore from "./context/hooks"
import VideoButtons from "./VideoButtons"
import VideoClips from "./VideoClips"

export default function See ({ }) {
  const { tag } = useParams()
  const lastTag = useRef(null)
  const player = useRef(null)
  const [isTag, setIsTag] = useState(false)
  const [videos, setVideos] = useState([])
  const [images, setImages] = useState([])
  const [summary, setSummary] = useState(null)
  const [clips, setClips] = useState(null)
  const [loading, setLoading] = useState(false)
  const [paths, setPaths] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)
  const [chosenPath, setChosenPath] = useState(0)
  const { state } = useStore()
  const { user } = state
  useEffect(() => {
    if (!tag || lastTag.current === tag) return
    lastTag.current = tag
    if (isValidObjectId(tag)) {
      apiJson(`/v/${tag}/stories`)
        .then(({ paths = [], videos }) => {
          setPaths(paths)
          console.log('paths', paths)
          // set chosen path to the longest
          setChosenPath(paths.reduce((acc, cur, i) => {
            if (cur.length > paths[acc].length) {
              return i
            }
            return acc
          }, 0))
          setVideos(videos || [])
        })
    } else {
      setIsTag(true)
      apiJson(`/search?tag=${tag}`)
        .then(({ videos, summary, clips, images = [] }) => {
          setSummary(summary)
          setVideos(videos || [])
          setClips(clips)
          setImages(images)
        })
    }
  }, [tag, setVideos])
  return (
    <div className='see mx-auto max-w-2xl'> 
      {isTag && (
        <h1 className="text-center pb-5 flex justify-between items-center">
          <span>{tag}</span>
          {user && (
            <a className="text-base" onClick={async () => {
              if (loading) return
              setLoading(true)
              const summary = await apiPost('/user/summarize', { tag })
              console.log('summary', summary)
              setSummary(summary.summary)
              setLoading(false)
            }}>{loading ? '🏃‍♀️' : '🤖'}</a>
          )}
        </h1>
      )}
      {summary && (
        <p className="mb-5">
          {summary}
        </p>
      )}
      <div className='video flex flex-col'>
        {videos.slice(0, 1).map(video => (
          <VideoPlayer 
            key={video._id} 
            video={video} 
            className='w-full'
            onRef={p => {
              player.current = p
              if (videos.length <= 1) {
                setCurrentVideo(video)
                return
              }
              let playlist = videos.map(v => ({
                _id: v._id,
                sources: [{
                  src: v.video,
                  type: 'video/mp4'
                }],
                name: v.summary || ' ',
                poster: `https://aiaiaiaiai.biz/images/${v._id}/screenshot-0.jpg`,
                thumbnail: [{
                  src: `https://aiaiaiaiai.biz/images/${v._id}/screenshot-0.jpg`,
                }]
              }))
              let currentItem = 0
              if (paths && paths.length > 0) {
                const pth = paths[chosenPath]
                playlist = pth.map((_id, i) => {
                  if (tag === _id) {
                    currentItem = i
                  }
                  return playlist.find(v => v._id === _id)
                })
              }
              p.playlist(playlist)
              p.playlist.autoadvance(0)
              p.playlist.currentItem(currentItem)
              p.playlistUi({ 
                horizontal: true,
                playOnSelect: true
              })
              p.on('playlistitem', (e) => {
                const i = p.playlist.currentItem()
                setCurrentVideo(videos.find(v => v._id === p.playlist()[i]._id))
              })
            }} 
          />
        ))}
        <VideoButtons player={player} video={currentVideo} />
        {videos.length > 1 && <div className="vjs-playlist w-full h-40 mt-5"></div>}
        <VideoClips video={currentVideo} videoId={currentVideo?._id} />
      </div>
      <VideoClips clips={clips} />
      {images.length > 0 && (
        <div className='images mt-6'>
          <p>Stills</p>
          <div className='flex flex-wrap w-full max-h-60 overflow-y-auto'>
            {images?.map(i => (
              <div
                key={i} 
                className='w-1/4 md:p-2 p-1 relative'
              >
                {/* <a className='z-1 absolute top-2 right-3' onClick={() => setThing('modal', {
                  type: 'thing',
                  props: i 
                })}>📝</a> */}
                  <img 
                    className='w-full h-auto' 
                    src={imgUrlForFrame(i.split('-')[0], i.split('-')[1])} 
                  />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}