import React, { useEffect, useState } from 'react'
import Uploader from './Uploader'
import classNames from 'classnames'
import { not, uniqBy } from 'ramda'
import { apiJson, linkStyles } from './utils'
import useStore, { useSetThing } from './context/hooks'

export default function ImageChooser ({ onComplete }) {
  const [imageStart, setImageStart] = useState(false)
  const [images, setImages] = useState([])
  const { state } = useStore()
  const setThing = useSetThing()
  const { user } = state
  useEffect(() => {
    (async () => {
      const res = await apiJson('/user/thing/VideoImage')
      setImages(res)
    })()
  }, [])
  if (!user) return null
  let btnText = 'Click or drop an image here...'
  if (images.length > 0) {
    btnText = 'Click or drop to upload, or choose...'
  }
  return (
    <div className="image-chooser">
      <p className='flex justify-between'>
        {imageStart && <span>Start the video with a custom image</span>}
        <a className={classNames(linkStyles, { 'text-3xl': !imageStart })} onClick={() => setImageStart(not)}>{imageStart ? '❌' : '📸'}</a>
      </p>
      <p>
        {imageStart && (
          <Uploader 
            btnText={btnText}
            onUpload={data => {
              onComplete && onComplete(data.url)
            }}
          >
            <div className='flex flex-wrap w-full max-h-60 overflow-y-auto'>
              {images?.map(i => (
                <div
                  key={i} 
                  className='w-1/4 md:p-2 p-1 relative'
                >
                  {/* <a className='z-1 absolute top-2 right-3' onClick={() => setThing('modal', {
                    type: 'thing',
                    props: i 
                  })}>📝</a> */}
                  <a                     
                    onClick={() => onComplete(i)} 
                  >
                    <img 
                      className='w-full h-auto' 
                      src={i} 
                    />
                  </a>
                </div>
              ))}
            </div>
          </Uploader>
        )}
      </p>
    </div>
  )
}