import React, {useEffect, useState} from 'react'
import dropin from "braintree-web-drop-in"
import { apiFetch, btnStyles, getUrl } from './utils'
import "./BraintreeDropin.scss"

function BraintreeDropIn(props) {
    const { show, onPaymentCompleted, btnDisabled } = props;

    const [braintreeInstance, setBraintreeInstance] = useState(undefined)

    useEffect(() => {
        if (show) {
            const initializeBraintree = async () => {
                const authorization = await apiFetch(`/payment/`).then(res => res.text())
                dropin.create({
                    authorization,
                    dataCollector: true,
                    container: '#braintree-drop-in-div',
                    card: {
                        overrides: {
                            styles: {
                                input: {
                                    'color': 'yellow'
                                },
                                ':focus': {
                                    'color': 'yellow'
                                }
                            }
                        }
                    }
                }, function (error, instance) {
                    if (error)
                        console.error(error)
                    else
                        setBraintreeInstance(instance);
                })
            } 

            if (braintreeInstance) {
                braintreeInstance
                    .teardown()
                    .then(() => {
                        initializeBraintree();
                    });
            } else {
                initializeBraintree();
            }
        }
    }, [show])

    return (
        <div
          className='rounded-md shadow-md'
            style={{display: `${show ? "block" : "none"}`}}
        >
            <div
                id={"braintree-drop-in-div"}
            />

            <button
                className={btnStyles}
                type="primary"
                disabled={!braintreeInstance || btnDisabled}
                onClick={() => {
                    if (braintreeInstance) {
                        braintreeInstance.requestPaymentMethod(
                            (error, payload) => {
                                if (error) {
                                    console.error(error);
                                } else {
                                    console.log("payment method nonce", payload.nonce);
                                    onPaymentCompleted({ nonce: payload.nonce, deviceData: payload.deviceData });
                                }
                            });
                    }
                }}
            >
                {
                    "Pay"
                }
            </button>
        </div>
    )
}

export default BraintreeDropIn
