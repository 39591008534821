import { set, uniq } from "ramda"
import { useFetchStories } from "./context/useFetchStories"
import { useEffect, useState, useMemo } from "react"
import BerfButton from "./common/BerfButton"
import ffmpeg from './utils/ffmpeg'
import VideoPlayer from "./VideoPlayer2"
import { apiPost, apiFetch, apiJson } from "./utils/"
import { FaRegTrashAlt, FaLongArrowAltUp } from "react-icons/fa";
import StoryCard from "./stories/StoryCard"

export default function TripViewer ({ trip = [], setTrip, storyId, setStoryId }) {
  const { fetchStory, fetchStories, storiesCache, nodesCache, videosCache, videoForStory } = useFetchStories() 
  const [nonExistentStory, setNonExistentStory] = useState(null)
  const [videoSrc, setVideoSrc] = useState(null)
  const [generating, setGenerating] = useState(false)
  const [poster, setPoster] = useState(null)
  const tripWithStory = useMemo(() => [...trip, storyId], [trip, storyId]);
  useEffect(() => {
    fetchStories(tripWithStory)
  }, [fetchStories, tripWithStory])
  useEffect(() => {
    async function _fetchScene () {
      setVideoSrc(null)
      const possibleStory = [...trip, storyId]
      const scene = await apiJson(`/user/scene/${possibleStory.join('-')}`)
      if (scene) {
        setVideoSrc(scene.video)
      } else {
        setNonExistentStory(possibleStory)
      }
    }
    _fetchScene()
  }, [trip])

  const theTrip = uniq(tripWithStory)
  let btn = null 
  if (videoSrc) {
    btn = (
      <a href={videoSrc} className='btn' target='_blank' download>download</a>
    )
  }
  if (nonExistentStory) {
   btn = (
    generating ? 'Generating...' : <BerfButton onClick={async () => {
        setGenerating(true)
        let res
        try {
          res = await apiPost('/user/scene', { storyIds: nonExistentStory })
        } catch (e) {
          window.alert('Error generating video')
        }
        if (res) {
          setVideoSrc(res.video)
          setNonExistentStory(null)
        }
        // const videos = theTrip.map(t => videosCache[storiesCache[t].videoId].video)
        // const d = await ffmpeg.concatMp4s(videos)
        // setVideoSrc(URL.createObjectURL(new Blob([d.buffer], {type: 'video/mp4'})))
        setGenerating(false)
      }}>generate</BerfButton>
    )
  }
  return (
    <div className="trip-viewer">
      {videoSrc && (
        <div className="mb-4">
          <VideoPlayer 
            className='mb-2' 
            url={videoSrc} 
            poster={`https://aiaiaiaiai.biz/images/${videoForStory(trip[0])?._id}/screenshot-0.jpg`}
          />
        </div>
      )}
      <div className="trip mt-4">
        {theTrip.map((t, i) => {
          return (
            <StoryCard 
              key={t} 
              storyId={t} 
              onClick={() => setStoryId(t)}
            >
              {i < theTrip.length - 1 && <a className='ml-auto pr-2 pl-6 flex' onClick={async () => {
                const newTrip = [...trip.slice(i + 1)]
                setTrip(newTrip)
              }}><FaRegTrashAlt />{i > 0 && <FaLongArrowAltUp />}</a>}
            </StoryCard>
          )
        })}
      </div>
      <div className='btn-wrap absolute bottom-4'>
        {btn}
      </div>
    </div>
  )
}