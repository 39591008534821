import { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { getUrl, estimateProcessingTime, isSomething, btnStyles, getVideo, imgUrlForFrame, isAudioVideo, apiJson } from "./utils"
import VideoSummary from "./VideoSummary"
import VideoPlayer from "./VideoPlayer"
import { DateTime } from "luxon"
import FrameChooser from "./FrameChooser"
import ffmpeg from './utils/ffmpeg'
import ThingList from "./ThingList"
import DescribeThing from "./DescribeThing"
import VideoClips from "./VideoClips"

const getStatus = (id) => fetch(getUrl() + `/status/${id}`).then(res => res.json())

function Video( ) {
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [status, setStatus] = useState({})
  const [stories, setStories] = useState(false)
  const lastId = useRef(null)
  const tout = useRef(null)
  useEffect(() => {
    if (!id || lastId.current === id) return
    lastId.current = id
    clearInterval(tout.current)
    setData(null)
    getVideo(id)
      .then(d => {
        setData(d)
        if (!d.finished) {
          getStatus(id).then(setStatus)

          tout.current = setInterval(() => {
            getStatus(id).then(setStatus)
          }, 30000)
        } else {
          apiJson(`/v/${id}/stories`).then(setStories)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [id])
  useEffect(() => {
    if (status.finished) {
      clearInterval(tout.current)
      window.location.reload()
    }
  }, [status])
  let started = null
  console.log('data', data)
  console.log('stories', stories)
  const createdAt = (new Date(data?.createdAt)).getTime()
  if (data?.started) {
    started = 'Started: ' + DateTime.fromMillis(data.started).toLocaleString(DateTime.DATETIME_MED) + ` (waited ${Math.floor((data.started - createdAt) / 1000 / 60)}m)`
  } else if (isSomething(status.placeInQueue)) {
    if (status.started) {

    } else if (status.placeInQueue === 0) {
      started = `In queue: You\'re next! It should take ${estimateProcessingTime(data?.config, 3)} to process`
    } else {
      started = `In queue: ${status.placeInQueue} videos ahead of you. It should take ${estimateProcessingTime(status.estimatedVideoSecs)} to get to yours`
    }
  }
  let finished = null
  if (data?.failed) {
    finished = 'Failed: ' + DateTime.fromMillis(data.failed).toLocaleString(DateTime.DATETIME_MED)
  } else if (data?.finished) {
    finished = 'Finished: ' + DateTime.fromMillis(data.finished).toLocaleString(DateTime.DATETIME_MED) + ` (processed in ${Math.floor((data.finished - data.started) / 1000 / 60)}m)`
  } else if (data?.started || status.started) {
    finished = `Processing: ~${estimateProcessingTime(data?.config, ((status.started - Date.now()) / 1000 / 60))} left to process`

  }
  return (
    <div className="text-center">
      <VideoPlayer alwaysVisible video={data} url={data?.video} />
      <VideoClips video={data} videoId={id} />
      {data && !data?.video && (
        <div className='py-20 text-2xl text-center'>
          {data?.failed ? 'This video failed to generate. We\'re on it.' : <>Generating <VideoSummary config={data?.config} /></>}
        </div>
      )}
      {/* <a onClick={async () => {
        const d = await ffmpeg.reverseMp4(data?.video)
        console.log('d', d)
        setSource(URL.createObjectURL(new Blob([d.buffer], {type: 'video/mp4'})))

      }}>🔄</a>
      {source && <VideoPlayer alwaysVisible loop={false} url={source} />} */}
      {data?.config && !data.config.generate && (
        <>
          <Link className={btnStyles} to={`/${isAudioVideo(data) ? 'audio' : 'narration'}/?v=${id}`}>🍴 Fork Clip</Link>
        </>
      )}
      {data?.video && (
        <>
          <VideoSummary config={data?.config} summary={data?.summary} />
          {/* <DescribeThing id={data?._id} type='video' /> */}
        </>
      )}
      <div className='mt-5 text-sm'>
        <div className='mb-2'>{finished}</div>
        <div className='mb-2'>{!data?.finished && started}</div>
        {data?.createdAt && !started && (
          <div className='text-xs'>
            Submitted: {DateTime.fromISO(data.createdAt).toLocaleString(DateTime.DATETIME_MED)}
          </div>
        )}
      </div>
    </div>
  )
}
export default Video