import classNames from "classnames";
import YoutubeForm from "./YoutubeForm";
import { useState, useContext, useEffect } from 'react'
import Narration from "./Narration";
import Checkout from "./Checkout";
import { omit, pick } from "ramda";
import VideoPlayer from "./VideoPlayer";
import { btnStyles, linkStyles, selectClass } from "./utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import AudioVideo from "./AudioVideo";
import GenerateVideo from "./GenerateVideo";
import AnimatedButton from "./common/AnimatedButton";
import { Context } from "./context/Context";
import { useSetThing } from "./context/hooks";
import ModeSelect from "./common/ModeSelect";
import "./HomePage.scss";

export default function HomePage() { 
  const [tab, setTab] = useState('')
  const tabs = ['youtube', 'narration']
  const [showBraintree, setShowBraintree] = useState(false)
  const [config, setConfig] = useState({})
  const { state, dispatch } = useContext(Context);
  const setThing = useSetThing()
  const location = useLocation()
  const navigate = useNavigate()
  const video = useRef(null)

  const { user, mode, userChecked } = state

  useEffect(() => {
    if (userChecked && user) {
      navigate('/n')
    }
  }, [userChecked, user])

  const onCheckout = (config) => {
    config.camera_config = {
      translation_y: '0:(0.3)',
      rotation_3d_y: '0:(0.3)',
      voice: config.voice || 'Bella',
      ...config.camera_config
    }
    setConfig(config)
    setShowBraintree(true)
  }

  let show = null
  let hp = false 
  let showAdvanced = false
  if (showBraintree) {
    show = <Checkout onCancel={() => setShowBraintree(false)} config={config} />
  } else if (/\/weird/.test(location.pathname)) {
    show = <GenerateVideo onCheckout={onCheckout} config={pick(['generate'], config)} />
  } else if (/\/audio/.test(location.pathname)) {
    showAdvanced = true
    show = <AudioVideo onCheckout={onCheckout} config={pick(['narration', 'topic'], config)} />
  } else if (/\/narration/.test(location.pathname)) {
    showAdvanced = true
    show = <Narration onCheckout={onCheckout} config={pick(['narration', 'topic'], config)} />
  } else if (/\/youtube/.test(location.pathname)) {
    show = <YoutubeForm onCheckout={onCheckout} config={omit(['narration'], config)} />  
  } else {
    hp = true
  }
  return (
    <div className={classNames('homepage')}>
      {hp && (
        <>
          <div className="block cave-wrap">
            <a className='intro' onClick={() => {
              if (video.current) {
                if (video.current.paused) {
                  video.current.play()
                } else {
                  video.current.pause()
                }
              } 
            }} />
            <VideoPlayer 
              alwaysVisible 
              url='https://aiaiaiaiai.biz/finals/66c5078c97713a848c9ffabd.mp4' 
              poster={`https://aiaiaiaiai.biz/images/663045cfa068e96bc01025ea/screenshot-0.jpg`}
              controls={false} 
              onRef={v => video.current = v } 
            />
            <div className='cave'>
              <h1>hi<br/>berfs<br/>club!</h1>
            </div>
          </div>
        </>
      )}
      {showAdvanced && !user && <ModeSelect />}
      {show}
    </div>
  )
}