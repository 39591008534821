import React, { useEffect, useRef } from 'react';
import { FPS, btnStyles, imgUrlForFrame } from './utils';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useIsVisible } from 'react-is-visible'
import { useSetThing } from './context/hooks';
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-playlist'
import 'videojs-playlist-ui'
import 'videojs-playlist-ui/dist/videojs-playlist-ui.css'


const VideoPlayer = ({ video, poster, url, controls, onRef, buttons, alwaysVisible, loop, muted, className }) => {
  const videoRef = useRef(null)
  const divRef = useRef(null)
  const playerRef = useRef(null)
  const isVisible = useIsVisible(divRef)
  const setThing = useSetThing() 
  const v = video || {}
  if (!url && video) {
    url = video.video
  }
  if (!poster && video) {
    poster = `https://aiaiaiaiai.biz/images/${video._id}/screenshot-0.jpg`
  }
  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");

      // videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, {
        controls: true,
        preload: 'auto',
        poster,
        muted,
        playsinline: true,
        loop: loop === true,
        fluid: true,
        sources: [{
          src: url,
          type: 'video/mp4'
        }]
      }, () => {
        videojs.log('player is ready');
        onRef && onRef(player);
      });

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;

      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);
  if (!url) return null
  const permalink = v?.config?.audio ? 'audio' : 'narration'
  return (
    <div className={classNames('video-player', className)} ref={divRef}>
      <div data-vjs-player>
        <div ref={videoRef} />
      </div>
    </div>
  );
}

export default VideoPlayer;