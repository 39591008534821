import { FaBriefcase } from "react-icons/fa6"; 
import { useRef, useState, useEffect } from 'react'; 
import classNames from 'classnames'
import "./MyStuff.scss";
import { logout, apiJson } from "./utils";
import { useFetchStories } from "./context/hooks";
import StoryCard from "./stories/StoryCard";
import VideoQueue from "./VideoQueue";
import useStore, { useSetThing } from "./context/hooks";
import { IoMenuSharp } from "react-icons/io5";


export default function MyStuff ({ onSelect }) {
  const [open, setOpen] = useState(false)
  const [logoutOpen, setLogoutOpen] = useState(false)
  const [myStories, setMyStories] = useState()
  const { fetchStories, storiesCache, nodeTextForStory } = useFetchStories()
  const { state, dispatch } = useStore()
  const { queue = [] } = state;
  const lastQueue = useRef(queue)
  useEffect(() => {
    if (!open) return
    if (myStories) return
    apiJson('/user/stories')
      .then(({ stories }) => setMyStories(stories))
  }, [open, myStories])
  useEffect(() => {
    if (!myStories) return
    fetchStories(myStories.map(({ _id }) => _id))
  }, [myStories])
  useEffect(() => {
    if (lastQueue.current && lastQueue.current.length > 0 && (!queue || queue.length === 0)) {
      setMyStories(null)
    }
    lastQueue.current = queue
  }, [queue])
  let show = null
  if (open) {
    show = (
      <>
        <VideoQueue />
        <div className='content relative'>
          {myStories && myStories.map(({ finished, _id, title, nodes, videos }) => {
            if (!finished) return null
            return (
              <StoryCard 
                key={_id} 
                storyId={_id} 
                onClick={onSelect}
              />
            )
          })}
          <a className={classNames('logout')} onClick={() => {
            if (!window.confirm('logout?')) return
            logout(dispatch)
            setOpen(false)
          }}><span>l8r </span>🐊<span>?</span></a>
        </div>
      </>
    )
  }
  return (
    <div className={classNames('my-stuff', { open })}>
      <a className='open' onClick={() => setOpen(!open)}>
        {queue && queue.length > 0 && (
          <div className='px-1 badge bg-yellow-400 text-black absolute top-[-8px] right-[-8px] z-10 text-xs rounded-full'>{queue.length}</div>
        )}
        {/* <FaBriefcase /> */}
        <IoMenuSharp />
      </a>
      {show}
    </div>

  )
}