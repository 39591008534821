import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import EventEmitter from 'wavesurfer.js/dist/event-emitter';

class Ai5Ffmpeg extends EventEmitter {
  _log = ''

  async _load () {
    if (this.loaded) return
    this.loaded = true
    this.message = ''
    // const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm'
    const baseURL = 'https://aiaiaiaiai.biz/ffmpeg'
    this.ffmpeg = new FFmpeg() 
    this.ffmpeg.on('log', ({ message }) => {
      this.message = message
      this._log += message + '\n'
      console.log(message);
    });
    this.ffmpeg.on('error', (e) => {
      console.error('error', e)
    })
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await this.ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
        // workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript')
    });
  }

  log() {
    return this._log
  }

  async convertGifToMp4 (file) {
    await this._load()
    await this.ffmpeg.writeFile('input.mp4', await fetchFile(file));
    // ffmpeg -i 200w-1.gif -r 10 -vf "crop=min(iw\,ih):min(iw\,ih),scale=512:512" -c:v libx264 -pix_fmt yuv420p sara.mp4
    await this.ffmpeg.exec(['-i', 'input.mp4', '-r', '10', '-vf', 'crop=min(iw\\,ih):min(iw\\,ih),scale=512:512', '-c:v', 'libx264', '-pix_fmt', 'yuv420p', 'output.mp4']);
    const duration = await this.ffmpeg.exec(['-i', 'output.mp4', '-f', 'null', '-']);
    console.log('duration', duration)
    return await this.ffmpeg.readFile('output.mp4');
  }

  async convertMp4ToMp3 (file) {
    await this._load()
    await this.ffmpeg.writeFile('input.mp4', await fetchFile(file));
    await this.ffmpeg.exec(['-i', 'input.mp4', '-vn', '-acodec', 'mp3', 'output.mp3']);
    return await this.ffmpeg.readFile('output.mp3');
  }
  async reverseMp4 (file) {
    await this._load()
    await this.ffmpeg.writeFile('input.mp4', await fetchFile(file));
    await this.ffmpeg.exec(['-i', 'input.mp4', '-vf', 'reverse', 'output.mp4']);
    return await this.ffmpeg.readFile('output.mp4');
  }

  async concatMp4s (files) {
    await this._load()
    let list = ''
    for (const f of files) {
      const endPart = f.split('/').pop()
      list += `file ${endPart}\n`
      await this.ffmpeg.writeFile(endPart, await fetchFile(f));
    }
    await this.ffmpeg.writeFile('list.txt', list);
    await this.ffmpeg.exec(['-f', 'concat', '-safe', '0', '-i', 'list.txt', '-c', 'copy', '-loglevel', 'debug', 'output.mp4']);
    return await this.ffmpeg.readFile('output.mp4');
  }

  async createSilence (duration) {
    await this._load()
    await this.ffmpeg.exec(['-f', 'lavfi', '-i', `anullsrc=channel_layout=stereo:sample_rate=44100:duration=${duration}`, 'silence.mp3']);
    const d = await this.ffmpeg.readFile('silence.mp3');
    return URL.createObjectURL(new Blob([d], {type: 'audio/mpeg'}))
  }
}
export default new Ai5Ffmpeg