import React, { useState, useContext, useEffect } from "react";
import { Context } from './context/Context';
import Select from 'react-select/creatable'
import "./ThingList.scss"
import { prop, propEq, uniqBy } from "ramda";


const darkTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: 'hotpink',
      primary: 'black',
      neutral0: 'black',
      neutral80: 'white',
    },
  }
}

export default function ThingList ({ before, type, onClick, placeholder, options }) {
  const { state, dispatch } = useContext(Context);
  const [input, setInput] = useState('')
  const { user } = state
  if (!user) return null
  let things = user.things
    .filter(propEq(type, 'type'))
    .sort((a,b) => {
      // if no createdAt put at the end
      if (!a.createdAt) return 1
      if (!b.createdAt) return -1
      return Date.parse(b.createdAt) - Date.parse(a.createdAt)
    })
  const handleInputChange = (newValue, actionMeta) => {
    setInput(newValue);
  }
  const handleChange = (e) => {
    if(e && e.__isNew__) {
      onClick(e.value)
    } else if(onClick) {
      onClick(things.find(t => t.to === e.value) || e.value);
    }
    setInput('')
  };

  const formatCreateLabel = (inputValue) => `${inputValue}`;


  let opts = uniqBy(prop('from'), things)
    .map(t => ({ value: t.to, label: t.from }))
  if (type !== 'audio') {
    opts = opts.concat((user.tags || []).map(t => ({ value: t, label: t })))
  }
  if (options) {
    opts = opts.concat(options.map(t => ({ value: t, label: t })))
  }
  opts = uniqBy(prop('value'), opts)
  return (
    <div className="thing-list">
      {before}
      <Select 
        onInputChange={handleInputChange}
        theme={darkTheme}
        inputValue={input}
        value={null}
        classNamePrefix='react-select'
        placeholder={placeholder || `Search ${type.replace('-', ' ')}`}
        options={opts} 
        onChange={handleChange}
        formatCreateLabel={formatCreateLabel}
      />
    </div>
  )
}