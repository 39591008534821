import React, { useState } from "react";
import { textInputClass } from "./utils";
import classNames from "classnames";

export default function AddStory ({}) {
  const [title, setTitle] = useState('')
  const maxChars = 140
  const remainingChars = maxChars - title.length

  return (
    <div className='add-story w-full m-3'>
      <label htmlFor="title">Title:</label>
      <input 
        className={classNames(textInputClass, 'w-full')} 
        value={title} 
        placeholder={``}
        onChange={evt => {
          setTitle(evt.target.value.slice(0, maxChars))
        }} 
      />
      <p className="text-right text-sm text-gray-500">{remainingChars}</p>
    </div>
  )
}