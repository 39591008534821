import "./GenerateVideo.scss"
import { useState } from 'react'
import { VOICES } from "./utils"
import classNames from "classnames"
import Price from "./common/Price"
import { Link } from "react-router-dom"

export default function GenerateVideo({ onCheckout, config = {} }) {
  const [text, setText] = useState(config.generate || '')
  const [type, setType] = useState('story')
  const playholders = {
    story: '"Computer, make me a video about..."',
    text: 'The computer will narrate and make a video of this exact text. It will use the "." at the end of each sentence to determine new scenes.'
  }
  const c = { generate: text, voice: VOICES[Math.floor(Math.random() * VOICES.length)][1]  }
  if (type === 'text') {
    delete c.generate
    c.text = text
  }
  return (
    <div className='generate-video relative'>
      <div className="instructions">
        <select className={classNames('select', 'mb-5')} value={type} onChange={e => setType(e.target.value)}>
          <option value='story'>explain story and computer make it.</option>
          <option value='text'>convert raw text into video</option>
        </select>
        
      </div>
      <textarea 
        autoFocus
        className='text' 
        value={text} 
        onChange={e => setText(e.target.value)} placeholder={playholders[type]}
      />
      <p className='fixed bottom-0 w-full p-3 bg-black text-center'><Link className="text-sm" to='/narration'>ready for something a bit more advanced?</Link></p>
      {text && (
        <div className="buttons p-5">
          <button className={classNames('btn', 'mt-0')} onClick={() => {
            onCheckout(c)
          }}>Generate Video <Price config={c} /></button>
        </div>
      )}
    </div>
  )
}