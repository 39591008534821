import { useRef, useEffect, useState } from 'react';
import YouTube from 'react-youtube'
import { btnStyles, getPrice, getUrl, playAi, textInputClass } from './utils';
import classNames from 'classnames';
import Checkout from './Checkout';
require('isomorphic-fetch')


function YoutubeForm({ onCheckout, config }) {
  const [youtube, setYoutube] = useState('')
  const [youtubeId, setYoutubeId] = useState('')
  const [youtubeError, setYoutubeError] = useState(false)
  const [duration, setDuration] = useState(0)
  const [_config, setConfig] = useState(config || {})
  const interval = useRef(null)
  useEffect(() => {
      // Extract the video ID from the URL
    const videoIdRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([0-9a-zA-z-]{11})/i

    const match = youtube.match(videoIdRegex);

    // Return the video ID if found, otherwise return null
    if (match && match[1]) {
      setConfig(prevConfig => ({
        ...prevConfig,
        youtubeId: match[1]
      }))
    } else {
      setConfig(prevConfig => ({
        ...prevConfig,
        youtubeId: null 
      }))
    }
  }, [youtube, setConfig])
  const purchase = () => {
    onCheckout && onCheckout(_config)
  }
  // const purchase = async () => {
  //   const authorization = await fetch(`${getUrl()}/payment/`).then(res => res.text())
  //   window.braintree.dropin.create({
  //     authorization,
  //     container: document.getElementById('dropin-container'),
  //     // ...plus remaining configuration
  //   }, (error, dropinInstance) => {
  //     console.log(dropinInstance)
  //     // Use `dropinInstance` here
  //     // Methods documented at https://braintree.github.io/braintree-web-drop-in/docs/current/Dropin.html
  //   });
  // }
  const youtubeOpts = {
    width: '100%',
    height: '300px',
    playersVars: {
      modestbranding: 1,
      loop: 1,
      controls: 0
    }
  }
  if (_config.start) {
    youtubeOpts.playersVars.start = _config.start
  }
  if (_config.end) {
    youtubeOpts.playersVars.end = _config.end
  }
  const inputClass = textInputClass
  let controls = (
    <>
        <div className='grid grid-cols-2 gap-5 mt-5'>
        <label className="block">
          <span >Start</span>
          <input 
            type="text" 
            value={_config.start || 0}
            onChange={(e) => setConfig({ ..._config, start: parseInt(e.target.value) })}
            className={inputClass} 
            placeholder="" 
          />
        </label>
        <label className="block">
          <span >End <span className='text-sm'>(total: {duration}s)</span></span>
          <input 
            type="text" 
            value={_config.end || ''}
            onChange={(e) => {
              const end = parseInt(e.target.value)
              // if (end > _config.start) {
                setConfig({ ..._config, end })
              // }
            }}
            className={inputClass} 
            placeholder="" 
          />
        </label>
        </div>
      <label className="block mt-5">
        <span>Starting Prompt<sup>*</sup></span>
        <input 
          type="text" 
          value={_config.topic || ''}
          onChange={(e) => setConfig({ ..._config, topic: e.target.value })}
          className={inputClass} 
          placeholder="" 
        />
      </label>
      <p className='text-sm mt-1 text-gray-300'>The prompt for the inital image</p>
      <label className="block mt-5">
        <span>Prompt after every prompt (optional)</span>
        <input 
          type="text" 
          value={_config.after || ''}
          onChange={(e) => setConfig({ ..._config, after: e.target.value })}
          className={inputClass} 
          placeholder="" 
        />
      </label>
      <p className='text-sm mt-1 text-gray-300'>This prompt will be inserted after every transcribed prompt (useful for "in the style of" type prompts)</p>
      {_config.topic && <button className={classNames(btnStyles, 'mt-5')} onClick={purchase}>Generate Video ${getPrice(_config)}</button>}
    </>
  )
  if (youtubeError) {
    controls = null
  }
  let show = []
  show.push(
    <div key='youtube_url' className='mb-5'>
      <label className="block">
        <span >Youtube URL<sup>*</sup></span>
        <input 
          type="text" 
          value={youtube}
          onChange={(e) => setYoutube(e.target.value)}
          className={inputClass} 
          placeholder="" 
        />
      </label>
    </div>
  )
  if (_config.youtubeId) {
    show.push(
      <div key='controls'>
        <YouTube 
          opts={youtubeOpts} 
          videoId={_config.youtubeId} 
          onReady={(e) => {
            setYoutubeError(false)
            if (!_config.end) {
              const duration = e.target.getDuration()
              setDuration(duration)
              const end = duration 
              setConfig(prevConfig => ({
                ...prevConfig,
                start: 0,
                end
              }))
            }
          }}
          onPlay={(e) => {
            const t = e.target.getCurrentTime()
            if (t < _config.start) {
              e.target.seekTo(_config.start)
              return
            }
            clearInterval(interval.current)
            interval.current = setInterval(() => {
              const t = e.target.getCurrentTime()
              if (_config.end > _config.start && t > _config.end) {
                e.target.seekTo(_config.start)
              }
            }, 500)
          }}
          onPause={(e) => {
            clearInterval(interval.current)
          }}
          onError={e => {
            setYoutubeError(true)
          }}
        />
        {controls}
      </div>
    )
  }
  return show
}

export default YoutubeForm;
