import { useMemo, useRef, useEffect } from "react";
import * as d3 from "d3";
import { on, uniq } from "ramda";

const MARGIN = { top: 0, right: 10, bottom: 0, left: 10 };


export const Dendrogram = ({ width, height, hierarchy, selected, depth, onClick }) => {
  const svgRef = useRef(null); // Ref for the SVG container

  useEffect(() => {
    if (!hierarchy) return; // Guard clause if data is not yet available

    // Setup dimensions
    const boundsWidth = width - MARGIN.right - MARGIN.left;
    const boundsHeight = height - MARGIN.top - MARGIN.bottom;

    let dendrogram = d3.tree()
      .size([boundsHeight, boundsWidth])
      (hierarchy);
    
    // align the nodes on top
    // const lowestX = d3.min(dendrogram.descendants(), d => d.x);
    // let currentDepth = false
    // dendrogram.each(d => {
    //   if (currentDepth !== d.depth) {
    //     d.x = lowestX
    //     currentDepth = d.depth
    //   }
    // })
    // const allXs = uniq(dendrogram.descendants().map(d => d.x)).sort((a, b) => a - b)
    // dendrogram.each(d => {
    //   if (d.parent && d.parent.children.length > 1) {
    //     const myIndex = d.parent.children.indexOf(d)
    //     d.x = allXs[myIndex]
    //   }
    // })

    const svg = d3.select(svgRef.current);

    // Clear previous contents
    svg.selectAll("*").remove();

    // Setup the group for the dendrogram
    const g = svg.append("g")
                 .attr("transform", `translate(${MARGIN.left},${MARGIN.top})`);

    // Generate links
    const links = dendrogram.links();
    const linkPathGenerator = d3.linkHorizontal()
                                 .x(node => node.y)
                                 .y(node => node.x);

    g.selectAll(".link")
     .data(links)
     .enter().append("path")
     .attr("class", "link")
     .attr("d", linkPathGenerator)
     .style("fill", "none")
     .style("stroke", "#CFFC52");

    // Generate nodes
    const nodes = dendrogram.descendants();

    const node = g.selectAll(".node")
                  .data(nodes)
                  .enter().append("g")
                  .attr("class", "node")
                  .attr("transform", d => `translate(${d.y},${d.x})`);

    node.append("circle")
        .attr("r", (d, i) => selected === d.data.id || selected === d.data?.data?.id || (depth && i === 0) ? 6 : 4)
        .attr('style', d => selected === d.data.id || selected === d.data?.data?.id ? 'filter: drop-shadow(0 0 5px #CFFC52)' : '')
        .style("fill", "#CFFC52")
        .on("click", (event, d) => {
          // Handle click event here
          onClick && onClick(d)
        });

    if (depth) {
      node.filter((d, i) => i === 0) // Filter for the first node
        .append("text")
        .attr("dx", 0) // Adjust text position relative to the node circle
        .attr("dy", ".35em") // Vertically center the text
        .attr("text-anchor", "middle") // Center the text horizontally
        .attr("font-size", "9px") // Set text size
        .attr('cursor', 'pointer')
        .text(depth)
        .style("fill", "black") // Set text color
        .on("click", (event, d) => {
          // Handle click event here
          onClick && onClick(d)
        });
    }

    // Optionally, add labels or other features here

  }, [hierarchy, height, selected, width]); // Re-run effect if any of these dependencies change

  return (
    <svg ref={svgRef} width={width} height={height}></svg>
  );
};