import React, { useState } from 'react';

function Accordion({ tabs = [] }) {
  const [activeTab, setActiveTab] = useState(null);

  const toggleTab = (index) => {
    if (activeTab === index) {
      // Clicking an open tab should close it
      setActiveTab(null);
    } else {
      // Clicking a closed tab should open it
      setActiveTab(index);
    }
  };

  return (
    <div className="accordion space-y-2">
      {tabs.map((tab, index) => (
        <div key={index} className="rounded shadow">
          <div
            className={`text-white hover:bg-pink-950 p-3 cursor-pointer ${
              activeTab === index ? 'bg-pink-500' : ''
            }`}
            onClick={() => toggleTab(index)}
          >
            {tab.label}
          </div>
          {activeTab === index && (
            <div className="p-3">{tab.content}</div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Accordion;
