import React, { useState, useEffect, useRef, useCallback } from "react";
import { apiFetch, apiJson, apiPost, inputsCommon } from "./utils";
import classNames from "classnames";
import { useStore, useSetThing } from "./context/hooks";
import { values } from "ramda";
import ThingList from "./ThingList";
import { Link } from "react-router-dom";
import { IoInformationCircle } from "react-icons/io5";

import './DescribeThing.scss'

export default function DescribeThing ({ id, type, onComplete }) {
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])
  const { dispatch, state } = useStore()
  const { user } = state
  const timerRef = useRef(null)
  const [helpOpen, setHelpOpen] = useState(false)
  const setThing = useSetThing()
  const refreshTags = useCallback(async () => {
    const t = await apiJson(`/search/tags?type=${type}&id=${id}`)
    setTags(t)
  }, [setTags, id, type])
  useEffect(() => {
    // Debounce the refreshTags function with a delay of 200ms
    const delay = 500
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(refreshTags, delay);

    // Clean up the timer on component unmount or dependency change
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [refreshTags]);
  const submit = async tag => {
    const thing = {
      type,
      id,
      tag,
    }
    const alreadyTagged = tags.find(t => t.tag === tag && t.mine)
    if (!alreadyTagged) {
      let foundTag = false
      setTags(tags.map(t => {
        if (t.tag === tag) {
          t.mine = true
          t.tagCount++
          foundTag = true
        }
        return t
      }))
      if (!foundTag) {
        setTags([...tags, {
          tag,
          mine: true,
          tagCount: 1,
        }])
      }
      const res = await apiPost('/user/describe', thing)
      refreshTags()
    }
    setDescription('')
    onComplete && onComplete(tag)
  }
  let show = (
    <div className='pt-5 pb-1'>
      <a className="mb-1 inline-block text-white" onClick={() => setHelpOpen(!helpOpen)}>
        <IoInformationCircle />
      </a>
      {helpOpen && (
        <p className='text-sm pb-2'>
          There are a few ways you can tag. 
          <ul>
            <li>Use keywords, like a normal #hashtag</li>
            <li>Describe what's happening in the video/image</li>
            <li>Describe how you would use it to build something larger</li>
          </ul>
          All of these descriptions are valuable 
        </p>
      )}
      <ThingList 
        type={type} 
        options={tags.filter(t => !t.mine).map(t => t.tag)}
        placeholder='we name/describe things to save them'
        onClick={(thing) => {
          submit(typeof thing === 'string' ? thing : thing.from)
        }} 
      />
    </div>
  )
  if (loading) {
    show = (
      <div className='flex justify-center items-center'>
        Saving...
      </div>
    )
  }
  // const descriptions = user?.things?.filter(t => t.to === to)
  return (
    <div className={classNames('the-thing', type)}>
      {show}
      <ul className="mt-3 tags overflow-auto">
        {tags?.map(d => (
          <li key={d.tag} className={classNames({ mine: d.mine })}>
            {!d.mine && (
              <a
                onClick={async () => {
                  const thing = {
                    type,
                    id,
                    tag: d.tag,
                  }
                  setTags(tags.map(t => {
                    if (t.tag === d.tag) {
                      t.mine = true
                      t.tagCount++
                    }
                    return t
                  }))
                  const res = await apiPost('/user/describe', thing)
                }}
              >️👍</a>
            )}
            <span className="tag-name">
              <Link to={`/c/${d.tag}`}>
                {d.tag} 
              </Link>
              <span className='text-xs p-1'>({d.tagCount})</span>
            </span>
            {d.mine && (
              <a
                onClick={async () => {
                  const thing = {
                    type,
                    id,
                    tag: d.tag,
                  }
                  setTags(tags.map(t => {
                    if (t.tag === d.tag) {
                      t.mine = false
                      t.tagCount--
                    }
                    return t
                  }))
                  const res = await apiPost('/user/describe/remove', thing)
                }}
              >x</a>
            )}
          </li>
        ))}
      </ul>
    </div>
  )

}