import React, { useState, useContext, useEffect } from "react";
import { Context } from '../context/Context';
import { abbreviateNumber, getFire, getPrice } from "../utils";

export default function Price ({ config }) {
  const { state, dispatch } = useContext(Context);
  const { user } = state
  if (user) {
    return `${abbreviateNumber(getFire(config))} 🪙`
  }
  return `$${getPrice(config)}`
}