import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Video from './Video';
import Ai5HomePage from './AiaiaiaiaiHomePage';
import BerfHomePage from './HomePage';
import { Provider } from './context/Context';
import Videos from './Videos';
import ErrorPage from './ErrorPage';
import { isBerf } from './utils';
import See from './See';
import UserPage from './UserPage';
import Narratives from './Narratives';

const HomePage = isBerf() ? BerfHomePage : Ai5HomePage

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "/me",
        element: <UserPage /> 
      },
      {
        path: "/weird",
        element: <HomePage />
      },
      {
        path: "/videos",
        element: <Videos />
      },
      {
        path: "/audio",
        element: <HomePage />
      },
      {
        path: "/narration",
        element: <HomePage />
      },
      {
        path: "/n/:id",
        element: <Narratives />
      },
      {
        path: "/n",
        element: <Narratives />
      },
      {
        path: "/youtube",
        element: <HomePage />
      },
      {
        path: "/c/:tag",
        element: <See /> 
      },
      {
        path: "/v/:id",
        element: <Video /> 
      }
    ]
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
