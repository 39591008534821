import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';



export default function Pop({ children, side = 'top' }) {
  return (
    <div className='popover'>
      <Popover.Root open>
        <Popover.Portal>
          <Popover.Content
            className={`pop-content rounded-full py-4 px-2 text-yellow-400 z-10 side-${side}`}
            sideOffset={5}
            side={side}
          >
            {children}
            <Popover.Arrow className="" />
          </Popover.Content>
        </Popover.Portal>
        <Popover.Trigger asChild>
          <button
            className={classNames("")}
            aria-label="Menu"
          >
          </button>
        </Popover.Trigger>
      </Popover.Root>
    </div>
  )
}