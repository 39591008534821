import { useEffect, useState } from 'react'
import { apiJson } from './utils'
import VideoPlayer from './VideoPlayer2'
import VideoClip from './VideoClip'

/**
 * clips = {
 *   clipIds: [],
 *   videos: []
 * } 
 */
export default function VideoClips ({ videoId, video, clips }) {
  const [_clips, setClips] = useState([])
  useEffect(() => {
    if (!videoId || clips) return
    apiJson(`/v/${videoId}/clips`)
      .then(what => {
        console.log('clips', what)
        setClips(what)
      })
  }, [videoId])
  if (!videoId && !clips) return null
  const theClips = clips ? clips.clipIds : _clips
  if (!theClips.length) return null
  return (
    <div className='video-clips py-5'>
      <h3 className='text-left mb-2'>Clips</h3>
      <div className='clips flex h-40'>
        {theClips.map(clipId => {
          const theVideo = clips ? clips.videos.find(v => v._id === clipId.split('-')[0]) : video
          return (
            <VideoClip 
              className='w-40 mr-5'
              key={clipId} 
              clipId={clipId} 
              video={theVideo} 
            />
          )
        })}
      </div>
    </div>
  )
}