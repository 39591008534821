import React, { useState } from "react"
import { getSecs } from "./utils"
import classNames from "classnames"
import { Link } from "react-router-dom"
import Voice from "./common/Voice"

export default function VideoSummary ({ config, summary }) {
  const [open, setOpen] = useState(false)
  if (summary) {
    return (
      <p className={classNames("video-summary py-5")}>
        {summary}
      </p>
    )
  }
  if (!config) return null
  let desc = (
    <>
      Start: {config.start}<br/>
      End: {config.end}<br/>
      Duration: {config.end - config.start}<br/>
      Starting Prompt: {config.topic}<br/>
    </>
  )
  const aVideo = config.from ? <Link to={`/v/${config.from}`} className="">forked video</Link>: 'video'
  if (config.narration) {
    console.log(config)
    desc = (
      <>
        a {aVideo} narrated by <Voice voice={config.voice} />  with {config.narration.length} clip{config.narration.length === 1 ? '' : 's'} &nbsp;
        <a onClick={() => setOpen(!open)}>{open ? '❌' : '👁️'}</a>
        <ul className={classNames("text-left w-1/2 m-auto py-5", { hidden: !open })}>
          {config.narration.map((n, i) => (
            <li key={i}>
              "{n.narration}"
            </li>
          ))}
        </ul>
      </>
    )
  } else if (config.prompts) {
    desc = (
      <>
        <b>{getSecs(config)} second scored {aVideo}</b> starting "{config.prompts['0'].slice(0, 100)}..."<br/>
      </>
    )
  } else if (config.text) {
    desc = (
      <p>a mechanically generated {aVideo} of "{config.text}"</p>
    )
  } else if (config.generate) {
    desc = (
      <p>a mechanically generated {aVideo} about "{config.generate}"</p>
    )
  }
  return (
    <div className={classNames("video-summary py-5", { open })}>
      {desc}
    </div>
  )
}
