import { path, last } from 'ramda'

const COST_PER_30 = 1.00
const FIRE_TO_DOLLAR = 10000 
export const FPS = 10
export const POP_COLOR = '#84FA4E'

export const isLocal = () => {
  return /localhost/.test(window.location.host)
}

export const getUrl = () => {
  return /localhost:3001/.test(window.location.host) ? 'http://localhost:3000' : 'https://lemorgan.aiaiaiaiai.biz'
}
export const isBerf = () => {
  if (/localhost/.test(window.location.host)) {
    return true
    //  return false 
  }
  return /berf/.test(window.location.host) 
}
export const isTv = () => {
  return true
  if (/localhost/.test(window.location.host)) {
    return true
    //  return false 
  }
  if (!isBerf()) {
    return false
  }
  return /tv/.test(window.location.host) 
}

export const site = (slug) => {
  let s = 'berf'
  if (!isBerf()) {
    s = 'aiaiaiaiai'
  } else if (isTv()) {
    s ='tv'
  }

  if (slug) {
    return slug === s
  } else {
    return s 
  }
}

export const getClipVideoId = clipId => clipId.split('-')[0]
export const getClipStart = clipId => parseInt(clipId.split('-')[1], 10)
export const getClipEnd = clipId => parseInt(clipId.split('-')[2], 10)

export const estimateProcessingTimeRaw = (secs, add = 0) => {
  return Math.floor(secs * 0.4 + add)
}
export const estimateProcessingTime = (secs, add = 0) => {
  if (typeof secs === 'object') secs = getSecs(secs)
  const mins = estimateProcessingTimeRaw(secs, add)
  if (mins > 0) {
    return `${mins}m`
  }
  return 'basically like no time'
}

export const isSomething = (v) => v || v === 0

export const getSecs = (config = {}) => {
  let secs = 0
  if (config.narration) {
    secs = config.narration.reduce((acc, t) => {
      if (isPause(t)) return acc + /pause-(\d+)/.exec(t.narration)[1] * 1
      return acc + estimateSpeechDuration(t.narration)
    }, 0)
  } else if (config.generate) {
    secs = 60
  // youtube
  } else if (config.end) {
    secs = (config.end - config.start)
  } else if (config.audioDuration) {
    secs = config.audioDuration
  } else if (config.text) {
    secs = estimateSpeechDuration(config.text)
  }
  return Math.ceil(secs)
}

export const getPrice = (config) => {
  return COST_PER_30 * Math.ceil(getSecs(config) / 3) / 10 // 10 seconds per 30 cents/btn
}
export const getFire = (config) => {
  const p = typeof config === 'object' ? getPrice(config) : parseFloat(config)
  return Math.floor(p * FIRE_TO_DOLLAR)
}

export const isAudioVideo = video => {
  return video?.config?.audio || video?.config?.silence 
}
export const getVideoUrl = data => {
  return `/${isAudioVideo(data) ? 'audio' : 'narration'}/?v=${data._id}`
}

export const btnStyles = 'btn'
export const crazyBtnStyle = 'inline-block bg-gradient-to-tr from-green-400 to-blue-600 hover:from-blue-600 hover:to-green-400 text-xl font-extrabold py-6 px-12 rounded-full shadow-5xl transform transition-all duration-700 border-8 border-purple-700'
export const linkStyles = 'text-pink-500 hover:text-pink-700 cursor-pointer'
export const inputsCommon = isTv() ? 'input' : `
  rounded-md
  border border-lime-400  bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700
  outline outline-0 transition-all placeholder-shown:border  focus:border-2 focus:border-pink-500 focus:border-t-blue-300 focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50
`
export const selectClass = inputsCommon.replace('py-2.5', 'py-1') + ` pr-7`
export const textInputClass = `
  mt-1
  block
  w-full
  ${inputsCommon}
`
export const h1Styles = 'text-3xl mb-3 text-left'
export const hintStyles = 'text-sm text-gray-400'

var audio = new Audio();
audio.src = '//aiaiaiaiai.biz/aiaiaiaiai.mp3';
export const playAi = () => {
  audio.play();
}

export function estimateSpeechDuration(phrase) {
  // Adjust the average speaking rate according to your needs
  const averageSpeakingRate = 160; // Words per minute

  // Calculate the number of words by splitting the phrase on spaces
  const words = phrase.trim().split(/\s+/);
  const wordCount = words.length;

  // Calculate the estimated duration in seconds
  const estimatedDuration = (wordCount / averageSpeakingRate) * 60;

  // Return the estimated duration in seconds
  return estimatedDuration;
}

export const isPause = (t) => {
  return /pause-\d+/.test(t.narration)
}
export const getPause = (t) => {
  if (!isPause(t)) return 0
  return /pause-(\d+)/.exec(t.narration)[1] * 1
}

export const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 1)`
export const blankNarrationPhrase = () => {
  return { 
    _id: uuid(),
    prompt: '', 
    narration: '', 
    style: '',
    neg_prompt: '', 
    color: randomColor(), 
    start: 0, 
    camera_movement: '',
    strength: 0.65
  }
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function getVideo(id) {
  return fetch(getUrl() + `/v/${id}`)
    .then(res => res.json())
}

const random = (min, max) => Math.random() * (max - min) + min

export const VOICES = [
  [
      "Rachel",
      "21m00Tcm4TlvDq8ikWAM"
  ],
  [
      "Clyde",
      "2EiwWnXFnvU5JabPnv8n"
  ],
  [
      "Domi",
      "AZnzlk1XvdvUeBnXmlld"
  ],
  [
      "Dave",
      "CYw3kZ02Hs0563khs1Fj"
  ],
  [
      "Fin",
      "D38z5RcWu1voky8WS1ja"
  ],
  [
      "Bella",
      "EXAVITQu4vr4xnSDxMaL"
  ],
  [
      "Antoni",
      "ErXwobaYiN019PkySvjV"
  ],
  [
      "Thomas",
      "GBv7mTt0atIp3Br8iCZE"
  ],
  [
      "Charlie",
      "IKne3meq5aSn9XLyUdCD"
  ],
  [
      "Emily",
      "LcfcDJNUP1GQjkzn1xUU"
  ],
  [
      "Elli",
      "MF3mGyEYCl7XYWbV9V6O"
  ],
  [
      "Callum",
      "N2lVS1w4EtoT3dr4eOWO"
  ],
  [
      "Patrick",
      "ODq5zmih8GrVes37Dizd"
  ],
  [
      "Harry",
      "SOYHLrjzK2X1ezoPC6cr"
  ],
  [
      "Liam",
      "TX3LPaxmHKxFdv7VOQHJ"
  ],
  [
      "Dorothy",
      "ThT5KcBeYPX3keUQqHPh"
  ],
  [
      "Josh",
      "TxGEqnHWrfWFTfGW9XjX"
  ],
  [
      "Arnold",
      "VR6AewLTigWG4xSOukaG"
  ],
  [
      "Charlotte",
      "XB0fDUnXU5powFXDhCwa"
  ],
  [
      "Matilda",
      "XrExE9yKIg1WjnnlVkGX"
  ],
  [
      "Matthew",
      "Yko7PKHZNXotIFUBG7I9"
  ],
  [
      "James",
      "ZQe5CZNOzWyzPSCn5a3c"
  ],
  [
      "Joseph",
      "Zlb1dXrM653N07WRdFW3"
  ],
  [
      "Jeremy",
      "bVMeCyTHy58xNoL34h3p"
  ],
  [
      "Michael",
      "flq6f7yk4E4fJM5XTYuZ"
  ],
  [
      "Ethan",
      "g5CIjZEefAph4nQFvHAz"
  ],
  [
      "Gigi",
      "jBpfuIE2acCO8z3wKNLl"
  ],
  [
      "Freya",
      "jsCqWAovK2LkecY7zXl4"
  ],
  [
      "Grace",
      "oWAxZDx7w5VEj9dCyTzz"
  ],
  [
      "Daniel",
      "onwK4e9ZLuTAKqWW03F9"
  ],
  [
      "Serena",
      "pMsXgVXv3BLzUgSXRplE"
  ],
  [
      "Adam",
      "pNInz6obpgDQGcFmaJgB"
  ],
  [
      "Nicole",
      "piTKgcLEGmPE4e6mEKli"
  ],
  [
      "Jessie",
      "t0jbNlBVZ17f02VDIeMI"
  ],
  [
      "Ryan",
      "wViXBPUzp2ZZixB1xQuM"
  ],
  [
      "Sam",
      "yoZ06aMxZJJ28mfd3POQ"
  ],
  [
      "Glinda",
      "z9fAnlkpzviPz146aGWa"
  ],
  [
      "Giovanni",
      "zcAOhNBS3c14rBihAFp1"
  ],
  [
      "Mimi",
      "zrHiDhphv9ZnVXBqCLjz"
  ],
  [
      "valley-girl",
      "1LvafSutRGx7uuI9vtJ6"
  ],
  [
      "radio-guy",
      "6NhbsygYw3HZBhDC2UfU"
  ],
  [
      "british-deep",
      "R2K6xXCciBV3uRkOOodf"
  ]
]

export const doLogin = async jwt => {
  if (!jwt && /^t|/.test(window.location.hash)) {
    jwt = decodeURIComponent(window.location.hash).replace('#t|', '')
    window.location.hash = ''
  }
  if (jwt) {
    localStorage.setItem('aiaiaiaiai-jwt', jwt)
  }
  if (!jwt) {
      jwt = localStorage.getItem('aiaiaiaiai-jwt')
  }
  if (jwt) {
    return fetch(getUrl() + '/user', {
      headers: {
        'Authorization': `Bearer ${jwt}`
      }
    })
      .then(res => res.json())
      .then(things => {
        return things 
      })
      .catch(err => {
        console.log(err)
        return false
      })
  }
}

export const apiFetch = async (pth, opts = {}) => {
  const jwt = localStorage.getItem('aiaiaiaiai-jwt')
  if (jwt) {
    opts.headers = opts.headers || {}
    opts.headers['Authorization'] = `Bearer ${jwt}`
  }
  return fetch(getUrl() + pth, opts)
}

export const apiJson = async (pth) => {
  return apiFetch(pth, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json())
  .catch(err => {
    return false
  })
}

export const apiPost = async (pth, body) => {
  return apiFetch(pth, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(res => res.json())
}

export const apiCheckout = body => {
  return apiPost(`/payment/checkout`, body)
}

export const LEGACY_CAMERA_MOVEMENTS = {
}
export const CAMERA_MOVEMENTS = {
  'Slowly Right': {
    translation_x: 0,
    translation_z: 0,
    rotation_3d_x: 0,
    rotation_3d_z: 0,
    translation_y: 0.3,
    rotation_3d_y: 0.3
  },
  'Slowly Left': {
    translation_x: 0,
    translation_z: 0,
    rotation_3d_x: 0,
    rotation_3d_z: 0,
    translation_y: -0.3,
    rotation_3d_y: -0.3
  },
  'Still': {
    translation_y: 0,
    translation_x: 0,
    translation_z: 0,
    rotation_3d_y: 0,
    rotation_3d_x: 0,
    rotation_3d_z: 0
  },
  'Zoom': {
    translation_y: 0,
    translation_x: 0,
    translation_z: 0,
    rotation_3d_y: 0,
    rotation_3d_x: 0,
    translation_z: 0.3
  },
  'Zoom Fast': {
    translation_y: 0,
    translation_x: 0,
    rotation_3d_y: 0,
    rotation_3d_x: 0,
    rotation_3d_z: 0,
    translation_z: 1
  },
  'Swirl Around': {
    translation_x: 0,
    translation_z: 0,
    rotation_3d_y: 0.5,
    rotation_3d_x: 0.5,
    translation_z: 0.5,
    translation_y: 0.5
  },
  'Random': {
    translation_y: Math.random() * 2 - 1,
    translation_x: Math.random() * 2 - 1,
    translation_z: Math.random() * 2 - 1,
    rotation_3d_y: Math.random() * 2 - 1,
    rotation_3d_x: Math.random() * 2 - 1,
    rotation_3d_z: Math.random() * 2 - 1,
  },
  'Panning Up': {
    translation_x: 0,
    rotation_3d_y: 0,
    rotation_3d_z: 0,
    rotation_3d_x: 0.5,
    translation_z: -1,
    translation_y: 1
  },
  'Down': {
    translation_y: 0,
    translation_x: -0.5,
    translation_z: -1,
    rotation_3d_y: 0,
    rotation_3d_x: 0,
    rotation_3d_z: 0
  },
}

export function abbreviateNumber(number) {
  const suffixes = ["", "k", "m", "b", "t"]; // Suffixes for thousands, millions, billions, trillions, etc.

  // If the number is less than 1000, no abbreviation needed
  if (number < 1000) {
      return number.toString();
  }

  const suffixNum = Math.floor(("" + number).length / 3); // Determine the appropriate suffix
  const shortValue = parseFloat((suffixNum !== 0 ? (number / Math.pow(1000, suffixNum)) : number).toPrecision(2));
  
  return shortValue + suffixes[suffixNum];
}

export function addCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function logout (dispatch) {
  localStorage.removeItem('aiaiaiaiai-jwt')
  dispatch({ type: 'user', payload: false })
  dispatch({ type: 'setThing', payload: { key: 'menuOpen', value: false } })
  window.location.href = '/'
}

export function imgUrlForFrame (videoId, frame) {
  if (typeof videoId === 'object') videoId = videoId._id
  return `https://aiaiaiaiai.biz/images/${videoId}/${frame.toString().padStart(5, '0')}.png`
}
export function isValidObjectId(str) {
    // Check if the string is a 24-character hexadecimal string
    const isValidHex = /^[0-9a-fA-F]{24}$/.test(str);
    return isValidHex;
}
export const sectionsFromTexts = txts => {
  const sections = []
  let currentSubtask = 0
  txts.forEach((t, i) => {
    if (t.clipId) {
      sections.push(t.clipId)
      currentSubtask++
    } else if (last(sections) !== currentSubtask) {
      sections.push(currentSubtask)
    }
  })
  console.log('sections', sections)

}

export const getConfig = (theTexts, audio, img) => {
  const config = { 
    audio: audio.url, 
    camera_config: {}, 
    audioDuration: audio.duration, 
    voice: audio.voice,
    texts: [] 
  }
  if (typeof config.audio !== 'string' || /^blob:/.test(config.audio) || !config.audio) {
    delete config.audio
    config.silence = true
  } 
  if (img) config.camera_config.image = img 
  config.prompts = {}
  config.neg_prompts = {}
  const getStrength = (t) => {
    let s = parseFloat(t.strength)
    if (isNaN(s)) s = 0.65
    return s
  }
  theTexts.forEach((t, i) => {
    if (t.camera_movement) {
      const movements = CAMERA_MOVEMENTS[t.camera_movement]
      t.camera_movement_config = movements
      Object.keys(movements).forEach(k => {
        if (!config.camera_config[k]) {
          if (i > 0) {
            config.camera_config[k] = '0:(0)'
          } else {
            config.camera_config[k] = ''
          }
        } 
        config.camera_config[k] += `${i === 0 ? '' : ','}${t.start}:(${movements[k]})` 
      })
    }

    config.camera_config.strength_schedule = config.camera_config.strength_schedule || ''
    if (i > 0) {
      config.camera_config.strength_schedule += `,${t.start - 1}:(${getStrength(theTexts[i - 1])}),`
    }
    config.camera_config.strength_schedule += `${t.start}:(${getStrength(t)})` 

    // const style = videoStyle && STYLES[videoStyle]
    if (t.prompt) {
      config.prompts[t.start] = t.prompt
      if (t.style) {
        config.prompts[t.start] += `, ${t.style}`
      }
    }
    // if (style && style.prompt) {
    //   config.prompts[t.start] = config.prompts[t.start] || ''
    //   config.prompts[t.start] += ' ' + style.prompt
    // }
    if (t.neg_prompt) {
      config.neg_prompts[t.start] = t.neg_prompt
    }
    // if (style && style.neg_prompt) {
    //   config.neg_prompts[t.start] = config.neg_prompts[t.start] || ''
    //   config.neg_prompts[t.start] += ' ' + style.neg_prompt
    // }
    config.texts.push(t)
  })
  return config
}
export function areHierarchiesSame(hierarchy1, hierarchy2, depth) {
  // Base case for recursion
  if (depth === 0) {
    return true;
  }

  // Check if the number of children is the same
  if ((hierarchy1.children || []).length !== (hierarchy2.children || []).length) {
    return false;
  }

  // Compare each child node recursively
  for (let i = 0; i < (hierarchy1.children || []).length; i++) {
    if (!areHierarchiesSame(hierarchy1.children[i], hierarchy2.children[i], depth - 1)) {
      return false;
    }
  }

  // Compare the IDs of the current nodes
  return hierarchy1.id === hierarchy2.id;
}

export const getDnaValue = (story, key) => {
  const obj = path(['dna', key], story)
  if (!obj) return null
  const getRandomProperty = () => {
    const total = Object.values(obj).reduce((sum, value) => sum + value, 0);
    let random = Math.random() * total;
    for (const [key, value] of Object.entries(obj)) {
      random -= value;
      if (random <= 0) {
        return key;
      }
    }
  }
  return getRandomProperty();
}
export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}