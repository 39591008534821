import { Range, getTrackBackground } from 'react-range'

export default function BerfRange({ step = 1, min = 0, max, onChange, onDrag, values, colors }) {
  return (
    <Range
      values={values}
      step={step}
      min={min}
      max={max}
      onChange={(vs) => {
        onChange && onChange({ values: vs })
      }}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={(evt) => {
            onDrag && onDrag(true)
          }}
          onMouseUp={(evt) => {
            onDrag && onDrag(false)
          }}
          onTouchStart={evt => {
            props.onTouchStart(evt)
            onDrag && onDrag(true)
          }}
          onTouchEnd={evt => {
            props.onTouchEnd && props.onTouchEnd(evt)
            onDrag && onDrag(false)
          }}
          style={{
            ...props.style,
            height: '36px',
            display: 'flex',
            width: '100%'
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '2px',
              width: '100%',
              borderRadius: '4px',
              background: getTrackBackground({
                values,
                colors: ['#CFFC52', '#CFFC52', '#CFFC52'],
                min,
                max,
              }),
              alignSelf: 'center'
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => {
        const c = colors ? colors[props.key] : '#CFFC52'
        return (
          <div
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '42px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
            <div
              style={{
                height: '32px',
                width: '1px',
                boxShadow: isDragged ? '0px 6px 10px 0px rgba(207, 252, 82, 0.40), 0px -6px 10px 0px rgba(207, 252, 82, 0.40)' : '0px 2px 6px #AAA',
                backgroundColor: isDragged ? '#fff' : c
              }}
            />
          </div>
        )}
      }
    />

  )
}