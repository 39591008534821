import classNames from "classnames";
import YoutubeForm from "./YoutubeForm";
import { useState, useContext } from 'react'
import Narration from "./Narration";
import Checkout from "./Checkout";
import { omit, pick } from "ramda";
import VideoPlayer from "./VideoPlayer";
import { btnStyles, linkStyles, selectClass } from "./utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import AudioVideo from "./AudioVideo";
import GenerateVideo from "./GenerateVideo";
import AnimatedButton from "./common/AnimatedButton";
import { Context } from "./context/Context";
import { useSetThing } from "./context/hooks";
import ModeSelect from "./common/ModeSelect";

export default function HomePage() { 
  const [tab, setTab] = useState('')
  const tabs = ['youtube', 'narration']
  const [showBraintree, setShowBraintree] = useState(false)
  const [config, setConfig] = useState({})
  const { state, dispatch } = useContext(Context);
  const setThing = useSetThing()
  const location = useLocation()
  const navigate = useNavigate()
  const video = useRef(null)

  const { user, mode } = state

  const onCheckout = (config) => {
    config.camera_config = {
      translation_y: '0:(0.3)',
      rotation_3d_y: '0:(0.3)',
      voice: config.voice || 'Bella',
      ...config.camera_config
    }
    setConfig(config)
    setShowBraintree(true)
  }

  let show = null
  let hp = false 
  let showAdvanced = false
  if (showBraintree) {
    show = <Checkout onCancel={() => setShowBraintree(false)} config={config} />
  } else if (/\/weird/.test(location.pathname)) {
    show = <GenerateVideo onCheckout={onCheckout} config={pick(['generate'], config)} />
  } else if (/\/audio/.test(location.pathname)) {
    showAdvanced = true
    show = <AudioVideo onCheckout={onCheckout} config={pick(['narration', 'topic'], config)} />
  } else if (/\/narration/.test(location.pathname)) {
    showAdvanced = true
    show = <Narration onCheckout={onCheckout} config={pick(['narration', 'topic'], config)} />
  } else if (/\/youtube/.test(location.pathname)) {
    show = <YoutubeForm onCheckout={onCheckout} config={omit(['narration'], config)} />  
  } else {
    hp = true
  }
  return (
    <div className={classNames('homepage')}>
      {hp && (
        <>
          <div className="block cave-wrap">
            <a className='intro' onClick={() => {
              console.log('video', video)
              if (video.current) {
                if (video.current.paused) {
                  video.current.play()
                } else {
                  video.current.pause()
                }
              } 
            }} />
            <VideoPlayer 
              alwaysVisible 
              url='https://aiaiaiaiai.biz/welcome.mp4' 
              poster={`https://aiaiaiaiai.biz/hp-poster.png`}
              controls={false} 
              onRef={v => video.current = v } 
            />
            <Link to='/weird' className={classNames('btn weird')}>Make a Weird Video</Link>
            {/* <Link to='/narration' className={classNames('btn')}>Not my first rodeo</Link> */}
            <div className='cave' />
            {/* <AnimatedButton
              className='rabbit'
              frames={2}
              onClick={() => {
                navigate('/videos')
              }}
            /> */}
          </div>
            <p className="text-center">
              {/* 👇🐇🕳️<br/> */}
              {/* <Link to='/narration' className={classNames('mr-5')}>make a text based video</Link><br/>
              <Link to='/audio' className={classNames('mr-5')}>make an audio based video</Link><br/> */}
              {/* <Link to='/videos' className={classNames('mr-5')}>watch videos</Link> */}
            </p>
        </>
      )}
      {showAdvanced && <ModeSelect />}
      {show}
    </div>
  )
}