import { useCallback, useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { apiJson, imgUrlForFrame } from "./utils"
import VideoTikTok from "./tiktok/VideoTikTok"
import { VscDebugContinue } from "react-icons/vsc";
import { GoGitCommit, GoRepoForked } from 'react-icons/go';
import useStore, { useFetchStories, useCache, useSetThing } from "./context/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { FiPlusCircle } from "react-icons/fi";
import { PiTelevisionSimpleDuotone } from "react-icons/pi";
import { FaRegSave, FaBook } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";


import PathViewer from "./tiktok/PathViewer"
import * as d3 from "d3";


import "./Narratives.scss";
import MenuButton from "./MenuButton";
import Toggle from "./common/Toggle";
import NodeTikTok from "./tiktok/NodeTikTok";
import { not, set, uniq, last } from "ramda";
import Modal from "./common/Modal";
import TripViewer from "./TripViewer";
import Pop from "./common/Pop";
import { IoIosShareAlt } from "react-icons/io";
import MyStuff from "./MyStuff";
import { RiEyeCloseLine } from "react-icons/ri";
import { HiOutlineEye } from "react-icons/hi2";
import ArrowKeys from "./common/ArrowKeys";




const nodeForStory = (id, parent) => {
  return {
    type: 'story',
    id,
    parent
  }
}
function ensureDirectIdAccess(node) {
  node.id = node.data.id;
  if (node.children) {
    node.children.forEach(ensureDirectIdAccess);
  }
}
// const START_STORY = '6624744ca068e96bc01025c2'
const START_STORY = '66c8d9b0558fe858d0ef9062'



export default function Narratives ({}) {
  const { id } = useParams()
  const { fetchStories, fetchStory, storiesCache, videosCache, nodesCache, preferred, nodeTextForStory } = useFetchStories()
  const navigate = useNavigate()
  const [mode, setMode] = useState('videos')
  const [pathViewerHover, setPathViewerHover] = useState(false)
  const [centerMenuOpen, setCenterMenuOpen] = useState(false)
  const [displayText, setDisplayText] = useState(true)
  const [trip, setTrip] = useState([])
  const [tripViewer, setTripViewer] = useState(false)
  const [preChangeStoryId, setPreChangeStoryId] = useState(null)
  const [startStory, setStartStory] = useState(id || START_STORY)
  const storyIdRef = useRef(startStory)
  const tikTokRef = useRef(null)
  const [storyId, _setStoryId] = useState(startStory)
  const setThing = useSetThing()
  const setPreferred = newPreferred => {
    setThing('preferred', uniq(newPreferred))
  }
  const cache = useCache()
  const setStoryId = newStoryId => {
    storyIdRef.current = newStoryId
    setPreChangeStoryId(newStoryId)
    _setStoryId(newStoryId)
    console.log('set story id', newStoryId)
  }

  useEffect(() => {
    fetchStory(storyId, true)
  }, [storyId])
  useEffect(() => {
    if (trip.length === 0) return
    setStartStory(trip[0])
  }, [trip])
  const story = storiesCache[storyId]
  const pathOnClick = useCallback(_storyId => {
    if (storyIdRef.current === _storyId) {
      setCenterMenuOpen(!centerMenuOpen)
    } else if (trip.includes(_storyId)) {
      setTripViewer(true)
    }
  }, [storyId, trip, centerMenuOpen])
  if (!story) return null
  const immediateStoryId = (preChangeStoryId && storyId !== preChangeStoryId) ? preChangeStoryId : storyId
  const immediateStory = storiesCache[immediateStoryId]
  let data = []
  const stories = Object.values(storiesCache)
  let hierarchy, cleanHierarchy
  data = stories 
    .map(s => {
      if (/-/.test(s.stories)) {
        // second from end
        let parent = s.parentId || s.stories.split('-').slice(-2, -1)[0]
        return nodeForStory(s._id, parent)
      } else {
        return nodeForStory(s._id)
      }
    })
  // remove parents that don't exist
  const removeNonexistentParents = dta => {
    return dta.map(d => {
      if (!dta.find(d2 => d2.id === d.parent) || d.id === startStory) {
        delete d.parent
      }
      return d
    })
    .filter(d => {
      if (!d.parent && d.id !== startStory) return false
      return d.parent || d.id === startStory 
    })
  }
  let dataLength
  do {
    dataLength = data.length
    data = removeNonexistentParents(data)
  } while (dataLength !== data.length)
  const createHierarchy = (data) => {
    return d3.stratify()
    .id(node => node.id)
    .parentId(node => node.parent)(data)
    .sort((a, b) => { // if they have children put on top
      if (a.children && !b.children) {
        return -1
      }
      if (!a.children && b.children) {
        return 1
      }
      if (a.children && b.children) {
        return b.children.length - a.children.length
      }
      return 0
    })
  }
  cleanHierarchy = createHierarchy(data)
  hierarchy = createHierarchy(data)
  const current = hierarchy.find(v => v.id === storyId)
  if (current) {
    if (current.parent) {
      const removeUnchosen = node => {
        if (node.parent) {
          node.parent.children = [node]
          return removeUnchosen(node.parent)
        } else {
          return node
        }
      }
      removeUnchosen(current.parent)
    }
  }
  window.hierarchy = hierarchy
  const tiktokOnChange = newStoryId => {
    console.log('tiktok on change', newStoryId)
    // navigate(`/n/${newStoryId}`, { replace: true })
    const current = cleanHierarchy.find(v => v.data.id === storyId)
    const newStory = cleanHierarchy.find(n => n.data.id === newStoryId)
    const lastStory = trip[trip.length - 1]
    if (!newStory) return
    if (current.depth < newStory.depth) {
      if (last(trip) !== storyId) {
        setTrip([...trip, storyId]) 
      }
    } else if (current.depth > newStory.depth) {
      setTrip(trip.slice(0, -1))
    // sibling
    } else {
      let newPref = [...preferred]
      if (preferred.includes(storyId)) {
        newPref = preferred.filter(d => d !== storyId)
      }
      newPref.push(newStoryId)
      setPreferred(newPref)
    }
    setPreChangeStoryId(newStoryId)
    setTimeout(() => {
      setStoryId(newStoryId)
    }, 500)
  }
  let tiktok = (
    <VideoTikTok 
      ref={tikTokRef}
      hierarchy={hierarchy}
      trip={trip}
      selected={storyId}
      onStartChange={tiktokOnChange}
      onChange={setStoryId}
    />
  )
  if (mode === 'nodes') {
    tiktok = (
      <NodeTikTok
        trip={trip}
        hierarchy={hierarchy}
        selected={storyId}
        onStartChange={tiktokOnChange}
        onChange={setStoryId}
      />
    )
  }
  return (
    <div className={classNames('narratives mx-auto max-w-lg w-full relative', { 'center-menu': centerMenuOpen })}>
      <div className='tiktok-wrap relative'>
        <div onClick={() => setDisplayText(not)} className={classNames('meta-text cursor-pointer sm p-4 w-full absolute left-0 z-10', { 'justify-end': !displayText })}>
          {displayText ? nodeTextForStory(immediateStoryId) : <HiOutlineEye />}
        </div>
        {tiktok}
      </div>
      {centerMenuOpen && (
        <div className="center-menu flex justify-center h-0 relative top-3">
          <Pop side='right'>
            <div className="center-menu-content">
              <a
                className="contribute"
                onClick={() => {
                  const video = videosCache[story.videoId] 
                  setCenterMenuOpen(false)
                  setThing('modal', {
                    type: 'quick-continue',
                    props: {
                      startStory,
                      storyId,
                      image: imgUrlForFrame(video, video.images.length - 6)
                    }
                  })
                }}
              >
                <FaCirclePlus />
              </a>
            </div>
          </Pop>
        </div>
      )}
      <div 
        onTouchStart={() => setPathViewerHover(true)}
        onTouchEnd={() => setPathViewerHover(false)}
        className="px-2 path-viewer-wrap">
        <PathViewer 
          hierarchy={cleanHierarchy}
          selected={immediateStoryId}
          trip={trip}
          onClick={pathOnClick}
        />
        <ArrowKeys
          onClick={direction => {
            if (tikTokRef.current) {
              tikTokRef.current.handleNavigation(direction);
            }
          }}
        />
      </div>
      {/* <MenuButton /> */}
      <MyStuff 
        onSelect={s => {
          const stories = s.stories.split('-')
          fetchStories(stories)
            .then(() => {
              setTrip(stories.slice(0, -1))
              setStartStory(stories[0])
              setStoryId(last(stories))
              setPreferred(stories)
            })
        }}
      />
      {tripViewer && (
        <Modal onClose={() => setTripViewer(false)}>
          <TripViewer
            setTrip={setTrip}
            trip={trip}
            storyId={storyId}
            setStoryId={sId => {
              setStoryId(sId)
              setPreferred(trip)
              setTrip([])
            }}
          />
        </Modal>
      )}
    </div>
  )
}