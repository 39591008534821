import { useFetchStories } from '../context/hooks'
import classNames from 'classnames'
import { LiaPoopSolid } from "react-icons/lia";


export default function StoryCard ({ storyId, onClick, children, className }) {
  const { storiesCache, nodesCache, videosCache } = useFetchStories()
  const story = storiesCache[storyId] 
  if (!story) return null
  const video = videosCache[story.videoId] 
  if (!video) return null
  const node = nodesCache[story.nodeId] 
  const screenshot = 'https://aiaiaiaiai.biz/' + (video.image || (Array.isArray(video.images) && video.images.find(i => /screenshot/.test(i))))
  const failed = video.failed
  const showClick = !failed && onClick
  const img = failed ? <LiaPoopSolid /> : <img src={screenshot} className='w-12 h-12 rounded-sm' />
  return (
    <div className={classNames('story-card flex items-center mb-4', className, { failed })}>
      {showClick ? (
        <a onClick={() => onClick(story)} className='shrink-0'>
          {img}
        </a>
      ) : img}
      <p className='ml-4'>{(node && node.text) || video.summary} {failed && <em className='text-xs'>(failed, sorry)</em>}</p>
      {children}
    </div>
  )
}