import React, { useState, useEffect, useRef } from "react";
import { FPS, apiPost, imgUrlForFrame, inputsCommon } from "./utils";
import classNames from "classnames";
import { useStore, useSetThing } from "./context/hooks";
import VideoPlayer from "./VideoPlayer";
import "./ClipMaker.scss"

import { Range, getTrackBackground } from 'react-range'
import { set } from "ramda";

export default function ClipMaker ({ video, onChange, muted }) {
  const STEP = 1;
  const MIN = 0;
  const MAX = video.images;
  const [values, _setValues] = React.useState([MIN, MAX]);
  const valuesRef = useRef(values)
  const [seek, setSeek] = useState(0)
  const [dragging, setDragging] = useState(false)
  const [posterFrame, setPosterFrame] = useState(0)
  const videoRef = useRef(null)
  const setValues = (vs) => {
    valuesRef.current = vs
    _setValues(vs)
  }
  return (
    <div className={classNames('clip-maker', { dragging })}>
    <img src={imgUrlForFrame(video, posterFrame)} className='poster' />
    <VideoPlayer 
      onRef={v => {
        videoRef.current = v
        v.addEventListener('timeupdate', evt => {
          const values = valuesRef.current
          if (videoRef.current.currentTime > values[1] / 10
            || videoRef.current.currentTime < values[0] / 10
          ) {
            videoRef.current.currentTime = values[0] / 10
          }
        })
      }} 
      video={video} 
      muted={muted}
      controls={false}
    />
    {values && (
      <>
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(vs) => {
            const whichIsDifferent = values.findIndex((v, i) => v !== vs[i])
            setValues(vs);
            onChange && onChange({ values: vs })
            let currentTime = Math.floor(vs[whichIsDifferent] / FPS)
            if (isNaN(currentTime)) {
              currentTime = 0
              setPosterFrame(0)
            }  else {
              setPosterFrame(vs[whichIsDifferent])
            }
            videoRef.current.currentTime = currentTime 
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={(evt) => {
                setDragging(true)
              }}
              onMouseUp={(evt) => {
                setDragging(false)

              }}
              onTouchStart={evt => {
                props.onTouchStart(evt)
                setDragging(true)
              }}
              onTouchEnd={evt => {
                props.onTouchEnd(evt)
                setDragging(false)
              }}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values,
                    colors: ['#ccc', '#fb0dc8', '#ccc'],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '42px',
                width: '42px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
              <div
                style={{
                  height: '42px',
                  width: '2px',
                  boxShadow: '0px 2px 6px #AAA',
                  backgroundColor: isDragged ? '#fff' : '#fb0dc8'
                }}
              />
            </div>
          )}
        />
        <output style={{ marginTop: '30px' }} id="output">
         frames: {values[0]} - {values[1]}
         {dragging && 'dragging'}
        </output>
      </>
    )}

    </div>
  )

}