import { CAMERA_MOVEMENTS, isPause, textInputClass, estimateSpeechDuration, blankNarrationPhrase, inputsCommon, selectClass, getPause, apiPost, apiFetch, apiJson } from './utils'
import { not, set } from 'ramda'
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import VideoClip from './VideoClip'


export default function NarrationPhrase ({ setTexts, i = 0, texts, onAbove, onBelow }){
  const t = texts[i]
  const tokenCount = Math.ceil((t.prompt.split(" ").length - 1) * 0.75)
  const [advanced, setAdvanced] = useState(false)
  const [weird, setWeird] = useState(true)
  const [silence, setSilence] = useState(isPause(t))
  const [loading, setLoading] = useState(false)
  const [clipSearch, setClipSearch] = useState()
  const [clipSearching, setClipSearching] = useState(false)
  // const [strength, setStrength] = useState()
  const [prevNarration, setPrevNarration] = useState('')
  const normalNegPrompt = '(deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime), text, cropped, out of frame, worst quality, low quality, jpeg artifacts, ugly, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, dehydrated, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, BadDream, UnrealisticDream'
  const hasPrompt = t.prompt && t.prompt.length > 0
  const speeds = [
    { value: 0.9, label: 'gradual' },
    { value: 0.8, label: 'trails' },
    { value: 0.65, label: 'just right' },
    { value: 0.45, label: 'schizo' },
    { value: 0.3, label: 'seizure' },
  ]
  const searchClips = async () => {
    if (t.prompt.length < 3) return
    setClipSearching(true)
    const res = await apiJson(`/search/clips?s=${t.prompt}`)
    console.log('res', res)
    setClipSearch(res)
    setClipSearching(false)
  }
  const promptLine = (
    <span className='prompt-line'>
      <label className='mt-4 block'>
        <span className='hide-advanced inline'>Image Prompt</span>
        <span className='flex justify-between'>
          <span className='show-advanced'>pictures</span>
          {t.prompt && (
            <div className='search-clips'>
              <a
                className='mr-3'
                onClick={searchClips}
              >{clipSearching ? '🏃‍♀️' : '🔎📺'}</a>
              {/* <a onClick={() => {
                setLoading(true)
                apiFetch(`/user/prompt?prompt=${t.prompt}`)
                .then(res => res.json())
                .then(res => {
                  console.log('res', res)
                  if (res.result) {
                    setTexts(texts.map((t, j) => {
                      if (j !== i) return t
                      return { ...t, prompt: res.result }
                    }))
                  } else {

                  }
                  setLoading(false)
                })
                .catch(err => {
                  console.log(err)
                  setLoading(false)
                })
              }} className='user-only float-right'>{loading ? '🏃‍♀️' : '🤖'}</a> */}
            </div>
          )}
        </span>
      </label>
      <textarea 
        className={classNames(textInputClass, 'h-16')} 
        value={t.prompt} 
        placeholder='describe the scene...'
        onChange={evt => {
          setTexts(texts.map((t, j) => {
            if (j !== i) return t
            return { ...t, prompt: evt.target.value }
          }))
        }} 
      />
    </span>
  )
  let show = (
    <>
      {promptLine}
      <p className='flex justify-between text-sm mb-4 mt-1'>
        <div className='flex justify-between w-full md:flex-row md:items-center flex-col items-start camera-controls mb-2 '>
          <div>
            <label className='text-xs block mb-1 mt-2'>movement</label>
            <select
              className={classNames(selectClass, 'camera-movement mt-2 md:mt-0')} 
              onChange={evt => {
                setTexts(texts.map((t, j) => {
                  if (j !== i) return t
                  return { ...t, camera_movement: evt.target.value }
                }))
              }}
              value={t.camera_movement}
            >
              <option value=''>{i === 0 ? '' : '(no change)'}</option>
              {Object.keys(CAMERA_MOVEMENTS).map(v => {
                return (
                  <option key={v} value={v}>{v}</option>
                )
              })}
            </select>
          </div>
          <div className='text-right'>
            <label className='text-xs block mb-1 mt-2'>speed</label>
            <select
              className={classNames(selectClass, 'speeds mt-2 md:mt-0')} 
              onChange={evt => {
                setTexts(texts.map((t, j) => {
                  if (j !== i) return t
                  return { ...t, strength: evt.target.value }
                }))
              }}
              value={t.strength}
            >
              {speeds.map(v => {
                return (
                  <option key={v.value} value={v.value}>{v.label}</option>
                )
              })}
            </select>
          </div>
          {/* <div className='speed-box text-right mt-2 md:m-0'>
            <div className='flex justify-between w-full items-center'>
              ️<label>speed</label> 
              {speeds.map((speed, z) => {
                return (
                  <a 
                    key={'speed-' + z} 
                    onClick={() => {
                      setTexts(texts.map((t, j) => {
                        if (j !== i) return t
                        return { ...t, strength: speed.value }
                      }))
                    }} 
                    className={classNames('cursor-pointer border border-gray-400 rounded-md p-1 mr-1', {
                      active: t.strength ? parseFloat(t.strength) === speed.value : speed.value === 0.65
                    })}
                  >{speed.label}</a> 
                )
              })}
            </div>
          </div> */}
        </div>
        <span className='text-red-500'>
          {tokenCount < 70 ? `` : `Too many tokens (${tokenCount})`}
        </span>
      </p>
      <div className='show-advanced neg-prompt'>
        <label>style</label>
        <input 
          type='text' 
          className={textInputClass} 
          value={t.style} 
          placeholder="style, not substance"
          onChange={evt => {
            setTexts(texts.map((t, j) => {
              if (j !== i) return t
              return { ...t, style: evt.target.value }
            }))
          }} 
        />
      </div>
      <div className='show-advanced neg-prompt'>
        <label>exclude</label>
        <input 
          type='text' 
          className={textInputClass} 
          value={t.neg_prompt} 
          placeholder="what you don't want to see in there..."
          onChange={evt => {
            setTexts(texts.map((t, j) => {
              if (j !== i) return t
              return { ...t, neg_prompt: evt.target.value }
            }))
          }} 
        />
      </div>
      {(hasPrompt || i > 0) && (
        <div className='narration-info'>
          <p className='mt-9 mb-3 flex justify-between'>
            <label className='mr-5'>
              <input
                className='mr-1 show-advanced inline-block'
                type='radio'
                value='pause'
                checked={!silence}
                onChange={() => {
                  setSilence(false)
                  setTexts(texts.map((t, j) => {
                    if (j !== i) return t
                    return { ...t, narration: prevNarration }
                  }))
                }}
              />
              <span className='hide-advanced inline'>Narration</span><span className='show-advanced inline'>words</span>
            </label>
            <span className='show-advanced inline'>or</span>
            <label className='mr-5 show-advanced inline'>
              <input
                className='mr-1'
                type='radio'
                value='pause'
                checked={silence}
                onChange={() => {
                  setPrevNarration(t.narration)
                  setSilence(true)
                  setTexts(texts.map((t, j) => {
                    if (j !== i) return t
                    return { ...t, narration: 'pause-1' }
                  }))
                }}
              />
              silence 
            </label>
          </p>
          {silence
            ? <div className='flex justify-between items-center'> 
              <label className='w-2/3'>How long (secs)?</label>
              <input 
                type='number' 
                pattern="\d*"
                className={textInputClass.replace('w-full', '') + ` w-1/3 grow-0`} 
                placeholder=''
                value={t.narration.replace(/pause-/, '')} 
                onChange={evt => {
                  setTexts(texts.map((t, j) => {
                    if (j !== i) return t
                    return { ...t, narration: 'pause-' + (evt.target.value).replace(/[^0-9]/g, '') }
                  }))
                }} 
              />
            </div>
            : <>
                <textarea 
                  className={classNames(textInputClass, 'h-20')} 
                  placeholder='what the narrator will say...'
                  value={t.narration} 
                  onChange={evt => {
                    setTexts(texts.map((t, j) => {
                      if (j !== i) return t
                      return { ...t, narration: evt.target.value }
                    }))
                  }} 
                />
                <p className='text-sm text-gray-300 mb-2 mt-1'>
                  ~{Math.floor(estimateSpeechDuration(t.narration))}s
                </p>
            </>}
        </div>
      )}
      {isPause(t) && (
        <p className='pause-amount text-right text-sm text-gray-300'>{getPause(t)}s</p>
      )}
    </>
  )
  if (clipSearch) {
    show = (
      <div className='clip-search w-full overflow-x-auto'>
        <span className='flex justify-between'>
          Existing Clips
          {clipSearching ? ' 🏃‍♀️' : ''}
          <a onClick={() => setClipSearch(null)}>❌</a>
        </span>
        <input 
          type='text' 
          className={textInputClass} 
          value={t.prompt} 
          placeholder="search for clips..."
          onKeyDown={evt => {
            // submit on enter key
            if (evt.keyCode === 13) {
              searchClips()
            }
          }}
          onChange={evt => {
            setTexts(texts.map((t, j) => {
              if (j !== i) return t
              return { ...t, prompt: evt.target.value }
            }))
          }} 
        />
        <div className='flex mt-5'>
          {clipSearch.clipIds.map(clipId => {
            const video = clipSearch.videos.find(v => v._id === clipId.split('-')[0])
            return (
              <div
                className='w-40 mr-5'
              >
                <VideoClip 
                  key={clipId} 
                  clipId={clipId} 
                  video={video} 
                />
                <a
                 onClick={() => {
                  setTexts(texts.map((t, j) => {
                    if (j !== i) return t
                    t.clipId = clipId
                    t.video = video
                    return t
                  }))
                  setClipSearch(null)
                 }}
                 >choose</a>
              </div>
            )
          })}
        </div>
      </div>
    )
  } 
  if (t.clipId) {
    show = (
      <div>
        {promptLine}
        <p className='text-right'>
          <a onClick={() => {
            setTexts(texts.map((t, j) => {
              if (j !== i) return t
              t.clipId = null
              t.video = null
              return t
            }))
          }}>❌</a>
        </p>
        <VideoClip clipId={t.clipId} video={t.video} />
      </div>
    )
  }
  return (
    <div className='mb-16 text mt-5 p-5 border rounded-md border-violet-500 relative pt-2 pb-8 narration-phrase' style={{ borderColor: t.color ? t.color.replace('0.5)', '1)') : '#fff' }}>
      {show}
      <a onClick={() => {
        if (onAbove) onAbove()
        }} className='hover:bg-pink-500 border-violet-500 border text-lg pl-2 cursor-pointer absolute -top-4 block h-8 pr-3 rounded-full bg-black top-plus'>+ 👆🏻 </a>
      <a onClick={() => {
        if (onBelow) onBelow()
      }} className='hover:bg-pink-500 border border-violet-500 text-lg pl-2  cursor-pointer absolute -bottom-4 block pr-3 h-8 p-d rounded-full bg-black bottom-plus'>+ 👇</a>
      <a onClick={() => {
        if (texts.length === 1) {
          setTexts([blankNarrationPhrase(), ...texts.slice(i + 1)])
          return
        }
        setTexts(texts.filter((_, j) => j !== i))
      }} className='hover:bg-pink-500 border cursor-pointer text-lg pl-2  border-violet-500 absolute -right-4 -top-4 block pr-2 h-8 p-d rounded-full bg-black delete'>🗑️</a>
    </div>
  )
}