import React, { useState, useRef, useEffect } from 'react'
import { last, path, range, set } from 'ramda'
import { textInputClass, inputsCommon, btnStyles, estimateSpeechDuration, blankNarrationPhrase, linkStyles, getVideo, h1Styles, FPS, CAMERA_MOVEMENTS, apiPost, imgUrlForFrame, getClipVideoId, getClipEnd } from './utils'
import classNames from 'classnames'
import NarrationPhrase from './NarrationPhrase'
import './Narration.scss'
import { useLocation } from 'react-router-dom'
import { WaveSurferPlayer } from './WaveSurfer'
import { LuTextCursor } from "react-icons/lu";
import { MdForest } from "react-icons/md";
import { BsChatText } from "react-icons/bs";
import Price from './common/Price'
import useStore, { useSetThing } from './context/hooks'
import ImageChooser from './ImageChooser'
import AudioChooser from './AudioChooser'
import ffmpeg from './utils/ffmpeg'
import BerfRange from './BerfRange'
import { FaArrowRotateRight } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import Sticky from 'react-sticky-el';

const STYLES = {
  basquiat: {
    label: 'Basquiat',
    prompt: 'in the style of basquiat',
    neg_prompt: 'bodies, human figures, man, boys, people, full bodies, faces, portrait, eyes, nose, mouth'
  }
}


export default function AudioVideo({ onCheckout, config }) {
  const [texts, setTexts] = useState(path(['narration'], config) || [blankNarrationPhrase()])
  const [videoType, setVideoType] = useState('')
  const [ silenceLength, setSilenceLength ] = useState(10)
  const [videoStyle, setVideoStyle] = useState('')
  const [text, setText] = useState('')
  const [transcript, setTranscript] = useState('')
  const location = useLocation() 
  const q = new URLSearchParams(location.search)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(q.get('image'))
  // const [audio, setAudio] = useState('https://aiaiaiaiai.biz/uploads/7468df8f-b5fd-48a7-82bd-a1b4315295eb.mp3')
  const [audio, setAudio] = useState('')
  const [audioDuration, setAudioDuration] = useState(0)
  const [autoGenerating, setAutoGenerating] = useState(false)
  const search = new URLSearchParams(location.search)
  const id = search.get('v') || search.get('c')
  const isFork = search.get('v')
  const isOk = audioDuration && texts.length > 0 && texts.some(t => t.prompt || t.narration) && texts[0].prompt
  const lastId = useRef(null)
  const { state, dispatch } = useStore() 
  const setThing = useSetThing()
  useEffect(() => {
    if (!id || lastId.current === id) return
    lastId.current = id
    setLoading(true)
    getVideo(id)
      .then(async d => {
        console.log(d)
        if (isFork) {
          if (!d.config.texts) return
          const hasANegPrompt = d.config.texts.some(t => t.neg_prompt)
          if (hasANegPrompt) {
            setThing('mode', 'advanced')
          }
          setTexts(d.config.texts)
          const v = path(['audio'], d.config)
          if (v) setAudio(v)
          const i = path(['camera_config', 'image'], d.config)
          if (i) setImage(i)
          const silence = path(['silence'], d.config)
          if (silence) {
            setVideoType('silence')
            setAudioDuration(d.config.audioDuration)
          }
        // continue
        } else {
          setImage(imgUrlForFrame(d, d.images - 6))
          if (d.config.texts) {
            setTexts(texts.map((t, i) => {
              // there should only be one right now
              const lastText = d.config.texts[d.config.texts.length - 1]
              t.strength = lastText.strength
              const lastCameraMovement = d.config.texts.slice(0).reverse().find(t => t.camera_movement)
              if (lastCameraMovement) {
                t.camera_movement = lastCameraMovement.camera_movement
              }
              return t
            }))
          }
        }
        setLoading(false)
      })
  }, [id, setLoading])
  useEffect(() => {
    texts.forEach((t, i) => {
      if (t.clipId) {
        const next = texts[i + 1]
        const newStart = t.start + parseInt(t.clipId.split('-')[2]) - parseInt(t.clipId.split('-')[1])
        if (!next || next.start !== newStart) {
          if (newStart < audioDuration * FPS ) {
            const newT = blankNarrationPhrase()
            newT.start = newStart
            newT.fixed = true
            setTexts([...texts.slice(0, i + 1), newT, ...texts.slice(i + 1)])
          }
        }
      }
      if (t.fixed) {
        const prev = texts[i - 1]
        if (prev && !prev.clipId){
          const newT = {
            ...t,
            fixed: false
          }
          setTexts([...texts.slice(0, i), newT, ...texts.slice(i + 1)])
        }
      }
    })
    if (texts[0].start !== 0) {
      setTexts([{
        ...texts[0],
        start: 0
      }, ...texts.slice(1)])
    }

  }, [texts, setTexts])
  const typeIcons = {
    text: <LuTextCursor />,
    sound: <BsChatText />,
    silence: <MdForest />
  }
  let topShow = (
    <>
      <div className='w-full mt-5 flex start-with'>
        {/* <a className={classNames(linkStyles, 'mr-5')} onClick={() => setVideoType('text')}>{typeIcons.text} Text</a> */}
        <a className={classNames(linkStyles, 'mr-5')} onClick={() => setVideoType('sound')}>{typeIcons.sound} Sound</a>
        <a className={classNames(linkStyles)} onClick={() => setVideoType('silence')}>{typeIcons.silence} Silence</a>
      </div>
    </>
  )
  if (videoType === 'sound') {
    topShow = <AudioChooser onComplete={obj => {
      setAudio(obj.url)
      setTranscript(obj.transcript)
    }} /> 
  } else if (videoType === 'silence') {
    topShow = (
      <div className=''>
        <div className='flex justify-start items-center'> 
          <label className='w-1/3'>How long (secs)?</label>
          <input 
            type='number' 
            pattern="\d*"
            className={textInputClass.replace('w-full', '') + ` w-1/3 grow-0`} 
            placeholder=''
            value={silenceLength} 
            onChange={evt => {
              setSilenceLength(evt.target.value)
            }} 
          />
          <a className={classNames('m-3 text-2xl')} onClick={async () => {
            setAudioDuration(silenceLength)
          }}>🙉</a>
        </div>
      </div>

    )
  }
  if (audio) {
    topShow = (
      <>
        {/* <p className='text-right'>
          <button onClick={() => setAudio(null)}><MdClose size={20}/></button>
        </p> */}
        <WaveSurferPlayer
          height={100}
          waveColor="rgb(200, 0, 200)"
          progressColor="rgb(100, 0, 100)"
          url={audio}
          markers={texts.map((t, i) => {
            let end = null
            if (t.clipId) {
              const pieces = t.clipId.split('-')
              end = (pieces[2] - pieces[1]) / FPS + t.start / FPS
            }
            console.log('end', end)
            return {
              start: t.start / FPS,
              end,
              color: t.color || '#ffffff',
              content: i,
              _id: t._id,
              fixed: t.fixed
            }
          })}
          onDuration={duration => {
            console.log('duration', duration)
            setAudioDuration(duration)
          }}
          onMarkerDrag={(i, region, ws) => {
            const allRegions = ws.regions.getRegions()
            let dur = 0
            function compareFirstThreeParts(str1, str2) {
                // Split the strings on ","
                const parts1 = str1.split(",");
                const parts2 = str2.split(",");
            
                // Take the first three parts
                const firstThree1 = parts1.slice(0, 3);
                const firstThree2 = parts2.slice(0, 3);
            
                // Compare the first three parts and return the result
                return JSON.stringify(firstThree1) === JSON.stringify(firstThree2);
            }
            setTexts(allRegions.sort((a,b) => a.start - b.start).map((r, j) => {
              // split on , and compare the first three parts

              const textIndex = texts.findIndex(t => compareFirstThreeParts(t.color, r.color))
              const t = texts[textIndex]
              t.start = Math.floor(r.start * FPS)
              return t 
            }))
          }}
        />
      </>
    )
  } else if (audioDuration) {
    topShow = (
      <div>
        <BerfRange
          min={0}
          max={audioDuration * FPS}
          values={texts.map(t => t.start)}
          colors={texts.map(t => t.color)}
          onChange={({ values }) => {
            const vs = texts.map(t => t.start)
            const whichIsDifferent = values.findIndex((v, i) => v !== vs[i])
            if (whichIsDifferent === 0) return
            if (!values[whichIsDifferent]) return
            if (texts[whichIsDifferent].fixed) return
            setTexts(texts.map((t, i) => {
              if (i === whichIsDifferent) {
                return {
                  ...t,
                  start: values[i]
                }
              }
              return t
            }))
          }}
        />
      </div>
    )
  }
  let imageShow = <ImageChooser onComplete={setImage} /> 
  if (image) {
    imageShow = (
      <p className='text-right'>
        <div className='flex justify-between'>
          <span>Starting Image:</span>
          <a className={linkStyles} onClick={() => setImage(null)}>🗑️</a>
        </div>
        <img src={image} />
      </p>
    )
  }
  const addTextBelow = (i) => {
    const t = texts[i]
    const newText = blankNarrationPhrase()
    const next = texts[i + 1]
    if (next) {
      const myDuration = (next.start - t.start) / FPS
      newText.start = Math.floor((t.start / FPS + myDuration / 2) * FPS)
    } else {
      newText.start = Math.floor((t.start / FPS + (audioDuration - t.start / FPS) / 2) * FPS)
    }
    setTexts([...texts.slice(0, i), t, newText, ...texts.slice(i + 1)])
  }
  let narrationShow = (
    <div className='texts text-left'>
      <h1 className={h1Styles}>
        Score the audio
        {transcript && texts.length === 1 && (
          <button 
            disabled={autoGenerating}
            onClick={async () => {
              setAutoGenerating(true)
              const res = await apiPost(`/narration/prompts`, {
                text: transcript
              })
              let start = 0
              const texts = res.map(p => {
                const ret = {
                  ...blankNarrationPhrase(),
                  start,
                  prompt: p.prompt,
                }
                start += Math.floor(estimateSpeechDuration(p.narration) * FPS)
                return ret
              })
              setAutoGenerating(false)
              setTexts(texts)
              console.log('res', res)
            }}
            className={classNames(linkStyles, 'text-lg ml-10')}
          >
            {autoGenerating ? 'computing... 🏃‍♀️' : 'auto generate 🤖'}
            
          </button>
        )}
      </h1>
      {imageShow}
      <div>
        {texts.map((t, i) => <NarrationPhrase 
            key={`phrase-${i}`} 
            i={i} 
            texts={texts} 
            setTexts={setTexts} 
            onAbove={() => {
              addTextBelow(i - 1)
            }}
            onBelow={() => {
              addTextBelow(i)
            }}
          />
        )}
      </div>
      {isOk && (
        <div className='p-5 border rounded-md'>
          <label className='block'>Video Style:</label>
          <select className={classNames('block', inputsCommon)} value={videoStyle} onChange={evt => setVideoStyle(evt.target.value)}>
            <option value=''>no style</option>
            {Object.keys(STYLES).map(k => {
              return (
                <option key={k} value={k}>{STYLES[k].label}</option>
              )
            })}
          </select>
          <button className={classNames(btnStyles, 'mt-5')} onClick={() => {
            const getConfig = (theTexts, audioDuration, audio, img, _id) => {
              const config = { audio, camera_config: {}, audioDuration, texts: [] }
              if (typeof audio !== 'string' || /^blob:/.test(audio) || !audio) {
                delete config.audio
                config.silence = true
              } 
              if (img) config.camera_config.image = img 
              if (_id) config[isFork ? 'from' : 'continues'] = _id
              config.prompts = {}
              config.neg_prompts = {}
              const getStrength = (t) => {
                let s = parseFloat(t.strength)
                if (isNaN(s)) s = 0.65
                return s
              }
              theTexts.forEach((t, i) => {
                if (t.camera_movement) {
                  const movements = CAMERA_MOVEMENTS[t.camera_movement]
                  t.camera_movement_config = movements
                  Object.keys(movements).forEach(k => {
                    if (!config.camera_config[k]) {
                      if (i > 0) {
                        config.camera_config[k] = '0:(0)'
                      } else {
                        config.camera_config[k] = ''
                      }
                    } 
                    config.camera_config[k] += `${i === 0 ? '' : ','}${t.start}:(${movements[k]})` 
                  })
                }
  
                config.camera_config.strength_schedule = config.camera_config.strength_schedule || ''
                if (i > 0) {
                  config.camera_config.strength_schedule += `,${t.start - 1}:(${getStrength(theTexts[i - 1])}),`
                }
                config.camera_config.strength_schedule += `${t.start}:(${getStrength(t)})` 
  
                const style = videoStyle && STYLES[videoStyle]
                if (t.prompt) {
                  config.prompts[t.start] = t.prompt
                }
                if (style && style.prompt) {
                  config.prompts[t.start] = config.prompts[t.start] || ''
                  config.prompts[t.start] += ' ' + style.prompt
                }
                if (t.neg_prompt) {
                  config.neg_prompts[t.start] = t.neg_prompt
                }
                if (style && style.neg_prompt) {
                  config.neg_prompts[t.start] = config.neg_prompts[t.start] || ''
                  config.neg_prompts[t.start] += ' ' + style.neg_prompt
                }
                config.texts.push(t)
              })
              return config
            }
            const config = getConfig(texts, audioDuration, audio, image, id)
            if (texts.find(t => t.clipId)) {
              console.log(texts)
              let generateSections = [[]]
              const totalFrames = audioDuration * FPS
              texts.forEach((t, i) => {
                const currentSection = generateSections[generateSections.length - 1]
                if (t.clipId) {
                  // add an end to last item on previous section
                  if (currentSection && currentSection.length > 0) {
                    currentSection[currentSection.length - 1].end = t.start - 1
                  }
                  generateSections.push([])
                  return
                }

                const lastText = texts[i - 1]
                if (lastText && lastText.clipId) {
                  t.image = imgUrlForFrame(getClipVideoId(lastText.clipId), getClipEnd(lastText.clipId))
                }
                if (currentSection.length === 0 && image && i === 0) {
                  t.image = image
                }
                generateSections[generateSections.length - 1].push(t)
              })
              generateSections = generateSections.filter(s => s.length > 0)
              const configs = generateSections.map((ts, i) => {
                const textsStart = ts[0].start
                const textsEnd = last(ts).end || (audioDuration * FPS)
                const config = getConfig(ts.map(txt => {
                  return {
                    ...txt,
                    start: txt.start - textsStart
                  }
                }), (textsEnd - textsStart) / FPS, null, ts[0].image)
                config.startingFrame = textsStart
                return config
              })
              config.subtasks = configs
            }
            onCheckout(config)
          }}>Generate Video <Price config={{ audioDuration }} /></button>
        </div>
      )} 
    </div>
  )
  if (!audio && !search.get('c') && !audioDuration) {
    narrationShow = null
  }
  if (loading) {
    return <div>please hold...</div>
  }
  return (
    <div className={classNames('narration p-5', { audio: audio || audioDuration })}>
      
        <Sticky 
         // bottomOffset={80}
          topOffset={80}
        >
          <div className='mb-16 border rounded-md p-4 bg-black'>
            {videoType && (
              <div className='flex justify-between items-center mb-5'>
                <span>{typeIcons[videoType]}</span>
                <span className='mr-auto ml-1'>{videoType}</span>
                <a className={linkStyles} onClick={() => {
                  setAudio(null)
                  setAudioDuration(null)
                  setVideoType('')
                }}><FaArrowRotateRight/></a>
              </div>
            )}
            {topShow}
          </div>
        </Sticky>

      <div>
        {narrationShow}
      </div>
    </div>
  )
}