import React, { useEffect, useState } from "react";
import NarrationPhrase from "./NarrationPhrase";
import { getDnaValue, btnStyles, textInputClass, blankNarrationPhrase, apiPost, getConfig, getFire, apiJson } from "./utils";
import classNames from "classnames";
import "./QuickContinue.scss";
import AudioChooser from "./AudioChooser2";
import BerfButton from "./common/BerfButton";
import { useCheckStatus, useCheckout, useFetchStories, useSetThing } from "./context/hooks";



export function QuickContinue ({ image, storyId, startStory }) {
  const [title, setTitle] = useState('')
  const [meta, setMeta] = useState('')
  const [texts, setTexts] = useState([blankNarrationPhrase({ style: getDnaValue})])
  const [audio, setAudio] = useState({})
  const [story, setStory] = useState({})
  const [audioDuration, setAudioDuration] = useState(0)
  const [config, setConfig] = useState({})
  const [loading, setLoading] = useState(false)
  const [advanced, setAdvanced] = useState(false)
  const [defaultPromptChanged, setDefaultPromptChanged] = useState(false)
  const checkout = useCheckout()
  const setThing = useSetThing()
  const { fetchStory, nodeTextForStory } = useFetchStories()

  useEffect(() => {
    setConfig(getConfig(texts, { ...audio, duration: audioDuration + 0.2 }, image))
  }, [audio, texts, setConfig, audioDuration])
  useEffect(() => {
    if (storyId) {
      async function _fetchStory () {
        const s = await fetchStory(storyId)
        if (s?.dna?.style) {
          setTexts([{
            ...texts[0],
            style: getDnaValue(s, 'style') 
          }])
        }
        setStory(s)
      }
      _fetchStory()
    }
  }, [storyId])
  const maxChars = 140
  const remainingChars = maxChars - meta.length 
  const isOk = meta.length > 3 && 
    meta.length < maxChars &&
    audioDuration > 0 &&
    texts[0].prompt
  
  if (loading) {
    return (
      <div className={classNames('quick-continue w-full mt-4')}>
        here we go...
      </div>
    )
  }

  return (
    <div className={classNames('quick-continue w-full mt-4', { advanced, simple: !advanced })}>
      {/* <div className="field-group mt-12">
        <label htmlFor="title">Story Title</label>
        <input
          type="text"
          id="title"
          className={textInputClass}
          value={title}
          onChange={evt => {
            setTitle(evt.target.value.slice(0, maxChars))
          }}
        />
      </div> */}
      <div className="field-group my-8 meta-text">
        <label htmlFor="meta">{advanced ? 'Meta Text' : 'What comes next?'}</label>
        <textarea 
          id="meta"
          className={classNames(textInputClass, 'h-24 w-full')} 
          value={meta} 
          placeholder={advanced ? `what comes next?` : `what comes after "${nodeTextForStory(storyId)}"?`}
          onChange={evt => {
            const val = evt.target.value
            setMeta(val)
            if (!defaultPromptChanged) {
              setTexts([{
                ...texts[0],
                prompt: val
              }])
            }
          }} 
        />
        {remainingChars < 0 && (
          <p className="text-right text-xs text-red mt-1">too many characters {remainingChars}</p>
        )}
      </div>
      {meta.length > 3 && (
        <>
          <AudioChooser
            voice={getDnaValue(story, 'voice')}
            narration={meta}
            onComplete={a => {
              console.log('audio', a)
              if (a.silenceLength) {
                setAudioDuration(a.silenceLength)
              } else {
                setAudio(a)
                setAudioDuration(a.audioDuration)
              }
            }}
          />
          <div className="field-group my-8 advanced">
            <label>Prompt</label>
            <div className="prompt-container">
              {image && (
                <div className='image-container'>
                  <img src={image} />
                </div>
              )}
              <NarrationPhrase
                texts={texts}
                setTexts={ts => {
                  setTexts(ts)
                  setDefaultPromptChanged(true)
                }}
              />
            </div>
          </div>
          <a onClick={() => setAdvanced(!advanced)} className='text-right block py-4 text-sm underline'>
            {advanced ? 'back 2 basic' : 'be extra'}  
          </a>
        </>
      )}
      <div className="btns flex justify-between">
        {isOk && (
          <BerfButton 
            disabled={!isOk}
            fire={getFire(config)}
            onClick={async () => {
              // create video
              setLoading(true)
              let hasStyle = false
              const cfg = Object.assign({}, config)
              if (cfg.texts[0].style) {
                cfg.style = cfg.texts[0].style
              }
              let res = await checkout({ config: cfg, fire: getFire(config) })
              if (res.ok) {
                res = await apiPost('/user/story', { meta, videoId: res.id, parentId: story?._id, startStory })
                if (title) {
                  res = await apiPost('/user/describe', { type: 'Story', id: res._id, tag: title })
                }
                setThing('modal', null)
              } else {
                window.alert('something went wrong')
              }
              setLoading(false)
            }}
          >create</BerfButton>
        )}
        <BerfButton
          className='cancel ml-auto'
          onClick={() => {
            setThing('modal', null)
          }}
        >cancel</BerfButton>

      </div>
    </div>
  )
}