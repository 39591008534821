import React, { useState } from "react";
import classNames from "classnames";
import { abbreviateNumber, btnStyles } from "../utils";
import { GiSplash } from "react-icons/gi";
import useStore from "../context/hooks";
import { on } from "ramda";
import Fire from "./Fire";


export default function BerfButton({ onClick, children, disabled, fire, className }) {
  const { state, dispatch } = useStore() 
  const { user } = state
  const [youSure, setYouSure] = useState(false)
  let add = null
  let show = children
  let click = onClick
  let no = null
  if (youSure) {
    show = `Use ${fire} berfs?`
    click = () => {
      onClick()
      setYouSure(false)
    }
    no = (
      <button
        className={classNames('ml-4')}
        onClick={() => setYouSure(false)}
      >no</button>
    )
  } else if (fire) {
    add = (
      <>
        <span className='ml-2 text-sm'>
          <Fire fire={fire} />
        </span>
      </>
    )
    // shouldnt be here
    if (!user) {
      show = 'login to use'
    }
    if (user.fire < fire) {
      show = 'Buy Berfs'
      click = () => {
        dispatch({ type: 'modal', payload: 'fire' })
      }
    } else {
      click = () => {
        setYouSure(true)
      }
    }
  }
  return (
    <>
      <button
        disabled={disabled}
        className={classNames(btnStyles, 'flex items-center', className)}
        onClick={click}
        >{show}{add}</button>
      {no}
    </>
  )
}