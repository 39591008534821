import { useCallback, useState, useContext } from 'react'
import { Context } from './context/Context';
import { useDropzone } from 'react-dropzone'
import './Uploader.scss'
import classNames from 'classnames'
import { append, filter, on, set } from 'ramda'
import { apiFetch, getUrl } from './utils'
import ffmpeg from './utils/ffmpeg';

function createFileFromUint8Array(uint8Array, fileName, mimeType) {
  console.log('uint8Array', mimeType)
  const blob = new Blob([uint8Array], { type: mimeType });
  return new File([blob], fileName, { type: mimeType });
}

export default function Uploader ({ onUpload, single, children, fileName = 'image', btnText = 'Click or drop a file' }) {
  const [uploading, setUploading] = useState(false)
  const [loadingText, setLoadingText] = useState('uploading...')
  const { dispatch } = useContext(Context);
  const onDrop = useCallback(async acceptedFiles => {
    setUploading(true)
    let file = acceptedFiles[0]

    if (fileName === 'audio' && file.type === 'video/mp4') {
      setLoadingText('converting mp4 to mp3...')
      const data = await ffmpeg.convertMp4ToMp3(file)
      file = createFileFromUint8Array(data, 'output.mp3', 'audio/mpeg')
      setLoadingText('uploading ...')
    } else if (fileName === 'gif') {
      if (file.type !== 'image/gif') {
        alert('Please upload a .gif file')
        return
      }
      setLoadingText('converting gif to mp4...')
      const data = await ffmpeg.convertGifToMp4(file)
      file = createFileFromUint8Array(data, 'output.mp4', 'video/mp4')
      onUpload && onUpload(file)
      setLoadingText('uploading ...')
      return
    }

    const formData = new FormData();
    formData.append(fileName, file);

    try {
      const response = await apiFetch(`/uploads`, {
        method: 'POST',
        body: formData,
      });

      if (!response.url) {
        throw new Error('Error uploading image');
      }

      const data = await response.json();
      if (onUpload) onUpload(data)
      dispatch({ type: 'refreshUser' })
      console.log('Uploaded image URL:', data);
    } catch (error) {
      console.error('Error uploading image:', error.message);
    }
    setUploading(false)
  }, [])
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ 
    onDrop,
    noClick: true,
    noKeyboard: true
  })
  // let what = <p>Drag 'n' drop {single ? 'a file' : 'some files'} here, or click to select file{!single && 's'}</p>
  // if (isDragActive) {
  //   what = <p>Drop here ...</p>
  // }
  // if (uploading) {
  //   what = <p>Uploading ...</p>
  // }
  if (uploading) {
    btnText = loadingText
  } else if (typeof children === 'string') {
    btnText = children
  }
  return (
    <div className={classNames('uploader', { active: isDragActive, uploading })} {...getRootProps()}>
      <input {...getInputProps()} />
      <button className='' onClick={open} >
        {btnText}
      </button>
      {typeof children !== 'string' && children}
    </div>
  )
}

