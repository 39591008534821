import React, { useEffect } from 'react';
import { FPS, btnStyles, imgUrlForFrame } from './utils';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useIsVisible } from 'react-is-visible'
import { useSetThing } from './context/hooks';
import { AiFillTags } from 'react-icons/ai'
import { GoGitCommit, GoRepoForked } from 'react-icons/go'
import DescribeThing from './DescribeThing';
import VideoButtons from './VideoButtons';

const VideoPlayer = ({ video, poster, url, controls, onRef, buttons, alwaysVisible, loop, muted }) => {
  const videoRef = React.useRef(null)
  const divRef = React.useRef(null)
  const isVisible = useIsVisible(divRef)
  const [realControls, setRealControls] = React.useState(controls !== false)
  const [tagOpen, setTagOpen] = React.useState(false)
  const setThing = useSetThing() 
  const v = video || {}
  if (!url && video) {
    url = video.video
  }
  useEffect(() => {
    if (videoRef.current) {
      if (onRef) onRef(videoRef.current)
    }
  }, [videoRef]);
  if (!url) return null
  if (!poster && video) {
    poster = `https://aiaiaiaiai.biz/images/${video._id}/screenshot-0.jpg`
  }
  const permalink = v?.config?.audio ? 'audio' : 'narration'
  return (
    <div className='video-player' ref={divRef}>
        <video poster={poster} ref={videoRef} className='w-full block' muted={muted} loop={loop !== false} controls={realControls} onClick={() => {
          if (controls === false) {
            setRealControls(true)
          }
        }} >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      {/* {(alwaysVisible || isVisible) ? (
      ) : (
        <div className='w-full block aspect-[1/1]' style={{ backgroundColor: '#000', backgroundImage: `url(${poster})`, backgroundSize: 'cover', backgroundPosition: 'center'}} />
      )} */}
      <VideoButtons video={v} player={videoRef} />
    </div>
  );
}

export default VideoPlayer;