import React, { useState, useEffect } from 'react';

const ArrowKeys = ({ 
  onClick,
  bgColor = 'bg-bg-alt',
  hoverColor = 'hover:bg-gray-900',
  activeColor = 'bg-gray-400'
}) => {
  const [activeKey, setActiveKey] = useState(null);

  const buttonStyle = {
    width: '60px',
    height: '60px',
    fontSize: '24px',
    margin: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.1s',
  };

  const getButtonClass = (key) => {
    return `${activeKey === key ? activeColor : bgColor} ${hoverColor} rounded`;
  };

  const handleKeyDown = (e) => {
    let direction = null;

    switch(e.key) {
      case 'ArrowUp':
        direction = 'up';
        break;
      case 'ArrowDown':
        direction = 'down';
        break;
      case 'ArrowLeft':
        direction = 'left';
        break;
      case 'ArrowRight':
        direction = 'right';
        break;
      default:
        return;
    }

    setActiveKey(direction);
    if (onClick) onClick(direction);
  };

  const handleKeyUp = () => {
    setActiveKey(null);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [onClick]);

  const handleClick = (direction) => {
    if (onClick) onClick(direction);
  };

  return (
    <div className="flex flex-col items-center arrow-keys">
      <button
        className={getButtonClass('up')}
        style={buttonStyle}
        onClick={() => handleClick('up')}
      >
        ↑
      </button>
      <div className="flex justify-center">
        <button
          className={getButtonClass('left')}
          style={buttonStyle}
          onClick={() => handleClick('left')}
        >
          ←
        </button>
        <button
          className={getButtonClass('down')}
          style={buttonStyle}
          onClick={() => handleClick('down')}
        >
          ↓
        </button>
        <button
          className={getButtonClass('right')}
          style={buttonStyle}
          onClick={() => handleClick('right')}
        >
          →
        </button>
      </div>
    </div>
  );
};

export default ArrowKeys;