import VideoPlayer from "./VideoPlayer2"
import VideoButtons from "./VideoButtons"
import useStore from "./context/hooks"
import { useState } from "react"
import Uploader from "./Uploader"
import VideoUploader from "./VideoUploader"
import { IoCloudUploadOutline } from "react-icons/io5";


export default function UserPage ({}) {
  const { state, dispatch } = useStore()
  const { user } = state
  const [page, setPage] = useState(1)
  const [uploaderOpen, setUploaderOpen] = useState(false)
  if (!user) return null
  const PER_PAGE = 50
  const videos = (user.things || [])
    .filter(t => t.type === 'final-video' && t.video)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  console.log(videos)
  const videosToShow = videos.slice(0, page * PER_PAGE)
  return (
    <div className='w-full max-w-xl mx-auto'>
      <header className="flex justify-between items-center">
        <h1>Videos <span className='text-base'>({videos.length})</span></h1>
        <a className='text-3xl' onClick={() => setUploaderOpen(!uploaderOpen)}><IoCloudUploadOutline /></a>
      </header>
      {uploaderOpen && (
        <VideoUploader
          onComplete={data => {
            dispatch({ type: 'refreshUser' })
          }}
        />
      )}
      {videos && (
        <>
          <div className="flex flex-wrap">
            {videosToShow.map(v => (
              <div className="w-1/2 p-2 mb-4">
                <VideoPlayer className='' key={v._id} video={v} />
                <VideoButtons video={v} />
              </div>
            ))}
          </div>
          {videos.length > videosToShow.length && (
            <button className='btn' onClick={() => setPage(page + 1)}>Load More</button>
          )}
        </>
      )}
    </div>
  )
}