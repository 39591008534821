import React, { useContext } from "react";
import { Context, savableSettings } from './Context';
import { apiJson, apiPost } from "../utils";

export const useStore = () => {
  return useContext(Context);
}
export const useSetThing = () => {
  const { dispatch } = useStore()
  return (key, value) => {
    if (savableSettings.includes(key)) {
      localStorage.setItem(key, value)
    }
    dispatch({ type: 'setThing', payload: { key, value } })
  } 
}
export const useCache = () => {
  const { dispatch } = useStore()
  return (key, value = []) => {
    dispatch({ type: 'cache', payload: { key, value } })
  }
}
let checkingStatus = false
export const useCheckStatus = () => {
  const { dispatch } = useStore()
  return async function checkStatus (force) {
    if (checkingStatus && !force) return
    console.log('checking status')
    checkingStatus = true
    const res = await apiJson(`/user/status`)
    if (res.ok && res.queue.length > 0) {
      dispatch({ type: 'setThing', payload: { key: 'queue', value: res.queue } })
      const t = res.queue.find(v => v.started) ? 1000 * 15 : 1000 * 60
      setTimeout(() => checkStatus(true), t)
    } else {
      checkingStatus = false
      dispatch({ type: 'setThing', payload: { key: 'queue', value: null } })
    }
  }
}
export const useCheckout = () => {
  const { dispatch } = useStore()
  const checkStatus = useCheckStatus()
  return (opts) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'setThing', payload: { key: 'beginCheckout', value: opts } })
      dispatch({ type: 'setThing', payload: { key: 'checkout', value: null } })
      dispatch({ type: 'setThing', payload: { key: 'checkoutError', value: null } })
      return apiPost(`/payment/checkout`, opts)
        .then(res => {
          if (res.ok) {
            dispatch({ type: 'setThing', payload: { key: 'beginCheckout', value: null } })
            dispatch({ type: 'setThing', payload: { key: 'checkout', value: res } })
            dispatch({ type: 'refreshUser' })
            checkStatus()
            resolve(res)
          } else {
            dispatch({ type: 'setThing', payload: { key: 'checkoutError', value: res } })
            resolve(res)
          }
        })
        .catch(err => {
            dispatch({ type: 'setThing', payload: { key: 'checkoutError', value: err } })
            resolve(err)
        })
    })
  }
}
export * from './useFetchStories'
export default useStore