import { forwardRef, useImperativeHandle, useState, useRef, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { apiJson, apiPost, imgUrlForFrame, isValidObjectId } from "../utils"
import { set } from "ramda"
import classNames from "classnames"
import VideoPlayer from "../VideoPlayer2"
import useStore, { useSetThing } from "../context/hooks"
import VideoButtons from "../VideoButtons"
import VideoClips from "../VideoClips"
import TikTok from "../TikTok"
import "../See.scss"
import "./VideoTikTok.scss"
import VideoQueue from "../VideoQueue"
import ArrowKeys from "../common/ArrowKeys"
import { QuickContinue } from "../QuickContinue"
import { VscDebugContinue } from "react-icons/vsc";
import { GoGitCommit, GoRepoForked } from 'react-icons/go';
import { MdOutlineReplay } from "react-icons/md";
import { IoPlayForward } from "react-icons/io5";
// import { hierarchy } from "d3"




const VideoTikTok = forwardRef(({ hierarchy, onChange, selected, onStartChange, trip }, ref) => {
  const player = useRef(null)
  const playerState = useRef({})
  const tikTokRef = useRef(null)
  const setThing = useSetThing()
  const [dragging, setDragging] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [mode, setMode] = useState('autoplay')
  const [currentVideo, setCurrentVideo] = useState(null)
  const [chosenPath, setChosenPath] = useState(0)
  const [preChangeStoryId, setPreChangeStoryId] = useState(null)
  const { state } = useStore()
  const { user, storiesCache, videosCache } = state
  let showVideos = null
  let videoPath = null
  useEffect(() => {
    setPreChangeStoryId(selected)
  }, [selected, setPreChangeStoryId])

  const videoChange = videoId => {
    if (player.current && !player.current.isDisposed()) {
      player.current.currentTime(0)
      player.current.off('ended')
      if (mode === 'autoplay') {
        player.current.loop(false)
        player.current.on('ended', () => {
          tikTokRef.current.handleNavigation('right')
        })
      }
      if (mode === 'loop') {
        player.current.loop(true)
      }
      if (playing) {
        player.current.play()
      }
    }
  }
  const getVideoForStoryId = _id => {
    const story = storiesCache[_id]
    return videosCache[story.videoId]
  }

  const renderVideo = (_id) => {
    const video = getVideoForStoryId(_id) 
    const img = `https://aiaiaiaiai.biz/images/${video._id}/screenshot-0.jpg`
    let show = (
      <img src={img} className='w-full square' />
    )

    return (
      <a 
        key={`v-${_id}`}
        className={classNames('video-cover', { selected: video._id === currentVideo })}
        onClick={() => {
          if (dragging) {
            setDragging(false)
            return
          }
          const newPlaying = !playing
          if (player.current && !player.current.isDisposed()) {
            if (newPlaying) {
              player.current.play()
            } else {
              player.current.pause()
            }
          }
          setPlaying(newPlaying)
        }}
      >
        {show}

      </a>
    )
  }

  if (hierarchy) {
    showVideos = (
      <>
        <TikTok
          trip={trip}
          selected={selected}
          onDrag={setDragging}
          renderItem={renderVideo}
          hierarchy={hierarchy}
          onStartChange={storyId => {
            setPreChangeStoryId(storyId)
            onStartChange && onStartChange(storyId)
          }}
          onChange={storyId => {
            // videoChange(storiesCache[storyId].videoId)
            onChange && onChange(storyId)
          }}
          ref={tikTokRef}
        />
      </>
    )
    videoPath = (
      <div className='legend p-2 flex justify-end absolute bottom-0 w-full z-10'>
        <a
          onClick={() => {
            if (player.current && !player.current.isDisposed()) {
              // turning to autoplay
              if (mode === 'loop') {
                player.current.loop(false)
                player.current.on('ended', () => {
                  tikTokRef.current.right()
                })
              } else {
                player.current.loop(true)
                player.current.off('ended')
              }
            }
            setMode(mode === 'loop' ? 'autoplay' : 'loop')
          }}
        >{mode === 'loop' ? <MdOutlineReplay /> : <IoPlayForward />}</a>
      </div>
    )
  }

  const video = getVideoForStoryId(selected) 
  const handleNavigation = (direction) => {
    if (tikTokRef.current) {
      tikTokRef.current.handleNavigation(direction);
    }
  };
  useImperativeHandle(ref, () => ({ handleNavigation }));
  return (
    <div className='max-w-2xl video-tt relative aspect-square'> 
      <div className='video-wrap w-full aspect-square'>
        <VideoPlayer 
          key={video._id} 
          video={video} 
          className='w-full square'
          controls={false}
          onRef={p => {
            player.current = p
            p.on('loadeddata', () => {
              setCurrentVideo(video._id)
              videoChange(video._id)
            })
            videoChange(video._id)
          }}
        />

      </div>
      {showVideos}
      {videoPath}
    </div>
  )
})
export default VideoTikTok