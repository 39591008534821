import React, { useState } from 'react';
import classNames from 'classnames';
import * as Progress from '@radix-ui/react-progress';
import useStore from './context/hooks';
import { estimateProcessingTime, estimateProcessingTimeRaw } from './utils';

const ProgressDemo = ({ video }) => {
  const [progress, setProgress] = React.useState(1);
  const lastProgress = React.useRef(0);

  React.useEffect(() => {
    let prog = (Date.now() - video.started) / (estimateProcessingTimeRaw(video.durationAhead) * 1000 * 60) * 100
    if (prog > 100) {
      prog = 100
    }
    if (prog === lastProgress.current) return
    const timer = setTimeout(() => setProgress(prog), 500);
    lastProgress.current = prog
  }, [video, setProgress]);

  return (
    <Progress.Root
      className="relative overflow-hidden bg-blackA6 rounded-full w-full h-[5px]"
      style={{
        transform: 'translateZ(0)',
      }}
      value={progress}
    >
      <Progress.Indicator
        className="bg-yellow-400 w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root>
  );
};


export default function VideoQueue() {
  const { state, dispatch } = useStore()
  const { queue = [] } = state;
  if (!queue || queue.length === 0) {
    return null
  }
  return (
    <div className='m-2 mt-4 video-queue p-1 text-xs text-yellow-400 text-right field-group'>
      <label>Queue</label>
      {queue.map((v, i) => {
        let show = (
          <>
            <ProgressDemo video={v} />
            <div className=''>
              {estimateProcessingTime(v.durationAhead)} to process
            </div>
          </>
        )
        if (!v.started) {
          show = (
            <div>
              {v.i === 0 ? 'next' : v.i + ' before you'} in queue
            </div>
          )
        }
        return (
          <div key={v._id} className='px-4 py-2'>
            {show}
          </div>
        )
      })}
    </div>
  )
}