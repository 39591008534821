import { useEffect, useState, useRef } from "react"
import { apiFetch, isBerf } from "./utils"
import VideoPlayer from "./VideoPlayer"
import { Link } from "react-router-dom"
import { path, prop, set, uniqBy } from "ramda"
import VideoTile from "./VideoTile"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import "./Videos.scss"
import { useIsVisible } from 'react-is-visible'
import classNames from "classnames"


export default function Videos({}) {
  const [videos, setVideos] = useState([])
  const [tags, setTags] = useState([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [originalVideos, setOriginalVideos] = useState([])
  const [search, setSearch] = useState('')
  const divRef = useRef(null)
  const isVisible = useIsVisible(divRef)
  useEffect(() => {
    if (page < 0) return
    setLoading(true)
    const api = search ? '/search?s=' + search : '/v?page=' + page
    apiFetch(api)
      .then(res => res.json())
      .then(res => {
        let v = res
        if (search) {
          v = res.videos || []
          setTags(res.tags || [])
        } else {
          setTags([])
        }
        setLoading(false)
        if (v.length === 0) {
          setPage(-1)
          return
        }
        setVideos(uniqBy(prop('_id'), [...videos, ...v]))
      })
  }, [page, setLoading, setVideos])
  console.log('videos', videos)

  useEffect(() => {
    if (isVisible && !loading && page >= 0 && !search) {
      setPage(page + 1)
    }
  }, [isVisible])
  return (
    <div className={classNames('videos', { loading })}>
      <div className="search">
        <textarea 
          value={search} 
          onChange={e => setSearch(e.target.value)} 
          placeholder="Search..."
          onKeyUp={e => {
            if (e.key === 'Enter') {
              if (originalVideos.length === 0) {
                setOriginalVideos(videos)
              }
              setVideos([])
              setSearch(search.trim())
              setPage(page + 1)
            }
          }}
        />
        {search && (
          <a className="clear" onClick={() => {
            setVideos(originalVideos)
            setSearch('')
            setPage(0)
          }}>❌</a>
        )}
      </div>
      {tags.length > 0 && (
        <div className="m-5">
          <h2>Tags</h2>
          <ul className='tags'>
            {tags.map(t => (
              <li key={t.tag}>
                <Link to={`/c/${t.tag}`}>{t.tag} ({t.tagCount})</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      <ResponsiveMasonry
        columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
        className="masonry-grid"
      >
        <Masonry gutter="20px">
          {videos.map(v => {
            const poster = path(['images', 0], v) && `https://aiaiaiaiai.biz/${path(['images', 0], v)}`
            return (
              <div key={v._id}>
                {/* <VideoTile video={v} /> */}
                <VideoPlayer loop={false} video={v} controls={false} />
              </div>
            )
          })}
        </Masonry>
      </ResponsiveMasonry>
      <div ref={divRef} className='load-more'>
        {loading ? 'one sec...' : ((page < 0 || search) && !isBerf() ? 'Want to search eVeRYtH1nG!? Join us over at Berf\'s' :  ':)')}
      </div>
    </div>
  )
}