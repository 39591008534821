import React, { useState, useEffect } from "react";
import { btnStyles, inputsCommon, getUrl, doLogin, linkStyles, abbreviateNumber } from "./utils";
import classNames from "classnames";
import { set } from "ramda";
import "./AuthForm.scss"
import UserMenu from "./UserMenu";
import useStore, { useSetThing } from "./context/hooks";
import { FaDiscord } from "react-icons/fa";


const AuthForm = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] =  useState("");
  const { state, dispatch } = useStore()
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const setThing = useSetThing()
  const { modal } = state

  const setOpen = (value) => {
    setThing('modal', value ? 'auth' : null)
  }
  const open = modal === 'auth'

  useEffect(() => {
    setError(null)
  }, [modal])

  const doAuth = p => {
    setLoading(true)
    return fetch(getUrl() + p, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email, password})
    })
      .then(res => res.json())
      .finally(() => setLoading(false))
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Implement your form submission logic here
    const path = isLogin ? '/auth/login' : '/auth/signup'
    setError(null)
    const handleAuthResponse = json => {
      if (!json.token) return setError('email & password do not match')
      setLoading(true)
      doLogin(json.token)
        .then(ok => {
          dispatch({ type: 'user', payload: ok })
          setLoading(false)
        })
    }
    doAuth(path)
      .then(json => {
        if (!isLogin) {
          return doAuth('/auth/login')
            .then(handleAuthResponse)
        } 
        handleAuthResponse(json)
      })
      .catch(err => {
        setError('email & password do not match')
      })
  }
  let show = null
  if (loading) {
    show = <>uno momento...</>
  } else if (state.user) {
    // show = <> <a className={linkStyles} onClick={() => {
    // show = <><UserMenu /> </>
    return null
  } else if (!open) {
    show = <span><button onClick={() => {
      setOpen(true)
      setIsLogin(true)
    }}>login</button> / <button onClick={() => {
      setOpen(true)
      setIsLogin(false)
    }}>signup</button></span>
  }
  if (show) {
    return <span className="auth-form absolute text-sm closed">{show}</span>
  }

  return (
    <div className={classNames("auth-form open", { login: isLogin, signup: !isLogin })}>
      <div className="form-wrap relative">
        <a className={classNames(linkStyles, "absolute top-2 right-6 text-lg")} onClick={() => setOpen(false)}>❌</a>
        <div className='cols mt-5'>
          {/* <div className="benefits">
            <h2>signup y?</h2>
            <ul className="pt-3">
              <li>it's free</li>
              <li>cheaper generation rates</li>
              <li>no need to pay every time</li>
              <li>easier site navigation</li>
              <li>turn off the 🗣️"aiaiaiaiai"</li>
              <li>keep track of your stuff</li>
              <li>no longer mid</li>
              <li>a neccessary precondition for joining berf's club</li>
            </ul>

          </div> */}
          <div className='formx text-center'>
            <a href={getUrl() + '/auth/discord'} className="btn btn-sm mb-4"><FaDiscord /> Login via Discord</a>
            <form onSubmit={handleFormSubmit}>
              <p className="my-6">or</p>
              <div className="form">
                <label>
                  Email:<br/>
                  <input
                    type="email"
                    name='email'
                    value={email}
                    className={inputsCommon}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>
                <br />
                <label>
                  Password:<br/>
                  <input
                    type="password"
                    name='password'
                    value={password}
                    className={inputsCommon}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </label>
                <br />
                <label className='confirm'>
                  Confirm Password:<br/>
                  <input
                    type="password"
                    name='cpassword'
                    value={confirmPassword}
                    className={inputsCommon}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <br />
                </label>
                {error && <p className="text-red-500">{error}</p>}
              </div>
              <button disabled={!isLogin && (password === '' || password !== confirmPassword)} className={btnStyles} type="submit">{isLogin ? "Login" : "Create Account"}</button>
            </form>
          </div>
        </div>
        <div className="text-center">
          {/* <h1>{isLogin ? <>You're back! <small>we made a mess</small> </> : "OMG, Get. In. here!"}</h1> */}
          <button className="mb-2" onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? "Need to create an account?" : "Already have an account?"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;