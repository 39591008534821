import { useMemo, useRef, useEffect, useCallback } from "react";
import * as d3 from "d3";
import { uniq } from "ramda";
import { POP_COLOR, areHierarchiesSame } from "../utils";

const MARGIN = { top: 20, right: 10, bottom: 0, left: 10 };


export const SimplePathNav = ({ width, height, hierarchy, selected, trip = [], onClick }) => {
  const svgRef = useRef(null); // Ref for the SVG container
  const lastHier = useRef(null); // Ref for the last hierarchy
  const lastCurrent = useRef(null); // Ref for the last selected node
  const lastWidth = useRef(null); // Ref for the last width
  const lastTrip = useRef(null); // Ref for the last trip
  const usedSibs = useRef([]); // Ref for the last used siblings
  const onClickRef = useRef(onClick)

  useEffect(() => {
    onClickRef.current = onClick
  }, [onClick])

  useEffect(() => {
    if (!hierarchy) return; // Guard clause if data is not yet available
    const current = hierarchy.find(v => v.id === selected)
    const _lastTrip = lastTrip.current
    if (lastCurrent?.current?.id === selected && 
      lastWidth.current === width &&
      _lastTrip.length === trip.length
    ) {
      return
    }

    // Setup dimensions
    const boundsWidth = width - MARGIN.right - MARGIN.left;
    const boundsHeight = height - MARGIN.top - MARGIN.bottom;
    const centerWidth = boundsWidth / 2;
    const centerHeight = boundsHeight / 2;
    const xRight = boundsWidth - centerWidth / 4
    const xLeft = centerWidth / 4

    const largeRadius = 10

    const svg = d3.select(svgRef.current);

    const popColor = POP_COLOR
    const transitionTime = 500

    // Clear previous contents
    if (lastWidth.current !== width) {
      svg.selectAll("*").remove();
    }
    lastWidth.current = width

    // Setup the group for the dendrogram
    let g = svg.select(".dendrogram-group");
    if (g.empty()) {
      g = svg.append("g")
            .attr("class", "dendrogram-group")
    }
    g.attr("transform", `translate(${MARGIN.left},${MARGIN.top})`);

    let isNext = current?.depth > (lastCurrent?.current?.depth || 0) 
    let isLast = current?.depth < (lastCurrent?.current?.depth || 0)
    let isSib = false
    if (lastCurrent?.current?.depth === current?.depth) {
      isSib = true
      isNext = false
      isLast = false
    }
    if (isNext || isLast) {
      usedSibs.current = []
    }

    if (current) {
      const siblings = current.parent?.children || [current];
      // all siblings after current
      const sibData = siblings.slice(siblings.findIndex(s => s.id === current.id))

      const sibs = g.selectAll(".siblings")
        .data(sibData, d => d.id)
      let isLastSib = false
      if (siblings.length > 1) {
        if (usedSibs.current.includes(selected)) {
          isLastSib = true
          usedSibs.current.pop()
        } else {
          usedSibs.current.push(selected)
        }
      }

      const yForChildren = (i, nodes) => {
        return (boundsHeight / (nodes.length + 1) * i)
      }
      const rForChildren = (i) => {
        if (i === 0) return 6
        return 4
      }
      const nodeOnClick = (event, d) => {
        onClickRef.current && onClickRef.current(d.id)
      }

      const fadeGradient = svg.select("defs linearGradient#left-gradient");
      const rightGradient = svg.select("defs linearGradient#right-gradient"); 
      if (fadeGradient.empty()) {
        svg.append("defs")
          .append("linearGradient")
          .attr("id", "left-gradient")
          .attr("gradientUnits", "userSpaceOnUse") // This makes the gradient fixed when zooming/panning
          .attr("x1", 0).attr("y1", 0)
          .attr("x2", xLeft).attr("y2", 0); // Horizontal gradient

        svg.append("defs")
          .append("linearGradient")
          .attr("id", "right-gradient")
          .attr("gradientUnits", "userSpaceOnUse") // This makes the gradient fixed when zooming/panning
          .attr("x1", xRight).attr("y1", 0)
          .attr("x2", boundsWidth).attr("y2", 0); // Horizontal gradient
      }
      // Define the start and end colors of the gradient
      fadeGradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", popColor)
        .attr("stop-opacity", 0); // Fully transparent at x = 0
      
      fadeGradient.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", popColor)
        .attr("stop-opacity", 1); // Fully opaque at the other end

      rightGradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", popColor)
        .attr("stop-opacity", 1); // Fully transparent at x = 0
      
      rightGradient.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", popColor)
        .attr("stop-opacity", 0); // Fully opaque at the other end
      

      // current line
      const currentLine = g.selectAll(".current-line").data(current.children && current.children.length > 0 ? ['yes'] : [], d => d)
      currentLine.enter().append("line")
        .attr("class", "current-line future")
        .attr("stroke", popColor)
        .attr("stroke-width", 2)
        .attr("x1", centerWidth)
        .attr("y1", 0)
        .attr("x2", centerWidth)
        .attr("y2", 0)
        .transition().duration(transitionTime)
          .attr("x2", xRight)

      currentLine.exit()
        .transition().duration(transitionTime)
          .attr("x2", centerWidth)
          .remove()


      //-- Siblings
      const sibR = (node, i) => i === 0 ? largeRadius : 4
      sibs.enter().append("g")
        .attr("class", "siblings node")
        .append("circle")
        .attr("r", sibR) 
        .style("fill", popColor)
        .on("click", nodeOnClick)
        .each(function(d, i, nodes) {
          const _this = d3.select(this)
          if (isSib) {
            if (isLastSib) {
              _this
                .attr('transform', `translate(${centerWidth},${yForChildren(-1, nodes)})`)
                .attr('r', 4)
                .transition().duration(transitionTime)
                  .attr('transform', `translate(${centerWidth},${yForChildren(i, nodes)})`)
                  .attr('r', largeRadius)

            } else {
              _this
                .attr('transform', (d, i) => {
                  return `translate(${centerWidth},${yForChildren(i + 1, siblings)})`
                })
                .transition().duration(transitionTime)
                  .attr('transform', (d, i) => {
                    return `translate(${centerWidth},${yForChildren(i, siblings)})`
                  })

            }
            
          } else {
            _this
              .attr('opacity', 0)
              .attr('transform', `translate(${centerWidth},${yForChildren(i, siblings)})`)
              .transition().delay(transitionTime / 1.5)
                .attr('opacity', 1)
          }
        })

      sibs
        .select('circle')
        .transition().duration(transitionTime)
          .attr('r', sibR)
          .attr('transform', (d, i, nodes) => {
            return `translate(${centerWidth},${yForChildren(i, siblings)})`
          })
      sibs.exit()
        .select('circle')
        .each(function(d, i , nodes) {
          const _this = d3.select(this)
          const y = yForChildren(i, nodes)
          const parentG = d3.select(this.parentNode);
          if (isSib) {
            _this
              .transition().duration(transitionTime)
                .attr('transform', `translate(${centerWidth},${yForChildren(-1, nodes)})`)
                .attr('r', 4)
                .on('end', function() {
                  parentG.remove(); // remove the parent g after transition ends
                })
          } else {
            parentG.remove()
          }
        })

      const children = g.selectAll(".children")
        .data(current.children || [], d => d.id)

      children.enter().append("g")
        .attr("class", "children node future")
        .append("circle")
          .style("fill", popColor)
          .each(function(d, i, nodes) {
            const _this = d3.select(this)
            const y = yForChildren(i, nodes)
            _this
              .attr("r", rForChildren(i)) 
            if (isNext || isSib) {
              _this          
                .attr('transform', `translate(${boundsWidth + centerWidth / 3},${y})`)
                .transition()
                .duration(transitionTime)
                  .attr('transform', `translate(${xRight},${y})`)
            } else {
              _this
                .attr('transform', `translate(${centerWidth},${y})`)
                .attr('r', i === 0 ? largeRadius : 4)
                .transition().duration(transitionTime)
                  .attr('transform', `translate(${xRight},${y})`)
                  .attr('r', rForChildren(i))
            }
          })

      children 
        .select('circle')
        .each(function(d, i, nodes) {
          const _this = d3.select(this)
          _this
            .attr("transform", d => {
              return  `translate(${xRight},${yForChildren(i, nodes)})`
            })
        })

      children.exit()
        .select('circle')
        .each(function(d, i , nodes) {
          const _this = d3.select(this)
          const y = yForChildren(i, nodes)
          const parentG = d3.select(this.parentNode);
          if (isSib) {
            parentG.remove()
          } else if (isNext) {
            _this
              .transition()
              .duration(transitionTime)
                .attr('transform', `translate(${centerWidth},${y})`)
                .attr('r', i === 0 ? largeRadius : 4)
                .on('end', function() {
                  parentG.remove(); // remove the parent g after transition ends
                });
          } else {
            _this
              .transition()
              .duration(transitionTime)
                .attr('transform', `translate(${boundsWidth + centerWidth / 3},${y})`)
                .on('end', function() {
                  parentG.remove(); // remove the parent g after transition ends
                });
          }
        })

      g.selectAll(".child-count").remove(); // Remove existing child-count elements
      if (current?.children?.length > 1) {
        children
          .append("text")
          .attr('class', 'child-count')
          .attr("dx", 0) // Adjust text position relative to the node circle
          .attr("dy", ".35em") // Vertically center the text
          .attr("text-anchor", "middle") // Center the text horizontally
          .attr("font-size", "9px") // Set text size
          .attr('cursor', 'pointer')
          .text(current.children.length)
          .style("fill", "black"); // Set text color
      }

      // right line extender
      const rightLineExtSelection = g.selectAll(".right-line-ext")
        .data(current.children && current.children.length > 0 && current.children[0].children ? ['yes'] : [], d => d)
      rightLineExtSelection.enter().append("line")
        .attr("class", "right-line-ext future")
        .attr("stroke", 'url(#right-gradient)')
        .attr("stroke-width", 2)
        .attr("x1", xRight)
        .attr("y1", 0)
        .attr("x2", xRight)
        .attr("y2", 0)
        .transition().duration(transitionTime)
          .attr("x2", boundsWidth)

      rightLineExtSelection.exit()
        .transition().duration(transitionTime)
          .attr("x2", xRight)
          .remove()
    }

    // trip
    const tripSelection = g.selectAll(".trip")
      .data(trip);
    const tripClick = (event, d) => {
      onClick && onClick(trip[trip.length - 1]);
    }

    tripSelection.enter().append("g")
      .attr("class", "trip node")
      .attr("transform", `translate(${xLeft},0)`)
      .append("circle")
        .style("fill", "#CFFC52")
        .on("click", tripClick)
        .attr("r", largeRadius) 
        .attr("transform", `translate(${xLeft*3},0)`) // Start from center
        .transition()
        .duration(transitionTime) // Animation duration for entering elements
        .attr("transform", `translate(0,0)`) // Animate to the left
        .attr("r", 6) 
        .end()

    const exitToLeft = lastTrip.current && lastTrip.current.length > 0 && trip.length > 0 && lastTrip.current[0] !== trip[0]
    tripSelection.exit()
      .transition()
      .duration(transitionTime) // Animation duration for exiting elements
      .attr("r", largeRadius) 
      .attr("transform", `translate(${exitToLeft ? -10 : centerWidth},0)`) // Animate back to the center
      .remove()
      .end()

    // trip line
    const tripLineSelection = g.selectAll(".trip-line")
      .data(trip.length > 0 ? ['yes'] : [], d => d)
    tripLineSelection.enter().append("line")
      .attr("class", "trip-line")
      .attr("stroke", popColor)
      .attr("stroke-width", 2)
      .attr("x1", centerWidth)
      .attr("y1", 0)
      .attr("x2", centerWidth)
      .attr("y2", 0)
      .transition().duration(transitionTime)
        .attr("x2", xLeft)

    tripLineSelection.exit()
      .transition().duration(transitionTime)
        .attr("x2", centerWidth)
        .remove()

    // trip line extender
    const tripLineExtSelection = g.selectAll(".trip-line-ext")
      .data(trip.length > 1 ? ['yes'] : [], d => d)
    tripLineExtSelection.enter().append("line")
      .attr("class", "trip-line-ext")
      .attr("stroke", 'url(#left-gradient)')
      .attr("stroke-width", 2)
      .attr("x1", xLeft)
      .attr("y1", 0)
      .attr("x2", xLeft)
      .attr("y2", 0)
      .transition().duration(transitionTime)
        .attr("x2", 0)

    tripLineExtSelection.exit()
      .transition().duration(exitToLeft ? 0 : transitionTime)
        .attr("x2", xLeft)
      .remove()

    // trip count
    const tripTextSelection = g.selectAll(".trip-text")
      .data([trip], d => d.length)

    tripTextSelection.enter().append("g")
      .attr('class', 'trip-text')
      .attr("transform", `translate(${xLeft},3)`)
      .append("text")
      .attr("text-anchor", "middle") // Center the text horizontally
      .attr("font-size", "9px") // Set text size
      .attr('cursor', 'pointer')
      .text(d => d.length > 1 ? d.length : '')
      .style("fill", "black")
      .on("click", tripClick)

    tripTextSelection
      .transition()
      .delay(transitionTime * 2)
      .select('text')
      .text(d => d.length > 1 ? d.length : '')

    tripTextSelection.exit().remove()


    lastCurrent.current = current
    lastTrip.current = trip
  }, [hierarchy, height, selected, width, trip]); // Re-run effect if any of these dependencies change


return (
    <svg ref={svgRef} width={width} height={height}></svg>
  );
};