import React, { useState, useContext } from 'react';
import { Context } from './context/Context';
import BraintreeDropIn from './BraintreeDropin';
import { apiFetch, btnStyles, getPrice, getSecs, getUrl, textInputClass, getFire, linkStyles } from './utils';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import VideoSummary from './VideoSummary';
import Price from './common/Price';

export default function Checkout({ config, onCancel, onPaymentCompleted }) {
  const { state, dispatch } = useContext(Context);
  const { user } = state
  const [processing, setProcessing] = useState(false)
  const [code, setCode] = useState('')
  const [email, setEmail] = useState('')
  const [free, setFree] = useState(false)
  const navigate = useNavigate()
  const checkout = async (braintree) => {
    let fire = null
    if (typeof braintree === 'number') {
      fire = braintree
      braintree = null
    }
    setProcessing(true)
    const res = await apiFetch(`/payment/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        braintree,
        config,
        fire,
        email,
        estimatedSecs: getSecs(config),
        code,
        price: parseFloat(getPrice(config))
      })
    }).then(res => res.json())
    
    if (res.ok) {
      dispatch({ type: 'refreshUser' })
      navigate(`/v/${res.id}`)

      // redirect to video page
    } else {
      alert('something went wrong')
    }
  }
  let show = (
    <>
      {!user && (
        <>
          <label className='block mt-5'>We'll only email you when it's ready</label>
          <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} className={textInputClass} placeholder='email required' />
        </>
      )}
      <div className='grid gap-4 grid-cols-3 grid-rows-1 mt-5 mb-5 items-center'>
        <span className='block pt-3'>Discount Code</span>
        <input 
          type="text" 
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className={classNames(textInputClass, 'h-11')} 
          placeholder="" 
        />
        <button className={linkStyles} onClick={async () => {
          const res = await fetch(`${getUrl()}/code/${code}`).then(res => res.json())
          if (!res.code) {
            return setCode('')
          }
          if (res.amount === 0) {
            setFree(code)
          }

        }}>Apply</button>
      </div>
      <BraintreeDropIn 
        btnDisabled={!email || !/.+@.+\..+/.test(email)}
        show 
        onPaymentCompleted={async (braintree) => {
          checkout(braintree)
        }} 
      />
    </>
  )
  const canUseFire = user && user.fire > 0
  let showPriceDetails = !canUseFire
  if (processing) {
    show = (
      <div className='text-center'>
        Processing...
      </div>
    )
  } else if (canUseFire) {
    if (user.fire >= getFire(config)) {
      show = (
        <div className='text-center'>
          <button className={classNames(btnStyles)} onClick={() => {
            checkout(getFire(config))
          }}>Use <Price config={config} /></button>
        </div>
      )
    }
  } else if (free) {
    show = (
      <div className='text-center'>
        <button className={classNames(btnStyles, 'text-7xl')} onClick={() => {
          checkout(null)

        }}>FREE!!</button>
      </div>
    )
  }
  return (
    <div className='checkout'>
      <div className='inner bg-black p-10 rounded-xl max-w-md'>
        {!processing && (
          <a className='text-2xl pr-4 pb-4 float-right' onClick={onCancel}>❌</a>
        )}
        <div className='text-left mb-5 mt-5'>
          <h1 className='text-2xl text-center mb-2 font-bold'>Create Video</h1>
          <VideoSummary config={config} />
          <br/>
          {showPriceDetails && (
            <div>
              <h3>Video Generation: ${getPrice(config)}</h3>
              <h3>Total: ${parseFloat(getPrice(config))}</h3>
            </div>
          )}
          {show}
        </div>
      </div>
    </div>
  )
}