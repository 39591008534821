import { useState } from "react"
import Uploader from "./Uploader"
import { apiFetch, inputsCommon } from "./utils"
import classNames from "classnames"
import { set, uniq } from "ramda"
import BerfButton from "./common/BerfButton"

export default function VideoUploader({ onUpload }) {
  const [videoSrc, setVideoSrc] = useState()
  const [video, setVideo] = useState()
  const [descriptions, setDescriptions] = useState([])
  const [description, setDescription] = useState('')
  let show = (
    <Uploader
      fileName="gif"
      onUpload={data => {
        setVideo(data)
        setVideoSrc(URL.createObjectURL(data))
      }}
    >upload .gif</Uploader>
  )
  if (videoSrc) {
    show = (
      <div>
        <video loop controls src={videoSrc} />
        <h3 className="text-xl mb-1">Describe this video. <span className="text-sm">2 minimum</span></h3>
        <p>
          The more accurate the better.
          {descriptions.length > 0 && (
            <span> It's also useful to describe how this video can be used. For example: "used as an exclamation" or "illustrates the injustice of it all" </span>
          )}
        </p>
        <textarea
          value={description}
          className={classNames(inputsCommon, 'w-full mt-3')}
          onChange={evt => setDescription(evt.target.value)}
          onKeyUp={evt => {
            if (evt.key !== 'Enter') return
            setDescriptions(uniq([...descriptions, description.trim()]))
            setDescription('')
          }}
        ></textarea>
        <ul>
          {descriptions.map((d, i) => (
            <li key={`d-${i}`}>{d}</li>
          ))}
        </ul>
        {descriptions.length > 1 && (
          <BerfButton
            onClick={async () => {
              const formData = new FormData();
              formData.append('video', video);
              formData.append('descriptions', JSON.stringify(descriptions));
              try {
                const response = await apiFetch(`/uploads`, {
                  method: 'POST',
                  body: formData,
                });

                if (!response.url) {
                  throw new Error('Error uploading image');
                }

                const data = await response.json();
                if (onUpload) onUpload(data)
                setVideoSrc(null)
                setVideo(null)
                setDescription('')
                setDescriptions([])
                console.log('Uploaded video:', data);
              } catch (error) {
                console.error('Error uploading image:', error.message);
              }
            }}
          >Upload</BerfButton>
        )}

      </div>
    )
  }
  return (
    <div className="video-uploader">
      {show}
    </div>
  )
}