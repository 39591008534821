import useStore from "../context/hooks"
import classNames from "classnames"
import "./Modal.scss"

const Modal = ({ className, children, onClose, buttons }) => {
  const { state, dispatch } = useStore()
  const close = () => {
    onClose && onClose()
    dispatch({ type: 'setThing', payload: { key: 'modal', value: null }})
  }
  return (
    <div className={classNames(className, "z-20 modal fixed top-0 left-0 w-full h-full flex items-center justify-center modal")}>

        <div className="max-h-full py-4 bg-black w-full md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto relative modal-inner">
            {/* <a href="#" className="z-10 top-0 right-0 m-6 text-gray-200 absolute hover:text-gray-300" onClick={close}>

                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </a> */}
            <div className="py-4 text-left px-4">
                {children}
            </div>
            <div className='flex justify-between px-4 buttons'>
              {buttons}
              <button className="btn cancel ml-auto" onClick={close}>close</button>
            </div>
        </div>
    </div>
  )
} 
export default Modal