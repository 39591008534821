import BerfButton from './common/BerfButton'

export default function ErrorPage () {
  return (
    <div className="flex flex-col items-center justify-center h-screen error-page">
      <h1 className="text-4xl font-bold">404</h1>
      <p className="text-2xl mb-6">Page not found</p>
      <BerfButton onClick={() => window.location.reload()}>Refresh</BerfButton>
    </div>
  )
}