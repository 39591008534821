import { useState, useCallback } from 'react';
import { useCache, useStore } from './hooks'; // Import your custom store hook
import { apiJson } from '../utils';

export const useFetchStories = () => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { state, dispatch } = useStore();
  const { storiesCache, nodesCache, videosCache, preferred } = state;
  const cache = useCache();

  const fetchStory = useCallback(async (storyId, skipCache) => {
    // Check if the story is already in the cache
    if (state.storiesCache[storyId] && !skipCache) {
      return state.storiesCache[storyId];
    } else {
      // Fetch the story if not in cache
      const storyData = await apiJson(`/v/stories/${storyId}`);
      console.log('storyData', storyData)
      cache('stories', storyData.stories)
      cache('nodes', storyData.nodes)
      cache('videos', storyData.videos)
      if (/-/.test(storyId)) {
        return storyData.stories[0]
      }
      return storyData.stories.find(s => s._id === storyId)
    }
  }, [dispatch, storiesCache]);

  const fetchStories = useCallback(async (storyIds) => {
    setIsLoading(true);
    setError(null);
    let ret = null

    try {
      const IdsToFetch = storyIds.filter(id => !storiesCache[id]);
      if (IdsToFetch.length === 0) {
        return
      }
      const storyData = await apiJson(`/v/stories/?ids=${IdsToFetch.join(',')}`);
      cache('stories', storyData.stories)
      cache('nodes', storyData.nodes)
      cache('videos', storyData.videos)
      
      // const fetchPromises = storyIds.map(fetchStory);

      // // Wait for all fetches to complete
      // const fetchedStories = await Promise.all(fetchPromises);
      // setStories(fetchedStories);
      // ret = fetchedStories
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
    return ret
  }, [dispatch, storiesCache]);

  const nodeTextForStory = useCallback((storyOrStoryId) => {
    const storyId = typeof storyOrStoryId === 'object' ? storyOrStoryId._id : storyOrStoryId;
    const story = storiesCache[storyId];
    if (!story) return null;
    return nodesCache[story.nodeId] && nodesCache[story.nodeId].text
  }, [nodesCache, storiesCache]);

  const videoForStory = useCallback((storyOrStoryId) => {
    const storyId = typeof storyOrStoryId === 'object' ? storyOrStoryId._id : storyOrStoryId;
    const story = storiesCache[storyId];
    if (!story) return null;
    return videosCache[story.videoId]
  }, [videosCache, storiesCache]);

  return { 
    fetchStories, 
    stories, 
    isLoading, 
    error, 
    fetchStory, 
    storiesCache, 
    nodesCache, 
    videosCache, 
    preferred, 
    nodeTextForStory,
    videoForStory
  };
};
