import React, { useState } from "react";
import { logout, abbreviateNumber, linkStyles } from "./utils";
import classNames from "classnames";
import useStore from "./context/hooks";

function UserMenu() {
  const { state, dispatch } = useStore()
  const { user, menuOpen } = state;
  if (!user) {
    return null
  }

  const handleMenuClick = () => {
    dispatch({ type: 'setThing', payload: { key: 'menuOpen', value: !menuOpen } })
  }
  let show = (
    <>
      <button
        onClick={handleMenuClick}
        className=""
      >
        {user.fire ? `${abbreviateNumber(user.fire)} 🪙` : '🚫 🪙'}
      </button>
      {menuOpen && <button onClick={handleMenuClick} className="text-right px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">❌</button>}
    </>
  )
  if (user.error) {
    show = (
      <a onClick={() => logout(dispatch)} className="text-red-200 bg-slate-600">VERIFY YOUR EMAIL!</a>
    )
  }

  return (
    <div className="relative inline-block text-left ">
      {show}
    </div>
  );
}

export default UserMenu;