import React, { useState, useRef, useEffect } from 'react'
import { not, path, set } from 'ramda'
import { btnStyles, getPrice, isPause, textInputClass, inputsCommon, estimateSpeechDuration, blankNarrationPhrase, linkStyles, getVideo, getPause, isAudioVideo } from './utils'
import classNames from 'classnames'
import NarrationPhrase from './NarrationPhrase'
import './Narration.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { WaveSurferPlayer } from './WaveSurfer'
import Price from './common/Price'
import { useSetThing } from './context/hooks'
import ImageChooser from './ImageChooser'

function splitString(text, howManyWords) {
  // Split the text into pieces using the characters , . ! ?
  let pieces = text.split(/([,.!?])/);

  // Initialize an empty array to store the result
  let result = [];
  let currentPiece = '';

  // Loop through each piece
  for (let piece of pieces) {
    // If the piece is a separator character (,.!?), add it to the current piece
    if (/[,.!?]/.test(piece)) {
      currentPiece += piece;
    } else {
      // If the piece is not a separator, split it into words
      let words = piece.split(/\s+/);

      // Check if adding the current piece and the new piece will exceed 20 words
      if ((currentPiece + piece).split(/\s+/).length <= howManyWords) {
        currentPiece += piece;
      } else {
        // If adding the new piece exceeds 20 words, push the currentPiece to the result array
        result.push(currentPiece.trim());
        currentPiece = piece;
      }
    }
  }

  // Push the last currentPiece to the result array
  result.push(currentPiece.trim());
  result = result.filter(r => r)

  return result;
}

export default function Narration({ onCheckout, config }) {
  const [texts, setTexts] = useState(path(['narration'], config) || [blankNarrationPhrase()])
  const [text, setText] = useState('')
  const [image, setImage] = useState(null)
  const [pastTextOpen, setPastTextOpen] = useState(false)
  const location = useLocation() 
  const id = new URLSearchParams(location.search).get('v')
  const textareaRef = useRef(null)
  const isOk = texts.length > 0 && texts.some(t => t.prompt || t.narration) && texts[0].prompt
  const [voice, setVoice] = useState('Bella')
  const lastId = useRef(null)
  const navigate = useNavigate()
  const setThing = useSetThing()
  useEffect(() => {
    if (!id || lastId.current === id) return
    lastId.current = id
    console.log(id)
    getVideo(id)
      .then(d => {
        if (isAudioVideo(d)) {
          navigate(`/audio?v=${id}`)
          return
        }
        const hasANegPrompt = d.config.narration.some(t => t.neg_prompt || isPause(t))
        if (hasANegPrompt) {
          setThing('mode', 'advanced')
        }
        setTexts(d.config.narration)
        const v = path(['camera_config', 'voice'], d.config)
        if (v) setVoice(v)
        const i = path(['camera_config', 'image'], d.config)
        if (i) setImage(i)
      })
  }, [id])
  useEffect(() => {
    console.log(texts)
  }, [texts])
  const voices = [
    'Adam',
    'Antony',
    'Arnold',
    'Bella',
    'Domi',
    'Elli',
    'Josh',
    'Rachel',
    'Sam',
    ['Army Guy', '6NhbsygYw3HZBhDC2UfU'],
    ['Valley Girl', '1LvafSutRGx7uuI9vtJ6'],
    ['British Guy', 'R2K6xXCciBV3uRkOOodf'],
  ]
  let imageShow = <ImageChooser onComplete={setImage} /> 
  if (image) {
    imageShow = (
      <p className='text-right'>
        <div className='flex justify-between'>
          <span>Starting Image:</span>
          <a className={linkStyles} onClick={() => setImage(null)}>🗑️</a>
        </div>
        <img src={image} />
      </p>
    )
  }
  const addTextBelow = (i) => {
    const t = texts[i]
    const myDuration = Math.floor(getPause(t)) / 2
    const meWithNewDuration = { ...t, narration: `pause-${myDuration}` }
    const newText = blankNarrationPhrase()
    newText.narration = `pause-${myDuration}`
    setTexts([...texts.slice(0, i), meWithNewDuration, newText, ...texts.slice(i + 1)])
  }
  return (
    <div className={classNames('p-5 narration', { forked: id })}>
      <div>
        {imageShow}
      </div>
      <div className={classNames('fixed bottom-16 show-advanced z-10 right-5 bg-black helper', {
        'w5/6 md:w-1/2': pastTextOpen
      })}>
        <p className='text-right'> <a className='text-pink-500' onClick={() => setPastTextOpen(not)}>{pastTextOpen ? '❌' : 'open helper 📝'}</a></p>
        {pastTextOpen &&
          <>
            <p>
              {/* <a onClick={async () => {
              const text = await navigator.clipboard.readText()
              setText(text)
            }}>Paste text</a>  */}
              Paste text then split it by hitting enter or <a className={linkStyles} onClick={() => {
              if (!text) return
              const pieces = splitString(text, 20)
              setTexts(pieces.map(p => ({ prompt: p.replace(/[^A-Za-z ]/g, ' '), narration: p })))
              setText('')
              setPastTextOpen(false)
            }}>auto-split</a>:</p>
            <textarea 
              ref={textareaRef} 
              onKeyUp={evt => {
                if (evt.key !== 'Enter') return
                const cursorPosition = evt.target.selectionStart;
                const t = text.slice(0, cursorPosition).trim() 
                setTexts([...texts, { prompt: t.replace(/[^A-Za-z ]/g, ' '), narration: t }])
                setText(text.slice(cursorPosition).trim())
                setTimeout(() => {
                  if (textareaRef.current) {
                    textareaRef.current.focus();
                    textareaRef.current.selectionStart = 0;
                    textareaRef.current.selectionEnd = 0;
                  }
                }, 250)
              }}  
              className={classNames("peer h-full min-h-[200px] w-full h-60 ", inputsCommon)}
              value={text} 
              onChange={evt => {
                setText(evt.target.value)
              }} 
            />
          </>
        }
      </div>
      {texts.length < 1 && (
        <a className={classNames(btnStyles)} onClick={() => {
          setTexts([...texts, { prompt: '', narration: '', neg_prompt: '' }])
        }}>Add a phrase</a>
      )}
      <div className='texts text-left'>
        {texts.map((t, i) => <NarrationPhrase 
            key={`phrase-${i}`} 
            i={i} 
            texts={texts} 
            setTexts={setTexts} 
            onAbove={() => {
              // insert a new text before this one
              setTexts([...texts.slice(0, i), blankNarrationPhrase(), ...texts.slice(i)])
            }}
            onBelow={() => {
              setTexts([...texts.slice(0, i + 1), blankNarrationPhrase(), ...texts.slice(i + 1)])
            }}
          />
        )}
        {texts.find(t => t.narration !== '') && (
          <div className=''>
            <p className='text-left'>Voice:</p>
            <select className={classNames(inputsCommon)} value={voice} onChange={evt => setVoice(evt.target.value)}>
              {voices.map(v => {
                const label = Array.isArray(v) ? v[0] : v
                const value = Array.isArray(v) ? v[1] : v
                return (
                  <option key={label} value={value}>{label}</option>
                )
              })}
            </select>
          </div>
        )}
        {isOk && <button className={classNames(btnStyles, 'mt-5')} onClick={() => {
          const config = { narration: texts, voice }
          if (image) config.camera_config = { image }
          if (id) config.from = id
          onCheckout(config)
        }}>Generate Video <Price config={{ narration: texts }} /> </button>}
      </div>
    </div>
  )
}