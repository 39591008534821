import React from 'react'
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions'
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6";
import { on } from 'ramda';
const { useRef, useState, useEffect, useCallback } = React


// WaveSurfer hook
const useWavesurfer = (containerRef, options) => {
  const [wavesurfer, setWavesurfer] = useState(null)

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!containerRef.current) return

    const ws = WaveSurfer.create({
      ...options,
      container: containerRef.current,
    })
    ws.registerPlugin(TimelinePlugin.create())
    // Initialize the Regions plugin
    const wsRegions = ws.registerPlugin(RegionsPlugin.create())
    ws.regions = wsRegions

    setWavesurfer(ws)

    return () => {
      ws.destroy()
    }
  }, [options, containerRef])

  return wavesurfer
}

// Create a React component that will render wavesurfer.
// Props are wavesurfer options.
export const WaveSurferPlayer = (props) => {
  const { markers = [], onDuration, onMarkerDrag, playing, onEvent } = props
  const containerRef = useRef()
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const wavesurfer = useWavesurfer(containerRef, props)

  // On play button click
  const onPlayClick = useCallback(() => {
    playing ? wavesurfer.pause() : wavesurfer.play()
  }, [playing])

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!wavesurfer) return

    setCurrentTime(0)
    setIsPlaying(false)

    const subscriptions = [
      wavesurfer.on('play', () => setIsPlaying(true)),
      wavesurfer.on('pause', () => setIsPlaying(false)),
      wavesurfer.on('finish', () => {
        setIsPlaying(false)
        onEvent && onEvent('finish')
      }),
      wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
      wavesurfer.on('decode', duration => {
        if (onDuration) onDuration(duration)
        markers.forEach((marker, i) => {
          const color = marker.end  ?  marker.color.replace('1)', '0.75)') : marker.color
          wavesurfer.regions.addRegion({
            start: marker.start,
            end: marker.end,
            content: '',
            color,
            drag: i > 0 && !marker.fixed,
            resize: false,
          })
        })
        wavesurfer.regions.on('region-updated', (region) => {
          const regions = wavesurfer.regions.getRegions()
          const markerIndex = regions.findIndex((marker) => marker.id === region.id)
          if (onMarkerDrag) onMarkerDrag(markerIndex, region, wavesurfer)
        })
        if (playing) wavesurfer.play()
      })
    ]

    return () => {
      subscriptions.forEach((unsub) => unsub())
    }
  }, [wavesurfer])

  return (
    <>
      <div ref={containerRef} style={{ minHeight: '120px', width: '100%' }} />
    </>
  )
}
