import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { last, flatten } from 'ramda';
import { useSpringCarousel } from 'react-spring-carousel'
import './TikTok.scss';
import Carousel from './common/Carousel';
import useStore from './context/hooks';

function hasAnyParents(node, parentIds) {
  while (node.parent) {
    if (parentIds.includes(node.parent.data.id)) {
      return true;
    }
    node = node.parent;
  }
  return false;
}


const TikTok = forwardRef(({ selected, onChange, onDrag, hierarchy, renderItem, disableKeyboard, onStartChange, trip }, ref) => {
  const apiRef = React.useRef();
  const dragTout = React.useRef();
  const indexHRef = React.useRef(0);
  const currentCarouselRef = React.useRef();
  const [indexH, setIndexH] = useState(0); // Horizontal Index
  const threshold = 10; // Minimum distance to trigger swipe
  const { state } = useStore()
  const { preferred } = state

  const newItems = []
  const storiesArray = []
  const nodes = []
  let initialActiveItem = 0
  const current = hierarchy.find(v => v.data.id === selected)
  const _onDrag = (e) => {
    if (!onDrag) return
    onDrag(true)
    clearTimeout(dragTout.current)
    dragTout.current = setTimeout(() => {
      onDrag(false)
    }, 300)
  }
  trip.forEach(t => {
    newItems.push({
      id: t,
      renderItem: renderItem(t)
    })
  })
  const addItemForNodes = (node, depth) => {
      const arr = []
      const items = node.map(c => {
        arr.push(c.data.id)
        return ({
          id: c.data.id,
          renderItem: renderItem(c.data.id)
        })
      })
      storiesArray.push(arr)
      const initialActiveItem = items.findIndex(i => i.id === selected || preferred.includes(i.id))
      newItems.push({
        id: depth + '-children',
        items,
        renderItem: <Carousel
          ref={items.find(i => i.id === selected) ? currentCarouselRef : null}
          items={items}
          initialActiveItem={initialActiveItem}
          disableKeyboard={disableKeyboard || !items.find(i => i.id === selected) || state.modal}
          carouselSlideAxis='y'
          onEvent={e => {
            if (e.eventName === 'onSlideStartChange') {
              onStartChange && onStartChange(e.nextItem.id)
            } else if (e.eventName === 'onSlideChange') {
              const thisIndex = items.findIndex(i => i.id === e.currentItem.id)
              onChange && onChange(e.currentItem.id)
            } else if (e.eventName === 'onDrag') {
              _onDrag(e)
            }
          }}
        />
      })
  }
  if (current) {
    const siblings = current.parent ? current.parent.children : [current]
    addItemForNodes(siblings, current.depth)
    const children = current.children
    if (children && children.length > 0) {
      addItemForNodes(children, current.depth + 1)
    }
  }

  if (selected) {
    initialActiveItem = newItems.findIndex(i => /-/.test(i.id) ? i.items.map(d => d.id).includes(selected) : i.id === selected)
    if (initialActiveItem === -1) {
      initialActiveItem = 0
    }
  }
  indexHRef.current = initialActiveItem

  const { 
    carouselFragment, 
    useListenToCustomEvent,
    slideToItem
  } = useSpringCarousel({
    initialActiveItem,
    items: newItems,
  })

  const handleNavigation = (direction) => {
    if (disableKeyboard || state.modal) return;

    switch (direction) {
      case 'left':
        if (indexH > 0) {
          setIndexH(indexH - 1);
          slideToItem(indexH - 1) ;
        }
        break;
      case 'right':
        if (indexH < newItems.length - 1) {
          setIndexH(indexH + 1);
          slideToItem(indexH + 1) ;
        }
        break;
      case 'up':
      case 'down':
        if (currentCarouselRef.current) {
          currentCarouselRef.current.handleVerticalNavigation(direction);
        }
        break;
      // 'up' and 'down' will be handled by the Carousel component
    }
  };

  useListenToCustomEvent(e => {
    if (e.eventName === 'onSlideStartChange') {
      const item = e.nextItem
      let storyId = item.id
      if (/-/.test(item.id)) {
        const mySibIndex = newItems.filter(i => /-/.test(i.id)).findIndex(i => i.id === item.id)
        const mySibs = storiesArray[mySibIndex]
        if (mySibs) {
          storyId = mySibs.find(id => preferred.includes(id)) || mySibs[0]
        }
      }
      onStartChange && onStartChange(storyId)

    } else if (e.eventName === 'onSlideChange') {
      const item = e.currentItem
      let storyId = item.id
      if (/-/.test(item.id)) {
        const mySibIndex = newItems.filter(i => /-/.test(i.id)).findIndex(i => i.id === item.id)
        const mySibs = storiesArray[mySibIndex]
        if (mySibs) {
          storyId = mySibs.find(id => preferred.includes(id)) || mySibs[0]
        }
      }
      setIndexH(item.index)
      console.log('onSlideChange', item)
      onChange && onChange(storyId)
    } else if (e.eventName === 'onDrag') {
      _onDrag(e)
    }
  })

  useImperativeHandle(ref, () => ({
    handleNavigation
  }));

  return (
    <div className='tiktok w-full aspect-square'>
      {carouselFragment}
    </div>
  );
});

export default TikTok;
