import React, { useRef } from "react"
import VideoPlayer from "./VideoPlayer2"
import { FPS, imgUrlForFrame } from "./utils"
import classNames from "classnames"

export default function VideoClip ({ clipId, video, className }) {
  const videoRef = useRef(null)
  if (!clipId) return null
  const pieces = clipId.split('-') 
  const videoId = pieces[0]
  const start = parseInt(pieces[1], 10)
  const end = parseInt(pieces[2], 10)
  const totalSeconds = (end - start) / FPS
  return (
    <div className='video-clip'>
      <VideoPlayer 
        url={`https://aiaiaiaiai.biz/finals/${videoId}.mp4`}
        poster={video && imgUrlForFrame(video, start)}
        controls={false}
        className={classNames(className)}
        loop
        muted
        onRef={v => {
          videoRef.current = v
          v.currentTime(start / FPS)
          v.on('timeupdate', evt => {
            if (videoRef.current.currentTime() >= end / FPS
              || videoRef.current.currentTime() < start / FPS
            ) {
              videoRef.current.currentTime(start / FPS)
            }
          })
        }} 
      />
      <div className='meta text-xs'>
        {totalSeconds}s
      </div>
    </div>
  )
}