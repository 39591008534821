import React, { useImperativeHandle, useEffect, useState, useCallback, forwardRef } from 'react'
import { useSpringCarousel } from 'react-spring-carousel'

const Carousel = forwardRef(({ disableKeyboard, items, carouselSlideAxis = 'x', onEvent, initialActiveItem = 0 }, ref) => {
  if (initialActiveItem < 0) {
    initialActiveItem = 0
  }
  const [indexV, setIndexV] = useState(initialActiveItem);

  const {
    carouselFragment,
    slideToItem,
    useListenToCustomEvent
  } = useSpringCarousel({
    withLoop: carouselSlideAxis !== 'x',
    carouselSlideAxis,
    initialActiveItem,
    items
  })
  const handleVerticalNavigation = useCallback((direction) => {
    if (disableKeyboard) return;

    let newIndex;
    if (direction === 'up') {
      newIndex = indexV > 0 ? indexV - 1 : items.length - 1;
    } else if (direction === 'down') {
      newIndex = indexV < items.length - 1 ? indexV + 1 : 0;
    } else {
      return;
    }

    setIndexV(newIndex);
    slideToItem(newIndex)
    console.log('onSlideStartChange', items[newIndex], newIndex)
    onEvent && onEvent({ 
      eventName: 'onSlideStartChange', 
      nextItem: items[newIndex] 
    });
  }, [indexV, items, disableKeyboard, onEvent]);

  useEffect(() => {
    // slideToItem(indexV)
  }, [indexV, slideToItem])
  useListenToCustomEvent(e => {
    onEvent && onEvent(e)
  })
  useImperativeHandle(ref, () => ({
    handleVerticalNavigation
  }));
  return (
    <div className='carousel tiktok w-full relative aspect-square'>
      {carouselFragment}
    </div>
  ) 
})
export default Carousel